import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportingToolComponent }   from './reporting-tool/reporting-tool.component'
import { ReportingToolSlcqComponent } from './reporting-tool-slcq/reporting-tool-slcq.component'
import { ReportingToolSlcqComponentComparable } from './reporting-tool-slcq-comparable/reporting-tool-slcq-comparable.component'
import { AssessmentComponent }   from './assessment/assessment.component'
// import { AssessmentSlcqComponent } from './assessment-slcq/assessment-slcq.component';
import { LoginComponent }   from './login/login.component'

import { AdminheaderComponent }   from './adminheader/adminheader.component'
import { AdminassessmentComponent }   from './adminassessment/adminassessment.component'
import { AdmindemographicsComponent }   from './demographicedit/admindemographics.component'
import { AdminindividualreportComponent }   from './adminindividualreport/adminindividualreport.component'
import { PublicindividualreportComponent }   from './publicindividualreport/publicindividualreport.component'
import { OverlayreportComponent }   from './overlayreport/overlayreport.component'
import { DemographicreportComponent }   from './demographicreport/demographicreport.component'
import { ClientlistComponent }   from './clientlist/clientlist.component'
import { AssessmentlistComponent }   from './assessmentlist/assessmentlist.component'

import { AuthGuard } from './guards/auth.guard';
import { MyAccountComponent } from './myaccount/myacount.component';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './resetpassword/resetpassword.component';
import { DemographicsComponent } from './demographics/demographics.component';
import { UsersComponent } from './users/users.component';

export const routes: Routes = [
  { path: '', component: ClientlistComponent, canActivate: [AuthGuard]  },
  { path: 'report', component: ReportingToolComponent, canActivate: [AuthGuard] },
  { path: 'report/response/:id', component: ReportingToolComponent, canActivate: [AuthGuard] },
  { path: 'report-slcq', component: ReportingToolSlcqComponent, canActivate: [AuthGuard] },
  { path: 'report-slcq-comparable', component: ReportingToolSlcqComponentComparable, canActivate: [AuthGuard] },
  { path: 'report-slcq/response/:id', component: ReportingToolSlcqComponent, canActivate: [AuthGuard] },
  { path: 'assessment/individual/:id', component: PublicindividualreportComponent },
  { path: 'assessment/:hash', component: AssessmentComponent },
  { path: 'assessment/:hash/:page', component: AssessmentComponent },
  { path: 'admin/assessment/:id', component: AdminassessmentComponent, canActivate: [AuthGuard] }, // Clients --> Asessments ---> This assessment
  // { path: 'admin/demographics/edit', component: AdmindemographicsComponent, canActivate: [AuthGuard] }, // Clients --> Asessments ---> This assessment
  { path: 'admin/report/individual/:id', component: AdminindividualreportComponent, canActivate: [AuthGuard] }, // Clients --> Asessments ---> This assessment ---> Individual report
  { path: 'admin/report/overlay/:idone/:idtwo', component: OverlayreportComponent, canActivate: [AuthGuard] }, // Clients
  { path: 'admin/report/demographic/:idassessment/:demographicgroup', component: DemographicreportComponent, canActivate: [AuthGuard] }, // Clients
  { path: 'clients', component: ClientlistComponent , canActivate: [AuthGuard] },
  { path: 'clients/:id/projects/assessments', component: AssessmentlistComponent, canActivate: [AuthGuard] },
  { path: 'admin/demographics/edit', component: DemographicsComponent, canActivate: [AuthGuard] },
  // Clients --> Asessments
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:hash', component: ResetPasswordComponent },
  { path: 'admin/my-account', component: MyAccountComponent, canActivate: [AuthGuard] },
  { path: 'admin/users', component: UsersComponent, canActivate: [AuthGuard] },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  declarations: [],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}


