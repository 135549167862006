import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { SlcqBubbleColors1, SlcqBubbleColors2 } from './bubble-chart-report';

@Component({
  selector: 'app-bubble-chart-report',
  templateUrl: './bubble-chart-report.component.html',
  styleUrls: ['./bubble-chart-report.component.css']
})
export class BubbleChartReportComponent implements OnInit {

  @Input() slcqQuadrant: any;
  @Input() bubbleChartChangeTrigger: any;
  @Input() bubbleChartWidth: number;

  type = 'BubbleChart';
  // numBubbles;
  options;
  data;
  dataSeries: any;

  slcqBubbleColors1 = new SlcqBubbleColors1;

  constructor() { }


  ngOnInit() {

    // console.log("entereed ngOnInit in BubbleChartReportComponent.. Argument is: ");
    // console.log(this.slcqQuadrant);

    
    // allow to change the size on the fly & keep the overlay position relative to the chart    
    // console.log("in BubbleChartReportComponent ngOnInit() bubbleChartWidth: " + this.bubbleChartWidth);
    // document.documentElement.style.setProperty("--main-height-and-width", this.slcqQuadrant.widthAndHeight + "px");
    document.documentElement.style.setProperty("--main-height-and-width", this.bubbleChartWidth + "px");

    // this.numBubbles = this.slcqQuadrant.slcqData.length;
    // this.buildSeries(); // commented out cuz we're only showing 1 assessment per chart for now
    this.buildData();
    this.setOptions(); // need to set this from scratch every time or the google charts component will not update

  }
  
  ngOnChanges(changes: SimpleChanges) {

    // console.log("in BubbleChartReportComponent ngOnChanges() bubbleChartWidth: " + this.bubbleChartWidth);
    document.documentElement.style.setProperty("--main-height-and-width", this.bubbleChartWidth + "px");

    // console.log("*************************** ENTERED BubbleChartReportComponent ngOnChanges");
    // this.numBubbles = this.slcqQuadrant.slcqData.length;
    // this.buildSeries(); // commented out cuz we're only showing 1 assessment per chart for now
    this.buildData();
    this.setOptions(); // need to set this from scratch every time or the google charts component will not update


    // // I was hoping this code would stop the ngOnChanges method from doubling the 1st call to this component
    // // so buildData wasn't being called twice the 1st time but it didn't work
    // for (const propName in changes) {
    //   if (changes.hasOwnProperty(propName)) {
    //     // console.log("propName:");
    //     // console.log(propName);
    //     switch (propName) {
    //       case "bubbleChartChangeTrigger": {
    //         console.log("data change detected in BubbleChartReportComponent via bubbleChartChangeTrigger");
    //         this.buildData();
    //       }
    //     }
    //   }
    // }
    // // console.log(changes);
    // // console.log(this.slcqQuadrant);
  }

  setOptions() {

    let showTooltips = "focus";
    if (!this.slcqQuadrant.showTooltips) {
      showTooltips = "none";
    }

    // these sizes look good when the overall chart width = 600px
    // const bubbleMaxSize = 60;
    // const bubbleMinSize = 10;
    // dynamic versions:
    const bubbleMaxSize = this.bubbleChartWidth / 10;
    const bubbleMinSize = this.bubbleChartWidth / 60;

    this.options = {
      "vAxis": {
        "title": this.slcqQuadrant.xLabel,
        "titleTextStyle": { "fontSize": 16, "bold": true },
        "minValue": 1, "maxValue": 4, "baseline": 2.5,
        "gridlines": { "count": 0 }, "minorGridlines": { "count": 0 },
        "ticks": []
      },
      "hAxis": {
        "title": this.slcqQuadrant.yLabel,
        "titleTextStyle": { "fontSize": 17, "bold": true },
        "minValue": 1, "maxValue": 4, "baseline": 2.5,
        "gridlines": { "count": 0 }, "minorGridlines": { "count": 0 },
        "ticks": []
      },
      // "series": this.dataSeries, // this was for showing mutliple assessments      
      series: {
        'Mean': { color: 'DarkRed', visibleInLegend: 'true' },
        '': { color: 'PaleTurquoise', visibleInLegend: 'false' }
      },
      "sizeAxis": { "maxSize": bubbleMaxSize, "minSize": bubbleMinSize },
      // "tooltip": { "trigger": "showTooltips" }
      "tooltip": { "trigger": "none" }
    };
  }

  // this was for handling the legend names & colors BUT for now we decided to only show 1 assessment per chart
  // so currently it's not needed
  // buildSeries() {

  //   let dataSeriesBuilder = '{';

  //   for (let i = 0; i < this.slcqQuadrant.slcqData.length; i++) {

  //     if (i > 0) {
  //       // add a comma separator when needed
  //       dataSeriesBuilder += ',';
  //     }

  //     const assessmentName = this.slcqQuadrant.slcqData[i].assessmentname;
  //     const meanName = 'Mean ' + assessmentName;

  //     dataSeriesBuilder += '"' + assessmentName + '": { "color": "' + this.slcqBubbleColors1.colors[i].data + '", "visibleInLegend": "true" },';

  //     // show Vicere's original mean color if there's only 1 chart to show
  //     if (this.slcqQuadrant.slcqData.length == 1) {
  //       dataSeriesBuilder += '"' + meanName + '": { "color": "darkRed", "visibleInLegend": "true" }';
  //     } else {
  //       dataSeriesBuilder += '"' + meanName + '": { "color": "' + this.slcqBubbleColors1.colors[i].mean + '", "visibleInLegend": "true" }';
  //     }

  //   }

  //   dataSeriesBuilder += '}';

  //   // console.log('dataSeries at end: ' + dataSeriesBuilder);

  //   // use object.assign to force the google charts component to see this as changed data so it reloads
  //   this.dataSeries = JSON.parse(dataSeriesBuilder);

  //   // console.log('this.dataSeries: ' + JSON.stringify(this.dataSeries));
  // }

  // this was built for handling multiple assessments BUT we decided to only show 1 assessment per chart for now
  buildData() {

    // calc where the bubbles should go based on how many data sets there are

    // use a spreadsheet to put the #s in and look for a pattern.  Do multiples of base 2, aka 1,2,4,8 and don't allow selecting more than that.

    // for the 1st quad
    // origin is 2.5,2.5
    // width of quadrant is 1.5 but we use 1.4 cuz it looks better
    // 1 bubble would be center of quadrant or 1.4/2 = 0.7 away from origin
    // 2 bubbles would be increments of 1.4/3 on the x axis
    // 3 or 4 would be the same x axis but change y axis to 1.4/3
    // const xyvals = this.xyvals(this.numBubbles, 1);
    // console.log("xyvals");
    // console.log(xyvals);


    let dataBuilder = '[';
    // let dataBuilder = '';

    for (let i = 0; i < this.slcqQuadrant.slcqData.length; i++) {

      if (i > 0) {
        // add a comma separator when needed
        dataBuilder += ',';
      }

      // const assessmentName = this.slcqQuadrant.slcqData[i].assessmentname;
      // const meanName = 'Mean ' + assessmentName;
      // for now we're only showing 1 assessment at a time so here's the temp revised version of the above code:      
      const assessmentName = '';
      const meanName = 'Mean';

      dataBuilder += '["", ' + this.slcqQuadrant.slcqData[i].meanX + ', '
        + this.slcqQuadrant.slcqData[i].meanY + ', "' + meanName + '", 1 ]';


      // bubble sizes here may screw up the size of the mean if the % is less than 1.
      // So need to adjust bubble sizes by adding 1 to make sure the smallest % bubble is greater than the mean.
      // console.log("about to build quad 1 data.  i=" + i + " and this.slcqQuadrant.slcqData[i].quad1Percent = " + this.slcqQuadrant.slcqData[i].quad1Percent);
      if (this.slcqQuadrant.slcqData[i].quad1Percent > 0) {
        // console.log("Entered if statement to build quad 1 data.  i=" + i);
        dataBuilder += ',["' + this.slcqQuadrant.slcqData[i].quad1Percent.toFixed(0)
          + '%", 1.75, 3.25, "' + assessmentName + '", ' + (this.slcqQuadrant.slcqData[i].quad1Percent + 1) + ']';
        // dataBuilder += ',["' + this.slcqQuadrant.slcqData[i].quad1Percent.toFixed(0)
        //   + '%", ' + xyvals[i].x + ', ' + xyvals[i].y + ', "", ' + (this.slcqQuadrant.slcqData[i].quad1Percent + 1) + ']';
      }
      if (this.slcqQuadrant.slcqData[i].quad2Percent > 0) {
        dataBuilder += ',["' + this.slcqQuadrant.slcqData[i].quad2Percent.toFixed(0)
          + '%", 3.25, 3.25, "' + assessmentName + '", ' + (this.slcqQuadrant.slcqData[i].quad2Percent + 1) + ']';
      }
      if (this.slcqQuadrant.slcqData[i].quad3Percent > 0) {
        dataBuilder += ',["' + this.slcqQuadrant.slcqData[i].quad3Percent.toFixed(0)
          + '%", 1.75, 1.75, "' + assessmentName + '", ' + (this.slcqQuadrant.slcqData[i].quad3Percent + 1) + ']';
      }
      if (this.slcqQuadrant.slcqData[i].quad4Percent > 0) {
        dataBuilder += ',["' + this.slcqQuadrant.slcqData[i].quad4Percent.toFixed(0)
          + '%", 3.25, 1.75, "' + assessmentName + '", ' + (this.slcqQuadrant.slcqData[i].quad4Percent + 1) + ']';
      }

      // console.log('dataBuilder in loop: ' + dataBuilder);

      // this.slcqQuadrant[counter].data = [ ['Mean', xValue*1, yValue*1, 'Mean', 1] ];
    }

    dataBuilder += ']';

    // console.log('dataBuilder at end: ' + dataBuilder);
    this.data = JSON.parse(dataBuilder);

    // console.log('this.data: ' + JSON.stringify(this.data));

  }

  // xyvals(numBubbles, numQuadrant) {

  //   const quadSize = 1.5;
  //   const center = 2.5;
  //   const padding = 0.15;

  //   switch (numQuadrant) {
  //     case 1:
  //       switch (numBubbles) {
  //         case 1:
  //           // dead center
  //           let xyIncrement = quadSize / 2;

  //           let x = 1 + xyIncrement;
  //           let y = center + xyIncrement;

  //           return [{ "x": x, "y": y }];
  //           break;
  //         case 2:
  //           // 1 row, 2 columns
  //           let xIncrement = quadSize / 3;
  //           let yIncrement = quadSize / 2;

  //           let x1 = 1 + xIncrement - padding;
  //           let y1 = center + yIncrement;
  //           let x2 = x1 + xIncrement+ padding;
  //           let y2 = y1;

  //           return [{ "x": x1, "y": y1 }, { "x": x2, "y": y2 }];
  //           break;
  //       }
  //       break;
  //     case 2:
  //       break;
  //     case 3:
  //       break;
  //     case 4:
  //       break;
  //   }
  //   return null;
  // }
}
