import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Report } from '../report';

@Component({
  selector: 'app-adminindividualreport',
  templateUrl: './adminindividualreport.component.html',
  styleUrls: ['./adminindividualreport.component.css']
})
export class AdminindividualreportComponent implements OnInit {

  report: Report;
  responseDemographics: any;

  constructor( private router: Router,
               private route: ActivatedRoute,
               private adminService: AdminService,
               private location: Location) { }


  assignReport(report): void {
    this.report = report;
  }

  getIndividualReport(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.adminService.getIndividualReport(id).subscribe((report) => {
                        this.assignReport(report);
                    });
  }

  getResponseDemographics(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.adminService.getResponseDemographics(id).subscribe((response) => {
      this.responseDemographics = response['assessmentDemographicQuestionsResponse'];
    });
  }

  ngOnInit() {
    this.getIndividualReport();
    this.getResponseDemographics();
  }

}
