import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import {
  AssessmentIntro,
  AssessmentQuestion,
  Assessment,
  AssessmentResponse,
  AssessmentDump,
  Assessmentlist,
  User,
  DemographicFilterList,
  DemographicFilter,
  Client,
  Project,
  AssessmentType,
  Response,
  DemographicAll,
} from "./assessment/assessment";
import {
  Report,
  OverlayReport,
  GenericReport,
  SidebarElements,
  Name,
} from "./report";
import { environment } from "../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
const customHttpOpt = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
  responseType: "blob",
};
@Injectable()
export class AdminService {
  private getSlcqLayoutUrl =
    environment.apiUrl + environment.portAdmin2 + "getSlcqLayout";
  private getSlcqReportUrl =
    environment.apiUrl + environment.portAdmin2 + "getSlcqReport";
  private getSlcqLayoutAndReportUrl =
    environment.apiUrl + environment.portAdmin2 + "getSlcqLayoutAndReport";

  private getAdminAssessmentUrl =
    environment.apiUrl + environment.portMain + "getAdminAssessment";
  private getResponseUrl =
    environment.apiUrl + environment.portMain + "getResponse";
  private getResponsesUrl =
    environment.apiUrl + environment.portMain + "getResponses";
  private getIndividualReportUrl =
    environment.apiUrl + environment.portMain + "getIndividualReport";
  private getAssessmentReportUrl =
    environment.apiUrl + environment.portMain + "getAssessmentReport";
  private getOverlayReportUrl =
    environment.apiUrl + environment.portMain + "getOverlayReport";
  private getAssessmentDumpUrl =
    environment.apiUrl + environment.portMain + "getAssessmentDump";
  private postLoginUrl = environment.apiUrl + environment.portUsers + "login";
  private getProjectsAssessmentsUrl =
    environment.apiUrl + environment.portMain + "getProjectsAssessments";
  private getAssessmentDemographicsUrl =
    environment.apiUrl + environment.portMain + "getAssessmentDemographics";
  private getNamesPerResponseUrl =
    environment.apiUrl + environment.portMain + "getNamesPerResponse";
  private getGenericReportUrl =
    environment.apiUrl + environment.portMain + "getGenericReport";
  private getGenericReportHtmlUrl =
    environment.apiUrl + environment.portMain + "getGenericReportHtml";
  private getClientsUrl =
    environment.apiUrl + environment.portMain + "getClients";
  private getAllClientsUrl =
    environment.apiUrl + environment.portUsers + "getAllClients";
  private submitUpdateClientUrl =
    environment.apiUrl + environment.portMain + "updateClient";
  private getSidebarElementsUrl =
    environment.apiUrl + environment.portMain + "getSidebarElements";
  private submitProjectUrl =
    environment.apiUrl + environment.portMain + "submitProject";
  private editProjectUrl =
    environment.apiUrl + environment.portMain + "updateProject";
  private submitAssessmentUrl =
    environment.apiUrl + environment.portMain + "submitAssessment";
  private editAssessmentUrl =
    environment.apiUrl + environment.portMain + "updateAssessment";
  private getProjectsUrl =
    environment.apiUrl + environment.portMain + "getProjects";
  private getAssessmentTypesUrl =
    environment.apiUrl + environment.portMain + "getAssessmentTypes";
  private validateAssessmentUrl =
    environment.apiUrl + environment.portMain + "validateAssessment";
  private getResponseDemographicsUrl =
    environment.apiUrl + environment.portMain + "getResponseDemographics";
  private updatePasswordUrl =
    environment.apiUrl + environment.portUsers + "setPassword";
  private validateEmailForgotPasswordUrl =
    environment.apiUrl + environment.portUsers + "validateEmailForgotPassword";
  private validateHashPasswordUrl =
    environment.apiUrl + environment.portUsers + "validateHashPassword";
  private updateForgotPasswordUrl =
    environment.apiUrl + environment.portUsers + "updateForgotPassword";
  private getDemographicsAllUrl =
    environment.apiUrl + environment.portMain + "getDemographicsAll";
  private getGeneralDemographicsUrl =
    environment.apiUrl + environment.portMain + "getGeneralDemographics";
  private getCustomGeneralDemographicsUrl =
    environment.apiUrl + environment.portMain + "getCustomGeneralDemographics";
  private submitNewCustomDemographicUrl =
    environment.apiUrl + environment.portMain + "submitNewCustomDemographic";
  private submitUpdateCustomDemographicUrl =
    environment.apiUrl + environment.portMain + "submitUpdateCustomDemographic";
  private getAvailableDemographicsUrl =
    environment.apiUrl + environment.portMain + "getAvailableDemographics";
  private submitToggleLockDemographicUrl =
    environment.apiUrl + environment.portMain + "submitToggleLockDemographic";
  private submitNewStandardDemographicUrl =
    environment.apiUrl + environment.portMain + "submitNewStandardDemographic";
  private getUsersUrl =
    environment.apiUrl + environment.portUsers + "users/list";
  private editUserUrl = environment.apiUrl + environment.portUsers + "userEdit";
  private addUserUrl = environment.apiUrl + environment.portUsers + "register";
  private userActivateUrl =
    environment.apiUrl + environment.portUsers + "userActivate";
  private userDeactivateUrl =
    environment.apiUrl + environment.portUsers + "userDeactivate";
  private resendUserConfirmationUrl =
    environment.apiUrl + environment.portUsers + "resendEmail";
  private getFiltersByUserUrl =
    environment.apiUrl + environment.portMain + "getFiltersByUser";
  private createFilterUrl =
    environment.apiUrl + environment.portMain + "createFilter";
  private filterEditUrl =
    environment.apiUrl + environment.portMain + "filterEdit";
  private deleteFilterByIdUrl =
    environment.apiUrl + environment.portMain + "deleteFilterById";
  private deleteProjectByIdUrl =
    environment.apiUrl + environment.portMain + "deleteProjectById";
  private deleteRespondentByIdUrl =
    environment.apiUrl + environment.portMain + "deleteRespondentById";
  private deleteAssessmentByIdUrl =
    environment.apiUrl + environment.portMain + "deleteAssessmentById";

  constructor(private http: HttpClient) {}

  getSlcqLayout(): Observable<string> {
    const url = `${this.getSlcqLayoutUrl}`;
    return this.http.get<string>(url).pipe();
  }

  // getSlcqReport(idassessment: string, dimensions:): Observable<string> {
  //   const url = `${this.getSlcqReportUrl}/?idassessment=${idassessment}`;
  //   return this.http.get<string>(url).pipe();
  // }
  getSlcqReport(idassessment, dimensions): Observable<Response> {
    return this.http
      .post<Response>(
        this.getSlcqReportUrl,
        { idassessment, dimensions },
        httpOptions
      )
      .pipe();
  }

  getSlcqLayoutAndReport(idassessment: string): Observable<string> {
    const url = `${this.getSlcqLayoutAndReportUrl}/?idassessment=${idassessment}`;
    return this.http.get<string>(url).pipe();
  }

  getAdminAssessment(id: string): Observable<Assessment> {
    const url = `${this.getAdminAssessmentUrl}/?id=${id}`;
    return this.http.get<Assessment>(url).pipe();
  }

  getProjectsAssessments(
    idclient: string,
    idassessmentexclude: string,
    currentclientid?: string
  ): Observable<Assessmentlist> {
    const url = `${this.getProjectsAssessmentsUrl}/?idclient=${idclient}&idassessmentexclude=${idassessmentexclude}&currentclientid=${currentclientid}`;
    return this.http.get<Assessmentlist>(url).pipe();
  }

  getAssessmentDemographics(
    idassessment: string
  ): Observable<DemographicFilterList> {
    const url = `${this.getAssessmentDemographicsUrl}/?idassessment=${idassessment}`;
    return this.http.get<DemographicFilterList>(url).pipe();
  }

  getNamesPerResponse(idassessment: string): Observable<Name[]> {
    const url = `${this.getNamesPerResponseUrl}/?idassessment=${idassessment}`;
    return this.http.get<Name[]>(url).pipe();
  }

  getResponse(idAssessmentResponse: number): Observable<AssessmentResponse> {
    const url = `${this.getResponseUrl}/?idAssessmentResponse=${idAssessmentResponse}`;
    return this.http.get<AssessmentResponse>(url).pipe();
  }

  getResponses(
    id: string,
    noanonymous: string
  ): Observable<AssessmentResponse[]> {
    const url = `${this.getResponsesUrl}/?id=${id}&noanonymous=${noanonymous}`;
    return this.http.get<AssessmentResponse[]>(url).pipe();
  }

  getIndividualReport(idAssessmentResponse: string): Observable<Report> {
    const url = `${this.getIndividualReportUrl}/?idAssessmentResponse=${idAssessmentResponse}`;
    return this.http.get<Report>(url).pipe();
  }

  getAssessmentReport(idassessment: string): Observable<Report> {
    const url = `${this.getAssessmentReportUrl}/?idassessment=${idassessment}`;
    return this.http.get<Report>(url).pipe();
  }

  getOverlayReport(
    idAssessmentOne: string,
    idAssessmentTwo: string
  ): Observable<OverlayReport> {
    const url = `${this.getOverlayReportUrl}/?idAssessmentOne=${idAssessmentOne}&idAssessmentTwo=${idAssessmentTwo}`;
    return this.http.get<OverlayReport>(url).pipe();
  }

  getAssessmentDump(
    idassessment: string,
    limit: number,
    offset: number
  ): Observable<AssessmentDump> {
    const url = `${this.getAssessmentDumpUrl}/?idassessment=${idassessment}&limit=${limit}&offset=${offset}`;
    return this.http.get<AssessmentDump>(url).pipe();
  }

  getClients(id: string): Observable<Client[]> {
    const url = `${this.getClientsUrl}/?idpeople=${id}`;
    return this.http.get<Client[]>(url).pipe();
  }

  getAllClients(): Observable<Client[]> {
    const url = `${this.getAllClientsUrl}`;
    return this.http.get<Client[]>(url).pipe();
  }

  getGeneralDemographics(): Observable<DemographicAll[]> {
    const url = `${this.getGeneralDemographicsUrl}`;
    return this.http.get<DemographicAll[]>(url).pipe();
  }

  getCustomGeneralDemographics(idclient: string): Observable<DemographicAll[]> {
    const url = `${this.getCustomGeneralDemographicsUrl}/?idclient=${idclient}`;
    return this.http.get<DemographicAll[]>(url).pipe();
  }

  submitNewCustomDemographic(customDemographic): Observable<Response> {
    return this.http
      .post<Response>(
        this.submitNewCustomDemographicUrl,
        customDemographic,
        httpOptions
      )
      .pipe();
  }

  submitNewStandardDemographic(demographic): Observable<Response> {
    return this.http
      .post<Response>(
        this.submitNewStandardDemographicUrl,
        demographic,
        httpOptions
      )
      .pipe();
  }

  submitToggleLockDemographic(demographic): Observable<Response> {
    return this.http
      .post<Response>(
        this.submitToggleLockDemographicUrl,
        demographic,
        httpOptions
      )
      .pipe();
  }

  submitUpdateCustomDemographic(customDemographic): Observable<Response> {
    return this.http
      .post<Response>(
        this.submitUpdateCustomDemographicUrl,
        customDemographic,
        httpOptions
      )
      .pipe();
  }

  editClient(client): Observable<Response> {
    return this.http
      .post<Response>(this.submitUpdateClientUrl, client, httpOptions)
      .pipe();
  }

  getGenericReport(filters, dimensions): Observable<GenericReport> {
    return this.http
      .post<GenericReport>(
        this.getGenericReportUrl,
        { filters: filters, dimensions: dimensions },
        httpOptions
      )
      .pipe();
  }

  getGenericReportHtml(filters, dimensions): Observable<GenericReport> {
    return this.http
      .post<GenericReport>(
        this.getGenericReportHtmlUrl,
        { filters: filters, dimensions: dimensions },
        {
          headers: new HttpHeaders({ "Content-Type": "application/json" }),
          responseType: "json",
        }
      )
      .pipe();
  }

  getSidebarElements(filters): Observable<SidebarElements> {
    return this.http
      .post<SidebarElements>(
        this.getSidebarElementsUrl,
        { filters: filters },
        httpOptions
      )
      .pipe();
  }

  postLogin(email: string, password: string): Observable<User> {
    return this.http
      .post<User>(
        this.postLoginUrl,
        { email: email, password: password },
        httpOptions
      )
      .pipe();
  }

  submitProject(project): Observable<Response> {
    return this.http
      .post<Response>(this.submitProjectUrl, project, httpOptions)
      .pipe();
  }

  editProject(project): Observable<Response> {
    return this.http
      .post<Response>(this.editProjectUrl, project, httpOptions)
      .pipe();
  }

  submitAssessment(assessment): Observable<Response> {
    return this.http
      .post<Response>(this.submitAssessmentUrl, assessment, httpOptions)
      .pipe();
  }

  editAssessment(assessment): Observable<Response> {
    return this.http
      .post<Response>(this.editAssessmentUrl, assessment, httpOptions)
      .pipe();
  }

  getProjects(id: string): Observable<Project[]> {
    const url = `${this.getProjectsUrl}/?idclient=${id}`;
    return this.http.get<Project[]>(url).pipe();
  }

  getAssessmentTypes(): Observable<AssessmentType[]> {
    const url = `${this.getAssessmentTypesUrl}`;
    return this.http.get<AssessmentType[]>(url).pipe();
  }

  getAvailableDemographics(idclient): Observable<Response[]> {
    const url = `${this.getAvailableDemographicsUrl}?idclient=${idclient}`;
    return this.http.get<Response[]>(url).pipe();
  }

  validateAssessment(assessment): Observable<Response> {
    return this.http
      .post<Response>(this.validateAssessmentUrl, assessment, httpOptions)
      .pipe();
  }

  getResponseDemographics(
    idassessment: string
  ): Observable<AssessmentResponse> {
    const url = `${this.getResponseDemographicsUrl}/?idAssessmentResponse=${idassessment}`;
    return this.http.get<DemographicFilterList>(url).pipe();
  }

  updatePassword(
    idpeople: string,
    oldPassword: string,
    newPassword: string
  ): Observable<Response> {
    return this.http
      .post<Response>(
        this.updatePasswordUrl,
        {
          idpeople: idpeople,
          oldpassword: oldPassword,
          newpassword: newPassword,
        },
        httpOptions
      )
      .pipe();
  }

  validateEmailForgotPassword(email: string): Observable<Response> {
    return this.http
      .post<Response>(
        this.validateEmailForgotPasswordUrl,
        { email: email },
        httpOptions
      )
      .pipe();
  }

  validateHashPassword(code: string): Observable<Response> {
    return this.http
      .post<Response>(this.validateHashPasswordUrl, { code: code }, httpOptions)
      .pipe();
  }

  updateForgotPassword(
    idpeople: number,
    newPassword: string,
    code: string
  ): Observable<Response> {
    return this.http
      .post<Response>(
        this.updateForgotPasswordUrl,
        { idpeople: idpeople, newpassword: newPassword, code: code },
        httpOptions
      )
      .pipe();
  }

  getUsers(id: string): Observable<User[]> {
    const url = `${this.getUsersUrl}/?idpeople=${id}`;
    return this.http.get<User[]>(url).pipe();
  }

  editUser(user): Observable<Response> {
    return this.http
      .patch<Response>(this.editUserUrl, user, httpOptions)
      .pipe();
  }

  addUser(user): Observable<Response> {
    return this.http.post<Response>(this.addUserUrl, user, httpOptions).pipe();
  }

  userActivate(user): Observable<Response> {
    return this.http
      .patch<Response>(this.userActivateUrl, user, httpOptions)
      .pipe();
  }

  userDeactivate(user): Observable<Response> {
    return this.http
      .patch<Response>(this.userDeactivateUrl, user, httpOptions)
      .pipe();
  }

  resendUserConfirmation(user): Observable<Response> {
    return this.http
      .post<Response>(this.resendUserConfirmationUrl, user, httpOptions)
      .pipe();
  }

  getFiltersByUser(id: string): Observable<User[]> {
    const url = `${this.getFiltersByUserUrl}/?idpeople=${id}`;
    return this.http.get<User[]>(url).pipe();
  }

  createFilter(user): Observable<Response> {
    return this.http
      .post<Response>(this.createFilterUrl, user, httpOptions)
      .pipe();
  }

  filterEdit(user): Observable<Response> {
    return this.http
      .patch<Response>(this.filterEditUrl, user, httpOptions)
      .pipe();
  }

  deleteFilterById(idfilter): Observable<Response> {
    const url = `${this.deleteFilterByIdUrl}/?idfilter=${idfilter}`;
    return this.http.delete<Response>(url).pipe();
  }

  deleteAssessmentById(idassessment): Observable<Response> {
    const url = `${this.deleteAssessmentByIdUrl}/?idassessment=${idassessment}`;
    return this.http.delete<Response>(url).pipe();
  }

  deleteProjectById(idproject): Observable<Response> {
    const url = `${this.deleteProjectByIdUrl}/?idproject=${idproject}`;
    return this.http.delete<Response>(url).pipe();
  }

  deleteRespondentById(idrespondent): Observable<Response> {
    const url = `${this.deleteRespondentByIdUrl}/?idrespondent=${idrespondent}`;
    return this.http.delete<Response>(url).pipe();
  }
}
