import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { SlcqReport, SidebarElements } from "../report";
import { AdminService } from "../admin.service";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import {
  isEmpty,
  find,
  get,
  head,
  uniqBy,
  indexOf,
  sortBy,
  reject,
  differenceBy,
  isUndefined,
  map,
  remove,
} from "lodash";
import { Client } from "../assessment/assessment";
import lang from "./lang";
import {
  SlcqQuadrant,
  SlcqData,
  SlcqLayoutSequence,
  SlcqAssessmentEntireDb,
} from "../bubble-chart-report/bubble-chart-report";
import { Title } from "@angular/platform-browser";
import { BrandingService } from "../branding.service";
declare var require: any;

@Component({
  selector: "app-reporting-tool-slcq",
  templateUrl: "./reporting-tool-slcq.component.html",
  styleUrls: ["./reporting-tool-slcq.component.css"],
})
export class ReportingToolSlcqComponent implements OnInit, AfterViewInit {
  @ViewChild("reportContent", { static: false }) reportContent!: ElementRef;
  currentUser;
  report: SlcqReport;
  sidebarelements: SidebarElements;
  idQueryParam;
  currentdemographics = [];
  idassessmentselectedone = 0;
  idassessmentselectedtwo = 0;
  demgroupselected = "";
  selecteddimension = "assessment";
  watermarkMode = false;
  idcolour = 0;
  colours = [
    "#42d4f4",
    "#bfef45",
    "#ffe119",
    "#f58231",
    "#e6194B",
    "#911eb4",
    "#4363d8",
    "#808000",
    "#9A6324",
    "#800000",
    "#f032e6",
    "#9ecaf2",
    "#aaffc3",
    "#fffac8",
    "#ffd8b1",
    "#fb98a7",
    "#e6beff",
    "#b7a4f4",
    "#cf20a5",
    "#a9a9a9",
  ];
  coloursassigned = [];
  oldcolours = [];
  currentlegends = [];
  namesperresponse = [];
  autodecollapse = true;
  individualIsLoaded = false;
  loading = false;
  demographicOptions = [];
  optionSelected = "";
  sums = [];
  filterselected = {
    name: "",
    idfilter: "",
    demographics: {
      id: "",
      options: [],
    },
    scales: [],
  };
  message = "";
  filterondelete = "";
  lang = "en";
  // Scales
  scales: any = [];
  currentScales = [];
  secondaryScales: any = [];
  currentSecondaryScales: any = [];
  langDictionary = lang;

  // Filters
  clientlist: Client[] = [];

  // Todo: Figure out what variable is needed
  newFilterName = "";
  newFilterClients = [];
  selectedFilter = 0;
  selectedFilterName = "";

  // Todo: Get this list from the endpoint once load and save are working
  // Call this instead
  // myFilters = this.getFilters();
  myFilters = [];

  // SLCQ vars
  slcqQuadrantEntireDb: SlcqQuadrant[] = [];
  slcqAssessmentEntireDb = new SlcqAssessmentEntireDb(); // hardcoded assessment for entire slcq db
  slcqQuadrant: SlcqQuadrant[] = [];
  slcqLayoutSequence = new SlcqLayoutSequence(); // layout sequence is pre hard coded in this var
  bubbleChartWidth: number = 700;

  // tracking for temporarily showing only 1 SLCQ assessment at a time.
  // These allow removing assessments that were previously checked.
  lastSelectionEventTarget: any = null;
  lastSelectionFilterElementIdassessment: number;

  nothingSelected = true;

  branding = {};

  downloadButtonDisabled = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private location: Location,
    private titleService: Title,
    private brandingService: BrandingService
  ) {
    this.branding = brandingService.setBranding(document, titleService);
  }

  getClients(): void {
    // console.log("entered getClients()");
    this.adminService.getAllClients().subscribe((clientlist) => {
      this.clientlist = clientlist["users"];
    });
  }

  assignSidebarElements(sidebarelements, isinitial): void {
    // console.log("entered assignSidebarElements()");

    if (this.sidebarelements == null) {
      this.sidebarelements = sidebarelements;
    } else {
      this.sidebarelements.sidebarDimensions =
        sidebarelements.sidebarDimensions.filter(
          (dimension) => dimension.lang == "en"
        );
    }
    this.sidebarelements.sidebarDimensions = uniqBy(
      this.sidebarelements.sidebarDimensions,
      "text"
    );
    if (
      this.idassessmentselectedone > 0 &&
      this.idassessmentselectedtwo > 0 &&
      isinitial
    ) {
      var selectedoptions = [];
      var selecteddemographics = [];
      selectedoptions.push(this.idassessmentselectedone);
      selectedoptions.push(this.idassessmentselectedtwo);
      this.runSelectAssessment(selectedoptions, selecteddemographics);
    }

    if (
      this.idassessmentselectedone > 0 &&
      this.demgroupselected !== "" &&
      isinitial
    ) {
      this.selecteddimension = "demographic";
      var demographicbox = this.document.getElementById("demographicsbox");
      if (demographicbox) {
        demographicbox.style.display = "block";
      }
      var selectedoptions = [];
      var selecteddemographics = [];
      selectedoptions.push(this.idassessmentselectedone);
      selecteddemographics.push(this.demgroupselected);
      this.runSelectAssessment(selectedoptions, selecteddemographics);
    }
  }

  runSelectAssessment(selectedoptions, selecteddemographics): void {
    // console.log("entered runSelectAssessment()");

    for (let filter of this.sidebarelements.sidebarFilters) {
      if (filter.field == "idassessment") {
        var filters = [];
        var demographics = [];
        filters.push({ idassessment: selectedoptions });
        if (selecteddemographics.length > 0) {
          demographics.push({ demographicgroup: selecteddemographics[0] });
        }
        this.getSlcqReport(filters, demographics);
        this.currentScales = filters;
        this.currentdemographics = demographics;
        this.getSidebarElements(filters, false);
      }
    }
  }

  getColourPerLegend(item): string {
    // console.log("entered getColourPerLegend()");
    let total = item.count;
    var itemname =
      this.selecteddimension + "-" + item.legend.split("</span>").pop();
    if (this.selecteddimension === "demographic") {
      total = get(find(this.sums, ["name", item.legend]), "count", item.count);
    }
    if (!this.coloursassigned[item.legend]) {
      if (!find(this.currentlegends, ["name", item.legend])) {
        if (itemname in this.oldcolours) {
          this.currentlegends.push({
            classcolor: this.oldcolours[itemname],
            name: item.legend,
            numberOfRespondents: total,
          });
        } else {
          this.coloursassigned[item.legend] = this.colours[this.idcolour];
          this.currentlegends.push({
            classcolor: this.coloursassigned[item.legend],
            name: item.legend,
            numberOfRespondents: total,
          });
          this.idcolour = this.idcolour + 1;
          this.oldcolours[itemname] = this.coloursassigned[item.legend];
        }
      }
      return find(this.currentlegends, ["name", item.legend]).classcolor;
    } else {
      return this.coloursassigned[item.legend];
    }
  }

  totalSum(arrayToSum): void {
    // console.log("entered totalSum()");
    this.sums = [];
    for (let option of arrayToSum.labels) {
      for (let result of option.results) {
        if (!find(this.sums, ["name", result.legend])) {
          this.sums.push({
            name: result.legend,
            count: result.count,
          });
        } else {
          let currentLegendTotal = get(
            find(this.sums, ["name", result.legend]),
            "count",
            0
          );
          this.sums[
            indexOf(this.sums, find(this.sums, ["name", result.legend]))
          ] = {
            name: result.legend,
            count: Number(currentLegendTotal) + Number(result.count),
          };
        }
      }
    }
  }

  assignReport(report): void {
    // console.log("entered assignReport()");
    // console.log("report: " + JSON.stringify(report));
    //this.namesperresponse = [];
    this.report = report;
    this.generateFilters();
    for (let row of report.rows) {
      this.totalSum(head(row.sections));
      for (let section of row.sections) {
        for (var i = 0; i < section.labels.length; i++) {
          let result = section.labels[i];
          if (this.demographicOptions.length > 0) {
            let optionState = find(this.demographicOptions, [
              "name",
              result.name,
            ]);
            result.show = get(optionState, "show", true);
            result.even = get(optionState, "even", !(i % 2 == 0));
            if (
              !find(this.demographicOptions, ["name", result.name]) &&
              this.optionSelected != "dimension"
            ) {
              this.demographicOptions.push({
                name: result.name,
                show: result.show,
                even: result.even,
              });
            }
          } else {
            result.show = true;
            result.even = !(i % 2 == 0);
            if (!find(this.demographicOptions, ["name", result.name])) {
              this.demographicOptions.push({
                name: result.name,
                show: true,
                even: result.even,
              });
            }
          }

          for (let itemresult of result.results) {
            for (let name of this.namesperresponse) {
              if (itemresult.legend == name.idassessmentresponse) {
                itemresult.legend =
                  '<span tag="' +
                  name.idassessmentresponse +
                  '"></span>' +
                  name.name +
                  " " +
                  name.lastname;
              }
            }
            itemresult.classcolor = this.getColourPerLegend(itemresult);
          }
        }
        section.labels = sortBy(section.labels, ["show", "name"]);
        for (let i = section.labels.length - 1; i >= 0; i--) {
          section.labels[i].even = !(i % 2 == 0);
        }
        if (
          differenceBy(this.demographicOptions, section.labels, "name").length >
            0 &&
          isEmpty(get(this.filterselected, "demographics.id", ""))
        ) {
          this.demographicOptions = reject(
            section.labels,
            (option) => !find(this.demographicOptions, ["name", option.name])
          );
        }
      }
    }

    // console.log("setting this.loading to false in assignReport()");
    // this.loading = false;
    this.demographicOptions = sortBy(this.demographicOptions, ["name"]);
  }

  attachReport(report): void {
    // console.log("entered attachReport()");
    if (isEmpty(this.report)) {
      this.assignReport(report);
    } else {
      for (let row of report.rows) {
        for (let section of row.sections) {
          for (let result of section.labels) {
            for (let main_row of this.report.rows) {
              for (let main_section of main_row.sections) {
                if (main_section.id == section.id) {
                  for (let name of this.namesperresponse) {
                    if (result.results[0].legend == name.idassessmentresponse) {
                      result.results[0].legend =
                        '<span tag="' +
                        name.idassessmentresponse +
                        '"></span>' +
                        name.name +
                        " " +
                        name.lastname;
                    }
                  }
                  result.results[0].classcolor = this.getColourPerLegend(
                    result.results[0]
                  );
                  if (
                    !find(
                      main_section.labels[0].results,
                      (temp_result) =>
                        temp_result.legend == result.results[0].legend
                    )
                  ) {
                    if (this.selecteddimension == "demographic") {
                      if (report.individual && this.demgroupselected) {
                        if (
                          !find(main_section.labels, ["name", " Respondents"])
                        ) {
                          main_section.labels.unshift({
                            name: " Respondents",
                            results: [],
                            show: true,
                            even: false,
                          });
                        }
                        find(main_section.labels, [
                          "name",
                          " Respondents",
                        ]).results.push(result.results[0]);
                      } else {
                        find(main_section.labels, [
                          "name",
                          result.name,
                        ]).results.push(result.results[0]);
                      }
                    } else {
                      main_section.labels[0].results.push(result.results[0]);
                    }
                  }
                }
                main_section.labels = sortBy(main_section.labels, [
                  "show",
                  "name",
                ]);
                for (let i = main_section.labels.length - 1; i >= 0; i--) {
                  main_section.labels[i].even = !(i % 2 == 0);
                }
              }
            }
          }
        }
      }
      // this.loading = false;
    }
  }

  generateFilters() {
    // console.log("entered generateFilters()");
    this.scales = [];
    if (get(this.filterselected, "idfilter", "")) {
      // this.report.rows = this.filterselected.scales;
      for (const scale of this.filterselected.scales) {
        let currentScale = find(this.report.rows, [
          "component",
          scale.component,
        ]);
        if (!isEmpty(currentScale)) {
          currentScale.selected = scale.selected;
          for (const section of currentScale.sections) {
            let currentSection = find(scale.sections, ["text", section.text]);
            section.selected = get(currentSection, "selected", true);
          }
        }
      }
    } else {
      this.report.rows.forEach((row) => {
        row.selected = get(row, "selected", true);
        row.sections.forEach((section) => {
          section.selected = get(section, "selected", true);
        });
      });
    }
  }

  getSidebarElements(filters, isinitial) {
    // console.log("entered getSidebarElements()");
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    filters.push({ idpeople: currentUser.idpeople });
    this.adminService
      .getSidebarElements(filters)
      .subscribe((sidebarelements) => {
        let sidebarbody = JSON.parse(get(sidebarelements, "body", {}));

        // filter everything out except SLCQ assessments
        sidebarbody.sidebarFilters = sidebarbody.sidebarFilters.filter(
          (sidebarFilters) => {
            const options = sidebarFilters.options.filter((options) => {
              const projects = options.projects.filter((projects) => {
                const assessments = projects.assessments;
                if (assessments.length > 0) {
                  projects.assessments = assessments;
                  return true;
                } else {
                  return false;
                }
              });
              if (projects.length > 0) {
                options.projects = projects;
                return true;
              } else {
                return false;
              }
            });
            if (options.length > 0) {
              sidebarFilters.options = options;
              return true;
            } else {
              return false;
            }
          }
        );
        // console.log("sidebarbody: " + JSON.stringify(sidebarbody));

        if (sidebarbody.sidebarFilters.length > 0) {
          for (let clientitem of sidebarbody.sidebarFilters[0].options) {
            for (let projectitem of clientitem.projects) {
              for (let assessmentitem of projectitem.assessments) {
                this.adminService
                  .getNamesPerResponse(assessmentitem.idassessment)
                  .subscribe((namesperresponse) => {
                    assessmentitem.names = []; // remove individual responses
                    // assessmentitem.names = namesperresponse;
                    // for (let name of namesperresponse) {
                    //   console.log("name: " + JSON.stringify(name));
                    //   this.namesperresponse.push(name);
                    // }
                  });
              }
            }
          }
        }

        // console.log("setting this.loading = false in getSidebarElements()");
        // this.loading = false;
        this.assignSidebarElements(sidebarbody, isinitial);
        this.getIndividualReport();
      });
  }

  getSlcqReport(filters, dimensions): void {
    // console.log("entered getSlcqReport()");

    // this.demographicOptions = [];
    let currentsecondarydimensions = dimensions;
    // this.loading = true;
    // this.adminService.getSlcqReport(filters, dimensions).subscribe((report) => {
    //   console.log("in getSlcqReport() report: " + JSON.stringify(report));
    //   if (report != null && !isUndefined(report) && isEmpty(report.rows)) {

    //     console.log("setting this.loading = false in getSlcqReport()");
    //     // this.loading = false

    //     this.document.getElementById("alert-info").classList.add('show');
    //     setTimeout(() => {
    //       this.document.getElementById("alert-info").classList.remove('show');
    //     }, 5000);
    //   } else {
    //     this.assignReport(report);

    //     if (this.currentSecondaryScales.length > 0) {
    //       for (let item_idassessmentresponse of this.currentSecondaryScales[0]['idassessmentresponse']) {
    //         var secondaryScales = [];
    //         var selectedoptions_idassessmentresponse = [];
    //         selectedoptions_idassessmentresponse.push(item_idassessmentresponse);
    //         secondaryScales.push({ "idassessmentresponse": selectedoptions_idassessmentresponse });
    //         if (this.selecteddimension != 'demographic') {
    //           currentsecondarydimensions = []
    //         }
    //         this.attachGenericReport(secondaryScales, currentsecondarydimensions);
    //       }
    //     } else {
    //       report.individual = false;
    //     }
    //   }
    // });
    // console.log("exiting getSlcqReport()");
  }

  // call API for specific ID response
  attachGenericReport(filters, dimensions): void {
    // console.log("entered attachGenericReport()");
    // this.adminService.getSlcqReport(filters, dimensions).subscribe((report) => {
    //   // console.log("setting this.loading = false in attachGenericReport()");
    //   // this.loading = false;
    //   report.individual = true;
    //   this.attachReport(report);
    // });
  }

  getInitialLoad(): void {
    // console.log("entered getInitialLoad()");
    // this.loading = true;
    this.route.queryParams.subscribe((params) => {
      if (
        typeof params.idassessment !== "undefined" &&
        typeof params.demographicgroup !== "undefined"
      ) {
        this.idassessmentselectedone = +params.idassessment;
        this.demgroupselected = params.demographicgroup;
        // this.selectDimension('demographic', false);
      }
      if (
        typeof params.idassessmentone !== "undefined" &&
        typeof params.idassessmenttwo !== "undefined"
      ) {
        this.idassessmentselectedone = +params.idassessmentone;
        this.idassessmentselectedtwo = +params.idassessmenttwo;
      }
    });
  }

  ngOnInit() {
    // console.log("entered ngOnInit()");
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.getInitialLoad();
    this.getSidebarElements([], true);
    this.getClients();
    // this.getFilters();
    this.initQuadrants();
  }

  ngAfterViewChecked() {
    // console.log("entered ngAfterViewChecked()");
    const id = this.route.snapshot.paramMap.get("id");
    this.idQueryParam = id;
    const response = this.document.getElementById(id);

    if (!isEmpty(response) && this.autodecollapse) {
      const parent = response.closest(".collapse");
      const grandpa = parent.closest(".collapse.projects-container");
      const grandgrandpa = grandpa.closest(".collapse.section");
      parent.classList.add("show");
      grandpa.classList.add("show");
      grandgrandpa.classList.add("show");
      this.autodecollapse = false;
    }
  }

  getIndividualReport(): void {
    // console.log("entered getIndividualReport()");
    if (!this.individualIsLoaded) {
      const id = this.route.snapshot.paramMap.get("id");
      this.idQueryParam = id;
      if (id != null) {
        this.currentScales.push({ idassessmentresponse: [this.idQueryParam] });
        this.attachGenericReport([{ idassessmentresponse: [id] }], []);
        this.individualIsLoaded = true;
      }
    }
  }

  selectElement($event, filterelement): void {
    console.log("entered selectElement()");
    // console.log("$event:");
    // console.log($event);
    // console.log("filterelement:");
    // console.log(filterelement);

    // This block of code was added for temporarily showing only 1 SLCQ assessment at a time.
    // These allow removing assessments that were previously checked and showing only the one just checked.
    if (this.lastSelectionEventTarget != null) {
      console.log("this.lastSelectionEvent is not null");

      this.lastSelectionEventTarget.checked = false;
      this.removeSlcq(this.lastSelectionFilterElementIdassessment);

      this.lastSelectionEventTarget = $event.target;
      this.lastSelectionFilterElementIdassessment = filterelement.idassessment;
    } else {
      this.lastSelectionEventTarget = $event.target;
      this.lastSelectionFilterElementIdassessment = filterelement.idassessment;
    }

    // this.loading = true;
    this.selectElementName(
      $event.target.name,
      filterelement,
      $event.target.checked
    );
  }

  toggleDemographicOption(demographicOption): void {
    // console.log("entered toggleDemographicOption()");
    let rows = get(this.report, "rows", []);
    // console.log("report rows: " + JSON.stringify(rows));
    for (let row of rows) {
      for (let section of row.sections) {
        let modifiedIndex;
        find(section.labels, (label) => {
          if (label.name === demographicOption.name) {
            modifiedIndex = indexOf(section.labels, label);
            label.show = !label.show;
            let option = find(this.demographicOptions, [
              "name",
              demographicOption.name,
            ]);
            option.show = label.show;
            option.even = label.even;
          }
        });
        section.labels = sortBy(section.labels, ["show", "name"]);
        for (let i = section.labels.length - 1; i >= 0; i--) {
          section.labels[i].even = !(i % 2 === 0);
        }
      }
    }
  }

  clearAllSelections(): void {
    // console.log("entered clearAllSelections()");
    this.selecteddimension = "assessment";
    this.optionSelected = "";
    this.currentScales = [];
    this.currentSecondaryScales = [];
    this.currentdemographics = [];
    this.individualIsLoaded = false;
    this.demographicOptions = [];
    this.autodecollapse = true;
    this.report = null;
    this.idcolour = 0;
    this.coloursassigned = [];
    this.oldcolours = [];
    this.currentlegends = [];
    this.getSidebarElements([], false);

    var options = this.document.getElementsByName("elementCheckbox-1");
    for (let option of <any>options) {
      if (option.getAttribute("secondTag") == "clearable") {
        option.checked = false;
      }
    }
    this.selectedFilter = 0;
    this.filterselected = {
      name: "",
      idfilter: "",
      demographics: {
        id: "",
        options: [],
      },
      scales: [],
    };
    this.newFilterClients = [];
    this.newFilterName = "";
    this.selectedFilterName = "";
  }

  selectElementName(name, filterelement, checked): void {
    // console.log("entered selectElementName()");

    // console.log("name: " + JSON.stringify(name));
    // console.log("filterelement: " + JSON.stringify(filterelement));
    // console.log("checked: " + JSON.stringify(checked));
    var options = this.document.getElementsByName(name);
    // console.log("options: " + JSON.stringify(options));

    if (checked) {
      this.addSlcq(filterelement.idassessment, filterelement.assessmentname);
    } else {
      // remove only this assessment
      this.removeSlcq(filterelement.idassessment);
    }

    var filters = [];
    var secondaryScales = [];
    var selectedoptions_idassessment = [];
    var selectedoptions_idassessmentresponse = [];
    var dimensions = this.currentdemographics;
    if (!checked) {
      if (
        filterelement.idassessmentresponse &&
        filterelement.name &&
        filterelement.lastname
      ) {
        var nameElement =
          '<span tag="' +
          filterelement.idassessmentresponse +
          '"></span>' +
          filterelement.name +
          " " +
          filterelement.lastname;
        remove(this.currentlegends, ["name", nameElement]);
        delete this.coloursassigned[nameElement];
      }
      delete this.coloursassigned[filterelement.assessmentname];
      remove(this.currentlegends, ["name", filterelement.assessmentname]);

      if (
        this.currentlegends.length == 0 ||
        this.idcolour == this.colours.length - 1
      ) {
        this.idcolour = 0;
      }
    }

    for (let option of <any>options) {
      if (option.checked) {
        if (option.getAttribute("tag") == "idassessment") {
          selectedoptions_idassessment.push(option.id);
        }
        if (option.getAttribute("tag") == "idassessmentresponse") {
          selectedoptions_idassessmentresponse.push(option.id);
        }
      }
    }
    if (selectedoptions_idassessment.length > 0) {
      filters.push({ idassessment: selectedoptions_idassessment });
    }
    if (selectedoptions_idassessmentresponse.length > 0) {
      secondaryScales.push({
        idassessmentresponse: selectedoptions_idassessmentresponse,
      });
    }

    this.currentSecondaryScales = secondaryScales;
    this.currentScales = filters;
    let currentsecondarydimensions = dimensions;
    if (
      (!(this.selecteddimension === "demographic") &&
        this.currentdemographics.length == 0) ||
      this.selecteddimension === "assessment"
    ) {
      dimensions = [];
    }

    if (filters.length > 0) {
      this.getSlcqReport(filters, dimensions);
    } else {
      if (secondaryScales.length > 0) {
        this.report = null;
        for (let item_idassessmentresponse of this.currentSecondaryScales[0][
          "idassessmentresponse"
        ]) {
          var secondaryScales = [];
          var selectedoptions_idassessmentresponse = [];
          selectedoptions_idassessmentresponse.push(item_idassessmentresponse);
          secondaryScales.push({
            idassessmentresponse: selectedoptions_idassessmentresponse,
          });
          if (this.selecteddimension !== "demographic") {
            currentsecondarydimensions = [];
          }
          this.attachGenericReport(secondaryScales, currentsecondarydimensions);
        }
      } else {
        this.report = null;
        this.currentdemographics = [];
        this.demographicOptions = [];
      }
    }

    this.getSidebarElements(filters, false);

    // console.log("exiting selectElementName().  this.report: " + JSON.stringify(this.report));
  }

  selectDemographic(dimensionId): void {
    // console.log("entered selectDemographic(). dimensionId arg = " + dimensionId);
    var options = this.document.getElementsByName("demographic");
    var selectedoptions = [];
    for (let option of <any>options) {
      if (option.checked) {
        selectedoptions.push(option.id);
      }
    }
    var demographics = [];

    if (selectedoptions.length > 0) {
      demographics.push({ demographicgroup: selectedoptions[0] });
      this.currentdemographics = demographics;
      this.getSlcqReport(this.currentScales, demographics);
    } else {
      this.report = null;
    }
    this.demgroupselected = dimensionId;
    this.optionSelected = "demographic";
    this.demographicOptions = [];

    // console.log("at end of selectDemographic().  Here's the vars:");
    // console.log("selectedoptions: " + JSON.stringify(selectedoptions));
    // console.log("demographics: " + JSON.stringify(demographics));
    // console.log("this.report: " + JSON.stringify(this.report));
  }

  selectDimension(dimension, fromClear): void {
    // console.log("entered selectDimension()");
    var demographicbox = this.document.getElementById("demographicsbox");
    if (dimension === "demographic") {
      this.selecteddimension = "demographic";
      this.getSlcqReport(this.currentScales, this.currentdemographics);
      if (demographicbox) {
        demographicbox.style.display = "block";
      }
    } else {
      if (demographicbox) {
        demographicbox.style.display = "none";
      }
    }

    if (dimension === "assessment") {
      this.selecteddimension = "assessment";
      this.demgroupselected = "";
      this.currentdemographics = [];
      this.getSlcqReport(this.currentScales, []);
    }
    this.optionSelected = "dimension";
    for (let key in this.oldcolours) {
      var newKey = dimension + "-" + key.split("-").pop();
      let value = this.oldcolours[key];
      delete this.oldcolours[key];
      this.oldcolours[newKey] = value;
    }
  }

  componentChanged(component) {
    // console.log("entered componentChanged()");
    component.selected = !component.selected;

    component.sections.forEach((section) => {
      section.selected = component.selected;
    });
  }

  download() {
    // this.detectScreenSize();
    window.onafterprint = () => {
      this.detectScreenSize();
      this.downloadButtonDisabled = false;
      // this.document.body.style.zoom = "100%";
    };
    this.downloadButtonDisabled = true;
    this.bubbleChartWidth = 460;

    setTimeout(() => {
      window.print();
    }, 2000);
    //after print, call detectScreenSize to turn the charts back into the normal size
    //window.print();
  }

  downloadOld() {
    // console.log("entered download()");
    let legends = [];
    this.report["legends"].forEach((l) => {
      legends.push(l.name);
    });
    let filename = legends.join("-");
    const html2pdf = require("html2pdf.js");
    const opt = {
      filename: filename + ".pdf",
      margin: 10,
      jsPDF: { orientation: "landscape" },
    };

    this.watermarkMode = true;
    setTimeout(() => {
      html2pdf()
        .from(this.reportContent.nativeElement)
        .set(opt)
        .save()
        .then(() => {
          this.watermarkMode = false;
        });
    }, 100);
  }

  // getFilters(filtername = '') {
  //   console.log("entered getFilters()");

  //   const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  //   this.adminService.getFiltersByUser(currentUser.idpeople).subscribe((filtersList) => {
  //     this.myFilters = map(filtersList, (el) => {
  //       var o = Object.assign({}, el);
  //       o.selected = false;
  //       return o;
  //     });
  //     // console.log("setting this.loading = false in getFilters()");
  //     // this.loading = false;
  //     if (filtername) { this.loadFilter(filtername); }
  //   });
  // }

  ableConfirmDeleteFooter(idfilter) {
    // console.log("entered ableConfirmDeleteFooter()");
    this.filterondelete = idfilter;
    this.document
      .getElementById("filter-delete-confirmation")
      .classList.add("show");
  }

  // deleteFilter() {
  //   console.log("entered deleteFilter()");
  //   // this.loading = true;
  //   this.adminService.deleteFilterById(this.filterondelete).subscribe((response) => {
  //     if (response.responseid == '1') {
  //       this.document.getElementById("alert-info-success").classList.add('show');
  //       this.getFilters();
  //       if (this.filterondelete == get(this.filterselected, 'idfilter', '')) {
  //         this.clearFilter();
  //       }
  //     }
  //     else {
  //     }
  //     this.document.getElementById("filter-delete-confirmation").classList.remove('show');
  //   });

  // }

  // clearFilter() {
  //   console.log("entered clearFilter()");
  //   this.selectedFilter = 0;
  //   this.filterselected = {
  //     name: '',
  //     idfilter: '',
  //     demographics: {
  //       id: '',
  //       options: []
  //     },
  //     scales: []
  //   };
  //   this.newFilterClients = [];
  //   this.newFilterName = '';
  //   this.selectedFilterName = '';
  //   // Default back to assessment
  //   this.report.rows.forEach((row) => {
  //     row.selected = true;
  //     row.sections.forEach((section) => {
  //       section.selected = true;
  //     });
  //   });

  //   // Todo: Populate the filters (dimension and scales) with default values.
  // }

  // filterEdit() {
  //   console.log("entered filterEdit()");
  //   // Todo: build a filter object and send it to the backend.
  //   const filter = {
  //     idfilter: this.filterselected.idfilter,
  //     clients: this.newFilterClients,
  //     configuration: {
  //       demographics: {
  //         id: this.demgroupselected,
  //         options: this.demographicOptions
  //       },
  //       scales: this.report.rows
  //     }
  //   };

  //   this.adminService.filterEdit(filter).subscribe((response) => {
  //     // Todo: handle the response: display message on screen
  //     // console.log(response);
  //     if (response.responseid == '1') {
  //       this.getFilters(this.filterselected.name);
  //       this.message = response.responsetxt;
  //       this.document.getElementById("alert-info-success").classList.add('show');
  //       setTimeout(() => {
  //         this.document.getElementById("alert-info-success").classList.remove('show');
  //       }, 3000);
  //     } else {
  //       this.getFilters();
  //       this.message = 'something went wrong.'
  //       this.document.getElementById("alert-info-danger").classList.add('show');
  //       setTimeout(() => {
  //         this.document.getElementById("alert-info-danger").classList.remove('show');
  //       }, 3000);
  //     }
  //   });
  // }

  // saveFilter() {
  //   console.log("entered saveFilter()");
  //   // Todo: build a filter object and send it to the backend.
  //   const filter = {
  //     filtername: this.newFilterName,
  //     clients: this.newFilterClients,
  //     configuration: {
  //       demographics: {
  //         id: this.demgroupselected,
  //         options: this.demographicOptions
  //       },
  //       scales: this.report.rows
  //     }
  //   };

  //   this.adminService.createFilter(filter).subscribe((response) => {
  //     // Todo: handle the response: display message on screen
  //     if (response.responseid == '1') {
  //       this.getFilters(filter.filtername);
  //       this.message = response.responsetxt;
  //       this.document.getElementById("alert-info-success").classList.add('show');
  //       setTimeout(() => {
  //         this.document.getElementById("alert-info-success").classList.remove('show');
  //       }, 3000);
  //     } else {
  //       this.getFilters();
  //       this.message = 'something went wrong.'
  //       this.document.getElementById("alert-info-danger").classList.add('show');
  //       setTimeout(() => {
  //         this.document.getElementById("alert-info-danger").classList.remove('show');
  //       }, 3000);
  //     }

  //   });
  // }

  // loadFilter(filterName) {
  //   console.log("entered loadFilter()");
  //   this.filterselected = this.myFilters.find(filter => filter.name === filterName);
  //   if (this.filterselected && this.filterselected.demographics.options.length !== 0) {

  //     // Show dimensions options (assessment or demographic)
  //     // this.selectDimension('demographic');

  //     // this.demgroupselected = this.filterselected.demographics.id;
  //     // this.demographicOptions = this.filterselected.demographics.options;
  //     for (const scale of this.filterselected.scales) {
  //       let currentScale = find(this.report.rows, ['component', scale.component]);
  //       if (!isEmpty(currentScale)) {
  //         currentScale.selected = scale.selected
  //         for (const section of currentScale.sections) {
  //           let currentSection = find(scale.sections, ['text', section.text]);
  //           section.selected = get(currentSection, 'selected', true);
  //         };
  //       }
  //     }

  //     const demographics = [];
  //     if (this.demgroupselected) { demographics.push({ 'demographicgroup': this.demgroupselected }); }
  //     this.currentdemographics = demographics;
  //     // this.getSlcqReport(this.currentScales, demographics);
  //     let currentfilter = find(this.myFilters, ['name', filterName]);
  //     currentfilter.selected = true;
  //     this.newFilterClients = currentfilter.clients
  //     this.selectedFilterName = filterName;
  //   } else {
  //     this.clearFilter();
  //   }
  // }

  /////////////////////////////////////////////////////////////////////////////////////
  // build SLCQ quadrant data

  async initQuadrants() {
    // console.log("entered initQuadrants()");
    // get the layout for the quadrants
    const quadrantLayout = await this.adminService.getSlcqLayout().toPromise();
    // console.log('quadrantLayout:');
    // console.log(quadrantLayout);
    // console.log(JSON.stringify(quadrantLayout));

    this.slcqQuadrantEntireDb = this.createQuadrants(
      this.slcqQuadrantEntireDb,
      quadrantLayout
    );

    this.slcqQuadrantEntireDb = this.addDataToQuadrants(
      this.slcqQuadrantEntireDb,
      { quadrants: this.slcqAssessmentEntireDb.assessment },
      -1,
      "Entire Database"
    );

    this.slcqQuadrant = this.createQuadrants(this.slcqQuadrant, quadrantLayout);
  }

  async addSlcq(idassessment, assessmentname) {
    // console.log("entering addSlcq()");

    this.nothingSelected = false;
    this.loading = true;

    // const id = this.route.snapshot.paramMap.get('id');
    // get the layout for the quadrants
    const slcqAssessment = await this.adminService
      .getSlcqReport(idassessment, [])
      .toPromise();
    // console.log('slcqAssessment:');
    // console.log(JSON.stringify(slcqAssessment));

    this.slcqQuadrant = this.addDataToQuadrants(
      this.slcqQuadrant,
      slcqAssessment,
      idassessment,
      assessmentname
    );

    // console.log("this.slcqQuadrant:");
    // console.log(this.slcqQuadrant);

    // console.log("setting this.loading = false in addSlcq()");
    this.loading = false;

    // console.log("exiting addSlcq()");
  }

  async removeSlcq(idassessment) {
    // console.log("entering removeSlcq()");

    // make a temp holder to make changes to
    // (this was for an experiment but is no longer necessary)
    let tempSlcqQuadrant = this.slcqQuadrant;

    this.loading = true;

    for (let i = 0; i < tempSlcqQuadrant.length; i++) {
      for (let j = 0; j < tempSlcqQuadrant[i].slcqData.length; j++) {
        if (tempSlcqQuadrant[i].slcqData[j].idassessment == idassessment) {
          // console.log("found the assessment data");
          // remove it
          tempSlcqQuadrant[i].slcqData.splice(j, 1);
        }
      }
    }

    // console.log("tempSlcqQuadrant");
    // console.log(tempSlcqQuadrant);

    // if theres no data left
    if (tempSlcqQuadrant[0].slcqData.length == 0) {
      this.nothingSelected = true;
    }

    this.slcqQuadrant = tempSlcqQuadrant;

    this.triggerBubbleChartChange();

    // console.log("setting this.loading = false in removeSlcq()");
    this.loading = false;

    // console.log("exiting removeSlcq()");
  }

  triggerBubbleChartChange() {
    for (let i = 0; i < this.slcqQuadrant.length; i++) {
      this.slcqQuadrant[i].dataChangeTrigger++;
    }
  }

  createQuadrants(slcqQuadrant, quadrantLayout): SlcqQuadrant[] {
    // console.log('quadrantLayout: ' + JSON.stringify(quadrantLayout));

    let counter = 0;
    // for (const quadrant of quadrantLayout) {
    for (let i = 0; i < quadrantLayout.length; i++) {
      // console.log("i=" + i + ", counter=" + counter);

      const quadrant = quadrantLayout[i];
      // console.log('quadrant: ' + JSON.stringify(quadrant));

      slcqQuadrant[counter] = new SlcqQuadrant();

      slcqQuadrant[counter].quadrantName = quadrant.quadrantname;
      slcqQuadrant[counter].quadrantPhrase1 = quadrant.quadrantphrase1;
      slcqQuadrant[counter].quadrantPhrase2 = quadrant.quadrantphrase2;
      slcqQuadrant[counter].sectionName = quadrant.sectionname;

      slcqQuadrant[counter].quadTitle1.line1 = quadrant.q1line1;
      slcqQuadrant[counter].quadTitle1.line2 = quadrant.q1line2;
      slcqQuadrant[counter].quadTitle2.line1 = quadrant.q2line1;
      slcqQuadrant[counter].quadTitle2.line2 = quadrant.q2line2;
      slcqQuadrant[counter].quadTitle3.line1 = quadrant.q3line1;
      slcqQuadrant[counter].quadTitle3.line2 = quadrant.q3line2;
      slcqQuadrant[counter].quadTitle4.line1 = quadrant.q4line1;
      slcqQuadrant[counter].quadTitle4.line2 = quadrant.q4line2;

      slcqQuadrant[counter].xLabel = quadrant.xlabel;
      slcqQuadrant[counter].xLabelLeft = quadrant.xscaleleft;
      slcqQuadrant[counter].xLabelRight = quadrant.xscaleright;

      slcqQuadrant[counter].yLabel = quadrant.ylabel;
      slcqQuadrant[counter].yLabelTop = quadrant.yscaletop;
      slcqQuadrant[counter].yLabelBottom = quadrant.yscalebottom;

      slcqQuadrant[counter].columnNames = [
        "Id",
        slcqQuadrant[counter].xLabel,
        slcqQuadrant[counter].yLabel,
        "",
        "n",
      ];

      slcqQuadrant[counter].showTooltips = true;

      counter++;
    }

    // cleanup the sectionName so it only shows at the beginning of each component section
    let lastSectionName = "";
    for (let i = 0; i < slcqQuadrant.length; i++) {
      if (slcqQuadrant[i].sectionName === lastSectionName) {
        // remove the sectionName so it doesn't show up on the page as a new section
        // console.log("removing " + slcqQuadrant[i].sectionName + " at index " + i);
        slcqQuadrant[i].sectionName = null;
      } else {
        lastSectionName = slcqQuadrant[i].sectionName;
      }
    }

    // console.log("exiting createQuadrants()");

    return slcqQuadrant;
  }

  addDataToQuadrants(
    slcqQuadrant,
    slcq,
    idassessment,
    assessmentname
  ): SlcqQuadrant[] {
    const slcqAssessment = get(slcq, 'quadrants', []);
    console.log(
      "entered addDataToQuadrants().  slcqAssessment.length=" +
        slcqAssessment.length
    );
    // console.log("assessmentname:" + assessmentname);

    let counter = 0;
    // create data for all quadrants
    for (let i = 0; i < 9; i++) {
      // console.log("processing element " + i);

      // find the quadrant
      let slcqAssessmentData = null;
      let foundQuadrant = false;
      for (let j = 0; j < slcqAssessment.length; j++) {
        if (slcqAssessment[j].quadnumber == i + 1) {
          slcqAssessmentData = slcqAssessment[j];
          foundQuadrant = true;
        }
      }

      if (!foundQuadrant) {
        // create an empty set of data
        slcqAssessmentData = {
          quadnumber: i + 1,
          totalresponses: 0,
          q1percent: 0,
          q2percent: 0,
          q3percent: 0,
          q4percent: 0,
          xaxismean: 0,
          yaxismean: 0,
        };
      }

      // console.log("slcqAssessmentData: ");
      // console.log(slcqAssessmentData);

      let slcqData: SlcqData = new SlcqData();

      slcqData.idassessment = idassessment;
      slcqData.assessmentname = assessmentname;

      slcqData.meanX = slcqAssessmentData.xaxismean;
      slcqData.meanY = slcqAssessmentData.yaxismean;

      slcqData.quad1Percent = slcqAssessmentData.q1percent;
      slcqData.quad2Percent = slcqAssessmentData.q2percent;
      slcqData.quad3Percent = slcqAssessmentData.q3percent;
      slcqData.quad4Percent = slcqAssessmentData.q4percent;

      slcqData.totalResponses = slcqAssessmentData.totalresponses;

      slcqQuadrant[counter].slcqData.push(slcqData);
      slcqQuadrant[counter].dataChangeTrigger++;

      counter++;
    }

    // console.log("slcqQuadrant: ");
    // console.log(slcqQuadrant);

    return slcqQuadrant;
  }

  /////////////////////////////////////////////
  // these 3 methods handle window size changes
  @HostListener("window:resize", [])
  private onResize() {
    // console.log("entered onResize");
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    // console.log("entered ngAfterViewInit");
    this.detectScreenSize();
  }

  private detectScreenSize() {
    var width = document.getElementById("slcqReportContainer").offsetWidth;
    // console.log("in detectScreenSize() width: " + width);
    this.bubbleChartWidth = width / 2 - (20 + 40 + 30 + 15 + 15);
  }
}
