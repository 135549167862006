import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../admin.service';
import { PermissionService } from '../permission.service';
import { Client } from '../assessment';
import { capitalize, isEmpty } from 'lodash';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  message = '';
  messageclass = '';
  newUser: object;
  allUsers;
  users;
  isAdmin = false;
  editingUser: object = {};
  clientlist: Client[];
  searchTerm: '';
  error = false;

  constructor(private router: Router , private adminService: AdminService, private permissionService: PermissionService, @Inject(DOCUMENT) private document: Document,) {
    this.newUser = {
      name: '',
      lastname: '',
      email: '',
      isadmin: false,
      clients: [],
    };
  }

  getClients(): void {
    this.adminService.getAllClients().subscribe((clientlist) => {
      this.clientlist = clientlist['users'];
      // Alphabetically sort the client list with .Sort + arroy function
      this.clientlist.sort(( a, b ) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      // console.log(this.clientlist);
    });
  }

  getUsers(idpeople): void {
    this.adminService.getUsers(idpeople).subscribe((usersList) => {
      this.allUsers = usersList['users'];
      this.users = this.sortUsers(this.allUsers);
      console.log(this.allUsers);
    });
  }

  ngOnInit() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.getClients();
    this.getUsers(currentUser.idpeople);
    this.isAdmin = this.permissionService.isAdmin();
    this.error = false;
    this.message = '';
  }

  setUserEdit(user): void {
    this.editingUser = {
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      clients: this.getUserClientsObj(user.clients),
      isadmin: user.isadmin,
      idpeople: user.idpeople,
    };
    console.log('Loading User ', this.editingUser);
  }

  addUser() {
    this.adminService.addUser(this.newUser).subscribe((response) => {
      const modal = this.document.getElementById('modal-add-user');
      if(response.responseid === '1') {
        this.message = 'New user has been created';
        this.messageclass = 'alert-success';
        this.ngOnInit();
        window.scrollTo(0, 0);
      }
      else if (response.responseid === '0') {
        this.messageclass = 'alert-danger';
        if (!isEmpty(response.responsetxt)) {
          this.message = capitalize(response.responsetxt);
          this.error = true;
        } else {
          this.message = 'An error has occurred.';
        }
      } else if (response.responseid === '-1') {
        this.message = response.responsetxt;
        this.error = true;
      }
    });
  }

  editUser() {
    console.log('Edit User', this.editingUser);
    this.adminService.editUser(this.editingUser).subscribe((response) => {
      if(response.responseid === '1') {
        console.log("success");
        this.message = 'The user has been updated';
        this.messageclass = 'alert-success';
      }
      else {
        console.log("failure");
        this.message = 'An error has occurred.';
        this.messageclass = 'alert-danger';
      }
      this.ngOnInit();
      window.scrollTo(0, 0);
    });
  }

  canAddUser() {
    return this.permissionService.canAddUser();
  }

  sortUsers(users) {
    return users.sort((n1, n2) => {
      if (n1.lastname.toUpperCase() > n2.lastname.toUpperCase()) {
        return 1;
      }

      if (n1.lastname.toUpperCase() < n2.lastname.toUpperCase()) {
        return -1;
      }

      if (n1.lastname.toUpperCase() === n2.lastname.toUpperCase()) {
        if (n1.name > n2.name) {
          return 1;
        }

        if (n1.name.toUpperCase() < n2.name.toUpperCase()) {
          return -1;
        }
      }
      return 0;
    });
  }

  searchUsers() {
    this.users = this.allUsers.filter(user => {
      return user.name.toUpperCase().includes(this.searchTerm.toUpperCase()) ||
        user.lastname.toUpperCase().includes(this.searchTerm.toUpperCase()) ||
        user.email.toUpperCase().includes(this.searchTerm.toUpperCase()) ||
        user.clients.join(', ').toUpperCase().includes(this.searchTerm.toUpperCase());
    });
  }

  refreshUsersList() {
    console.log('Refresh users list');
  }

  private getUserClientsStr(clients) {
    return clients.map(client => client.name).join(', ');
  }

  private getUserClientsObj(clients) {
    return clients.map(client => {
      const clientObj = {};
      clientObj['idclient'] = client.idclient;
      clientObj['name'] = client.name;
      return clientObj;
    });
  }

  private isValidEmail(email: string) {
    return email !== '' && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  }

  setAction(endpoint, user) {
    console.log('Calling Endpoint ', endpoint, user.idpeople);

    if (endpoint === 'userActivate') {
      this.adminService.userActivate({idpeople: user.idpeople}).subscribe((response) => {
        if (response.responseid === '1') {
          this.message = 'User has been Activated';
          this.messageclass = 'alert-success';
        }
        else {
          this.message = 'An error has occurred.';
          this.messageclass = 'alert-danger';
        }
        this.ngOnInit();
        window.scrollTo(0, 0);
      });
    }

    if (endpoint === 'userDeactivate') {
      this.adminService.userDeactivate({idpeople: user.idpeople}).subscribe((response) => {
        if (response.responseid === '1') {
          this.message = 'User has been Deactivated';
          this.messageclass = 'alert-success';
        }
        else {
          this.message = 'An error has occurred.';
          this.messageclass = 'alert-danger';
        }
        this.ngOnInit();
        window.scrollTo(0, 0);
      });
    }

    if (endpoint === 'resendUserConfirmation') {
      this.adminService.resendUserConfirmation({email: user.email}).subscribe((response) => {
        if (response.responseid === '1') {
          this.message = 'Confirmation email sent';
          this.messageclass = 'alert-success';
        }
        else {
          this.message = 'An error has occurred.';
          this.messageclass = 'alert-danger';
        }
        this.ngOnInit();
        window.scrollTo(0, 0);
      });
    }
  }

}
