import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { Client } from '../assessment';
import { Router } from '@angular/router';
import {PermissionService} from '../permission.service';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-clientlist',
  templateUrl: './clientlist.component.html',
  styleUrls: ['./clientlist.component.css']
})
export class ClientlistComponent implements OnInit {

  clientlist: Client[];
  client: object;
  messageclass;
  message;
  editingClient: object = {};
  isAdmin = false;

  constructor(private router: Router,
              private adminService: AdminService,
              private permissionService: PermissionService) {
    this.client = { name:"", primarycontact:"", email:"", idpeople: JSON.parse(localStorage.getItem('currentUser')).idpeople};
  }

  private navigateToClient(id) {
    this.router.navigate(["/clients/" + id + "/projects/assessments"]);
  };

  getClients(idpeople): void {
    this.adminService.getClients(idpeople).subscribe((clientlist) => {
                        this.clientlist = orderBy(clientlist, [client => client.name.toLowerCase()], ['asc']);
                    });
  }

  ngOnInit() {
    this.isAdmin = this.permissionService.isAdmin();
    this.message = "";
    this.messageclass = "";
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let currentClients = JSON.parse(localStorage.getItem('currentClients'));
    this.getClients(currentUser.idpeople);
    if(currentClients.length == 1) {
		  this.router.navigate(['/clients/' + currentClients[0].idclient + '/projects/assessments']);
    }
  }

  private navigateToDemographicsCatalog() {
    this.router.navigate(['/admin/demographics/edit']);
  };

  addClient() {
    console.log("Adding Client");
    this.adminService.editClient(this.client).subscribe((response) => {
      if(response.responseid == "1") {
        this.message = 'New client has been created';
        this.messageclass = 'alert-success';
      }
      else {
        this.message = 'An error has occurred.';
        this.messageclass = 'alert-danger';
      }
      this.ngOnInit();
    });
  }

  setClientEdit(client): void {
    this.editingClient = {
      idclient: client.id,
      name: client.name,
      email: client.email,
      primarycontact: client.primarycontact,
      phone: client.phone,
      idpeople: JSON.parse(localStorage.getItem('currentUser')).idpeople,
    };
  }

  editClient() {
    console.log("Edit Client");
    this.adminService.editClient(this.editingClient).subscribe((response) => {
      if(response.responseid == "0") {
        console.log("success");
        this.message = 'The client has been updated';
        this.messageclass = 'alert-success';
      }
      else {
        console.log("failure");
        this.message = 'An error has occurred.';
        this.messageclass = 'alert-danger';
      }
      this.ngOnInit();
    });
  }

  deleteClient() {
    console.log("Delete Client");
  }

  navigateToProjects(clientId) {
    this.router.navigate(['/clients/' + clientId + '/projects/assessments']);
  }

  canAddClient() {
    return this.permissionService.canAddClient();
  }

  // clientIsAdmin(id) {
  //   return this.permissionService.isAdmin(id);
  // }
}
