import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../admin.service';
import { capitalize, isEmpty } from 'lodash';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BrandingService } from '../branding.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

   email: any;
   password: any;
   errorMessage: string = "";
   
  branding = {};

  constructor(private router: Router,
    private adminService: AdminService,
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private brandingService: BrandingService) {
      
    this.branding = brandingService.setBranding(document, titleService);
  }

  ngOnInit() {
    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['/']);
    }
  }

  login() {

    try {
      this.adminService.postLogin(this.email.toLowerCase(),this.password).subscribe((response) => {
          if (response.responseid === 1) {
            localStorage.setItem('currentUser', JSON.stringify(response.user));
            localStorage.setItem('currentClients', JSON.stringify(response.clients));
            this.errorMessage = "";
            this.router.navigate(['/']);
          } else {
            // ToDo : Messages
            if (!isEmpty(response.responsetxt)) { this.errorMessage = capitalize(response.responsetxt); }
            else { this.errorMessage = "Incorrect username and/or password."; }
          }
        });
    } catch (error) {
      this.errorMessage = "Incorrect username and/or password.";
    }
  }

}
