import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { OverlayReport } from '../report';

@Component({
  selector: 'app-overlayreport',
  templateUrl: './overlayreport.component.html',
  styleUrls: ['./overlayreport.component.css']
})
export class OverlayreportComponent implements OnInit {
  
  report: OverlayReport;
  
  constructor( private router: Router, 
               private route: ActivatedRoute, 
               private adminService: AdminService,
               private location: Location) { }
  
  assignReport(report): void {
    this.report = report;
    console.log(this.report);
    //this.initialLoadComponent();
  } 
  
  getOverlayReport(): void {
    const idone = this.route.snapshot.paramMap.get('idone');
    const idtwo = this.route.snapshot.paramMap.get('idtwo');
    
    this.adminService.getOverlayReport(idone, idtwo).subscribe((report) => {
                        this.assignReport(report);
                    });   
  }

  ngOnInit() {
    this.getOverlayReport();
  }

}
