import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.css']
})
export class BubbleChartComponent implements OnInit {

  @Input() slcqQuadrant: any;
  @Input() bubbleChartWidth: number;

  type = 'BubbleChart';

  options;

  constructor() { }

  ngOnInit() {

    // allow to change the size on the fly & keep the overlay position relative to the chart
    // document.documentElement.style.setProperty('--main-height-and-width', this.slcqQuadrant.widthAndHeight + 'px');
    document.documentElement.style.setProperty("--main-height-and-width", this.bubbleChartWidth + "px");

    
    // if (!this.slcqQuadrant.data) {
    //   this.buildData();
    // }
    this.buildData();
    this.setOptions(); // need to set this from scratch every time or the google charts component will not update

  }
  
  // ngOnChanges(changes: SimpleChanges) {
  //   document.documentElement.style.setProperty("--main-height-and-width", this.bubbleChartWidth + "px");
  //   this.buildData();
  //   this.setOptions(); // need to set this from scratch every time or the google charts component will not update
  // }
  
  setOptions() {

    let showTooltips = "focus";
    if (!this.slcqQuadrant.showTooltips) {
      showTooltips = "none";
    }

    // these sizes look good when the overall chart width = 600px
    // const bubbleMaxSize = 60;
    // const bubbleMinSize = 10;
    // dynamic versions:
    const bubbleMaxSize = this.bubbleChartWidth / 10;
    const bubbleMinSize = this.bubbleChartWidth / 60;

    this.options = {
      "vAxis": {
        "title": this.slcqQuadrant.xLabel,
        "titleTextStyle": { "fontSize": 16, "bold": true },
        "minValue": 1, "maxValue": 4, "baseline": 2.5,
        "gridlines": { "count": 0 }, "minorGridlines": { "count": 0 },
        "ticks": []
      },
      "hAxis": {
        "title": this.slcqQuadrant.yLabel,
        "titleTextStyle": { "fontSize": 17, "bold": true },
        "minValue": 1, "maxValue": 4, "baseline": 2.5,
        "gridlines": { "count": 0 }, "minorGridlines": { "count": 0 },
        "ticks": []
      },
      // "series": this.dataSeries, // this was for showing mutliple assessments      
      series: {
        'Mean': { color: 'DarkRed', visibleInLegend: 'true' },
        '': { color: 'PaleTurquoise', visibleInLegend: 'false' }
      },
      "sizeAxis": { "maxSize": bubbleMaxSize, "minSize": bubbleMinSize },
      // "tooltip": { "trigger": "showTooltips" }
      "tooltip": { "trigger": "none" }
    };
  }

  buildData() {

    let testData = '[ ["", ' + this.slcqQuadrant.meanX + ', ' + this.slcqQuadrant.meanY + ', "Mean", 1 ]';

    // bubble sizes here may screw up the size of the mean if the % is less than 1.
    // So need to adjust bubble sizes by adding 1 to make sure the smallest % bubble is greater than the mean.
    if (this.slcqQuadrant.quad1Percent > 0) {
      testData += ',["' + this.slcqQuadrant.quad1Percent.toFixed(0) + '%", 1.75, 3.25, "", ' + (this.slcqQuadrant.quad1Percent + 1) + ']';
    }
    if (this.slcqQuadrant.quad2Percent > 0) {
      testData += ',["' + this.slcqQuadrant.quad2Percent.toFixed(0) + '%", 3.25, 3.25, "", ' + (this.slcqQuadrant.quad2Percent + 1) + ']';
    }
    if (this.slcqQuadrant.quad3Percent > 0) {
      testData += ',["' + this.slcqQuadrant.quad3Percent.toFixed(0) + '%", 1.75, 1.75, "", ' + (this.slcqQuadrant.quad3Percent + 1) + ']';
    }
    if (this.slcqQuadrant.quad4Percent > 0) {
      testData += ',["' + this.slcqQuadrant.quad4Percent.toFixed(0) + '%", 3.25, 1.75, "", ' + (this.slcqQuadrant.quad4Percent + 1) + ']';
    }

    testData += ']';

    // console.log('testData: ' + testData);

    // this.slcqQuadrant[counter].data = [ ['Mean', xValue*1, yValue*1, 'Mean', 1] ];
    this.slcqQuadrant.data = JSON.parse(testData);
  }

  // userChangedOverlayXLeftTopSlider() {
  //   console.log('userChangedOverlayXLeftTopSlider value = ' + this.value_overlay_X_left_top);
  //   this.root.style.setProperty("--overlayXleft-top", this.value_overlay_X_left_top + "%");
  // }
  
  // userChangedOverlayXLeftTransformOrigin1Slider() {
  //   console.log('userChangedOverlayXLeftTransformOrigin1Slider value = ' + this.value_overlay_X_left_transform_origin_1);
  //   this.root.style.setProperty("--overlayXleft-transform-origin-1", this.value_overlay_X_left_transform_origin_1 + "%");
  // }
  
  // userChangedOverlayXLeftTransformOrigin2Slider() {
  //   console.log('userChangedOverlayXLeftTransformOrigin2Slider value = ' + this.value_overlay_X_left_transform_origin_2);
  //   this.root.style.setProperty("--overlayXleft-transform-origin-2", this.value_overlay_X_left_transform_origin_2 + "%");
  // }
}
