import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  email: string;
  errorMessage: string = "";
  message: string = "";

  constructor(private router: Router , private adminService: AdminService,) { }

  ngOnInit() {

  }

  submit() {
    this.adminService.validateEmailForgotPassword(this.email.toLowerCase()).subscribe((response) => {
      this.message = "";
      this.errorMessage = "";
      if(response.responseid == "1") {
        this.message = "A reset password link has been sent to your email address.";
      } else {
        this.errorMessage = "If a valid email address was entered, you will receive an email to reset your password.";
      }
    });
  }

}
