import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  constructor() { }

  // change branding based on hostname
  setBranding(document: Document, titleService: Title) {

    let branding: any = {};
    // branding.login = {};
    // branding.adminheader = {};
    // branding.assessment = {};

    // console.log(document.location);
    if (document.location.hostname.includes("vicere")) {

      // title
      titleService.setTitle("Vicere Associates, Inc. - Leadership and Organizational Development Consulting");

      // favicon
      let favIcon: HTMLLinkElement = document.querySelector('#appIcon');
      favIcon.href = '/assets/vicere/vicere-favicon.png';

      branding.alt = "Vicere Associates";

      branding.logo_color = "/assets/vicere/vicere-logo-color.png";
      branding.logo_gray = "/assets/vicere/vicere-cp-gray-logo.png";
      branding.logo_white = "/assets/vicere/vicere-logo-white.png";
      branding.logo_white_srcset = "/assets/vicere/vicere-logo-white.png 1x, /assets/vicere/vicere-logo-white@2x.png 2x";

    } else if (document.location.hostname.includes("ylh")) {

      // title
      titleService.setTitle("Your Branding Here");

      // favicon
      let favIcon: HTMLLinkElement = document.querySelector('#appIcon');
      favIcon.href = '/assets/ylh/ylh-favicon.png';

      branding.alt = "Your Logo Here";

      branding.logo_color = "/assets/ylh/your-logo-here-color.svg";
      branding.logo_gray = "/assets/ylh/your-logo-here-cp-gray.svg";
      branding.logo_white = "/assets/ylh/your-logo-here-white.svg";
      branding.logo_white_srcset = "/assets/ylh/your-logo-here-white.svg 1x, /assets/ylh/your-logo-here-white.svg 2x";

    } else {

      // title
      titleService.setTitle("Strategia Analytics");

      // favicon
      let favIcon: HTMLLinkElement = document.querySelector('#appIcon');
      favIcon.href = "/assets/favicon-strategia.png";

      branding.alt = "Strategia Analytics";

      branding.logo_color = "/assets/logo-color.svg";
      branding.logo_gray = "/assets/cp-gray-logo.svg";
      branding.logo_white = "/assets/logo-white.svg";
      branding.logo_white_srcset = "/assets/logo-white.svg 1x, /assets/logo-white.svg 2x";

    }

    return branding;
  }
}
