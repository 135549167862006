export class QuadTitle {
  line1 = '';
  line2 = '';
}

export class SlcqQuadrant {

  quadrantName: string;
  sectionName: string;

  quadTitle1 = new QuadTitle();
  quadTitle2 = new QuadTitle();
  quadTitle3 = new QuadTitle();
  quadTitle4 = new QuadTitle();

  xLabel = '';
  xLabelLeft = '';
  xLabelRight = '';

  yLabel = '';
  yLabelTop = '';
  yLabelBottom = '';

  columnNames: any;

  data: any;

  meanX: number;
  meanY: number;

  totalResponses: number;

  quad1Percent: number;
  quad2Percent: number;
  quad3Percent: number;
  quad4Percent: number;

  showTooltips = true;

}

export class SlcqData {

  componentName = '';
  sectionId: number;
  sectionName = '';
  value: number;

}
