export class Assessment {
  id: number;
  name: string;
  idcategory: number;
  category: string;
  client: Client;
  assessmentIntro: AssessmentIntro;
  assessmentExit: AssessmentExit;
  demographicQuestions: Array<DemographicQuestion>;
  howtoread: any;
  assessmentQuestions: Array<AssessmentQuestion>;
  url: string;
  startdate: string;
  enddate: string;
  idassessmentresponse: number;
  idassessmenttype: number;
  uuid: string;
}

export class Client {
  id: number;
  name: string;
  idclient: string;
}

export class DemographicAll {
  iddemographicquestion: number;
  enumerator: string;
  weight: number;
  type: string;
  crosstabcolname: string;
  iddemographicquestiontext: number;
  question: string;
  label: string;
  lang: string;
}

export class AssessmentIntro {
  title: string;
  text: string;
}

export class AssessmentExit {
  title: string;
  text: string;
}

export class AssessmentQuestion {
  groupAssessmentSectionTitle: string;
  idassessment: number;
  id: number;
  question: string;
  subtext: string;
  answers: Array<AssessmentQuestionAnswer>;
  idcategory: number;
  category: string;
}

export class DemographicQuestion {
  id: number;
  answer_id: number;
  answer_txt: string;
  weight: number;
  validationmessage?: any;
  lang: string;
  valueset: any;
  needtextbox: boolean;
}

export class AssessmentQuestionAnswer {
  id: number;
  answer: string;
  class: string;
  lang: string;
}

export class AssessmentResponse {

}

export class Adminassessment {

}

export class AssessmentDump {
  rows: Object[];
  // // future use
  // osaquestiondefinitions: Object[];
  // slcqquestiondefinitions: Object[];
  // demographicdefinitions: Object[];
}

export class AdminAssessment {
  id: number;
  name: string;
  client: string;
  url: string;
  submissions: object;

}

export class Adminindividualreport {
  id: number;
  name: string;

}

export class Adminheader {

}

export class User {
  responseid: number;
  clients: Object[];
  user: Object;
}

export class Assessmentlist {
  idclient: number;
  name: string;
}

export class Clientlist {

}


export class DemographicFilter {

}

export class DemographicFilterList {
  demographics: Array<DemographicFilter>;
}



export class Project {

  constructor(
    public id: number,
    public name: string,
    public idclient: string,
    public status: number
  ) {  }

}

export class AssessmentSubmit {

  constructor(
    public id: number,
    public name: string,
    public idassessmenttype: number,
    public idproject: number,
    public startdate: string,
    public enddate: string,
    public status: number,
    public estimatedresponses: string,
    public hash: string,
    public demographics: Object[]
  ) {  }

}


export class AssessmentType {
  id: number;
  name: string;
  description: string;
  introtitle: string;
  intotext: string;
  exittitle: string;
  exittext: string;
}

export class Response {
  responseid: string;
  responsemsg: string;
}

export class AssessmentLog{
  id?: number;
  uuid: string;
  logtype: string;
  browser: string;
  idassessment: number;
  os: string;
  platform: string;
  currentPage: number;
  questions: Object [];
  logdate: string;
}