import { Injectable } from '@angular/core';
import { get, last } from 'lodash';

@Injectable()
export class PermissionService {
  roles: string[] = ["admin", "super admin"];

  isAdmin(): boolean {
    // const clients = JSON.parse(localStorage.getItem('currentClients'));
    // let admin = false;
    // clients.forEach((client) => {
    //   if (client["idclient"] === idClient && this.roles.indexOf(client['rolename']) !== -1) {
    //     admin = true;
    //   }
    // });
    // return admin;
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const userEmail = get(user, 'email', '');
    const emailSplitted = userEmail.split('@');
    return last(emailSplitted) === 'strategiaanalytics.com';

  }

  canAddClient(): boolean {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return user["email"] === "admin@strategiaanalytics.com";
  }

  canAddUser(): boolean {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return user["email"] === "admin@strategiaanalytics.com";
  }
}
