import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AssessmentIntro, AssessmentQuestion, Assessment, AssessmentResponse } from './assessment';
import { MessageService } from './message.service';
import { environment } from '../environments/environment';
import { Report } from './report';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class AssessmentService {

  private getAssessmentUrl = environment.apiUrl + environment.portMain + "getAssessment";
  private submitAssessmentUrl = environment.apiUrl + environment.portMain + "submitAssessmentResponse";
  private cronAssessmentStatusUrl = environment.apiUrl + environment.portMain + "cronAssessmentStatus";
  private getPublicReportUrl = environment.apiUrl + environment.portMain + "getPublicReport";

  constructor(private http: HttpClient, private messageService: MessageService) { }

  getAssessment(hash: string): Observable<Assessment> {
    const url = `${this.getAssessmentUrl}/?hash=${hash}`;
    return this.http.get<Assessment>(url)
      .pipe();
  }

  cronAssessmentStatus(): Observable<Object> {
    return this.http.get<Object>(this.cronAssessmentStatusUrl)
      .pipe();
  }

  submitAssessment(assessment: Assessment): Observable<Assessment> {
    return this.http.post<Assessment>(this.submitAssessmentUrl, assessment, httpOptions)
      .pipe();
  }

  getPublicReport(idAssessmentResponse: number): Observable<Report> {
    const url = `${this.getPublicReportUrl}/?idAssessmentResponse=${idAssessmentResponse}`;
    return this.http.get<Report>(url)
      .pipe();
  }

}
