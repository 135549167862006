import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Report, GenericReport, SidebarElements } from "../report";
import { AdminService } from "../admin.service";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import {
  isEmpty,
  find,
  get,
  head,
  uniqBy,
  indexOf,
  sortBy,
  reject,
  differenceBy,
  isUndefined,
  map,
  remove,
} from "lodash";
import { Client } from "../assessment";
import lang from "./lang";
import { Title } from "@angular/platform-browser";
import { BrandingService } from "../branding.service";
import { downloadFileFromJSON } from "../../utils/dataToFile";
import { __core_private_testing_placeholder__ } from "@angular/core/testing";
declare var require: any;

@Component({
  selector: "app-reporting-tool",
  templateUrl: "./reporting-tool.component.html",
  styleUrls: ["./reporting-tool.component.css"],
})
export class ReportingToolComponent implements OnInit {
  @ViewChild("reportContent", { static: false }) reportContent!: ElementRef;
  currentUser;
  report: GenericReport;
  sidebarelements: SidebarElements;
  idQueryParam;
  currentdemographics = [];
  idassessmentselectedone = 0;
  idassessmentselectedtwo = 0;
  demgroupselected = "";
  selecteddimension = "assessment";
  watermarkMode = false;
  idcolour = 0;
  colours = [
    "#42d4f4",
    "#bfef45",
    "#ffe119",
    "#f58231",
    "#e6194B",
    "#911eb4",
    "#4363d8",
    "#808000",
    "#9A6324",
    "#800000",
    "#f032e6",
    "#9ecaf2",
    "#aaffc3",
    "#fffac8",
    "#ffd8b1",
    "#fb98a7",
    "#e6beff",
    "#b7a4f4",
    "#cf20a5",
    "#a9a9a9",
  ];
  coloursassigned = [];
  oldcolours = [];
  currentlegends = [];
  namesperresponse = [];
  autodecollapse = true;
  individualIsLoaded = false;
  loading = true;
  demographicOptions = [];
  optionSelected = "";
  sums = [];
  filterselected = {
    name: "",
    idfilter: "",
    demographics: {
      id: "",
      options: [],
    },
    scales: [],
  };
  message = "";
  filterondelete = "";
  lang = "en";
  // Scales
  scales: any = [];
  currentScales = [];
  secondaryScales: any = [];
  currentSecondaryScales: any = [];
  langDictionary = lang;

  // Filters
  clientlist: Client[] = [];

  // Todo: Figure out what variable is needed
  newFilterName = "";
  newFilterClients = [];
  selectedFilter = 0;
  selectedFilterName = "";

  // Todo: Get this list from the endpoint once load and save are working
  // Call this instead
  // myFilters = this.getFilters();
  myFilters = [];

  branding = {};
  downloadButtonDisabled = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private location: Location,
    private titleService: Title,
    private brandingService: BrandingService
  ) {
    this.branding = brandingService.setBranding(document, titleService);
  }

  getClients(): void {
    this.adminService.getAllClients().subscribe((clientlist) => {
      this.clientlist = clientlist["users"];
    });
  }

  assignSidebarElements(sidebarelements, isinitial): void {
    if (this.sidebarelements == null) {
      this.sidebarelements = sidebarelements;
    } else {
      this.sidebarelements.sidebarDimensions =
        sidebarelements.sidebarDimensions.filter(
          (dimension) => dimension.lang == "en"
        );
    }
    this.sidebarelements.sidebarDimensions = uniqBy(
      this.sidebarelements.sidebarDimensions,
      "text"
    );
    if (
      this.idassessmentselectedone > 0 &&
      this.idassessmentselectedtwo > 0 &&
      isinitial
    ) {
      var selectedoptions = [];
      var selecteddemographics = [];
      selectedoptions.push(this.idassessmentselectedone);
      selectedoptions.push(this.idassessmentselectedtwo);
      this.runSelectAssessment(selectedoptions, selecteddemographics);
    }

    if (
      this.idassessmentselectedone > 0 &&
      this.demgroupselected !== "" &&
      isinitial
    ) {
      this.selecteddimension = "demographic";
      var demographicbox = this.document.getElementById("demographicsbox");
      if (demographicbox) {
        demographicbox.style.display = "block";
      }
      var selectedoptions = [];
      var selecteddemographics = [];
      selectedoptions.push(this.idassessmentselectedone);
      selecteddemographics.push(this.demgroupselected);
      this.runSelectAssessment(selectedoptions, selecteddemographics);
    }
  }

  runSelectAssessment(selectedoptions, selecteddemographics): void {
    for (let filter of this.sidebarelements.sidebarFilters) {
      if (filter.field == "idassessment") {
        var filters = [];
        var demographics = [];
        filters.push({ idassessment: selectedoptions });
        if (selecteddemographics.length > 0) {
          demographics.push({ demographicgroup: selecteddemographics[0] });
        }
        this.getGenericReport(filters, demographics);
        this.currentScales = filters;
        this.currentdemographics = demographics;
        this.getSidebarElements(filters, false);
      }
    }
  }

  getColourPerLegend(item): string {
    let total = item.count;
    var itemname =
      this.selecteddimension + "-" + item.legend.split("</span>").pop();
    if (this.selecteddimension === "demographic") {
      total = get(find(this.sums, ["name", item.legend]), "count", item.count);
    }
    if (!this.coloursassigned[item.legend]) {
      if (!find(this.currentlegends, ["name", item.legend])) {
        if (itemname in this.oldcolours) {
          this.currentlegends.push({
            classcolor: this.oldcolours[itemname],
            name: item.legend,
            numberOfRespondents: total,
          });
        } else {
          this.coloursassigned[item.legend] = this.colours[this.idcolour];
          this.currentlegends.push({
            classcolor: this.coloursassigned[item.legend],
            name: item.legend,
            numberOfRespondents: total,
          });
          this.idcolour = this.idcolour + 1;
          this.oldcolours[itemname] = this.coloursassigned[item.legend];
        }
      }
      return find(this.currentlegends, ["name", item.legend]).classcolor;
    } else {
      return this.coloursassigned[item.legend];
    }
  }

  totalSum(arrayToSum): void {
    this.sums = [];
    for (let option of arrayToSum.labels) {
      for (let result of option.results) {
        if (!find(this.sums, ["name", result.legend])) {
          this.sums.push({
            name: result.legend,
            count: result.count,
          });
        } else {
          let currentLegendTotal = get(
            find(this.sums, ["name", result.legend]),
            "count",
            0
          );
          this.sums[
            indexOf(this.sums, find(this.sums, ["name", result.legend]))
          ] = {
            name: result.legend,
            count: Number(currentLegendTotal) + Number(result.count),
          };
        }
      }
    }
  }

  assignReport(report): void {
    //this.namesperresponse = [];
    this.report = report;
    console.log("report", this.report);

    this.generateFilters();
    for (let row of report.rows) {
      this.totalSum(head(row.sections));
      for (let section of row.sections) {
        for (var i = 0; i < section.labels.length; i++) {
          let result = section.labels[i];
          if (this.demographicOptions.length > 0) {
            let optionState = find(this.demographicOptions, [
              "name",
              result.name,
            ]);
            result.show = get(optionState, "show", true);
            result.even = get(optionState, "even", !(i % 2 == 0));
            if (
              !find(this.demographicOptions, ["name", result.name]) &&
              this.optionSelected != "dimension"
            ) {
              this.demographicOptions.push({
                name: result.name,
                show: result.show,
                even: result.even,
              });
            }
          } else {
            result.show = true;
            result.even = !(i % 2 == 0);
            if (!find(this.demographicOptions, ["name", result.name])) {
              this.demographicOptions.push({
                name: result.name,
                show: true,
                even: result.even,
              });
            }
          }

          for (let itemresult of result.results) {
            for (let name of this.namesperresponse) {
              if (itemresult.legend == name.idassessmentresponse) {
                itemresult.legend =
                  '<span tag="' +
                  name.idassessmentresponse +
                  '"></span>' +
                  name.name +
                  " " +
                  name.lastname;
              }
            }
            itemresult.classcolor = this.getColourPerLegend(itemresult);
          }
        }
        section.labels = sortBy(section.labels, ["show", "name"]);
        for (let i = section.labels.length - 1; i >= 0; i--) {
          section.labels[i].even = !(i % 2 == 0);
        }
        if (
          differenceBy(this.demographicOptions, section.labels, "name").length >
            0 &&
          isEmpty(get(this.filterselected, "demographics.id", ""))
        ) {
          this.demographicOptions = reject(
            section.labels,
            (option) => !find(this.demographicOptions, ["name", option.name])
          );
        }
      }
    }
    this.loading = false;
    this.demographicOptions = sortBy(this.demographicOptions, ["weight"]);
  }

  attachReport(report): void {
    if (isEmpty(this.report)) {
      this.assignReport(report);
    } else {
      for (let row of report.rows) {
        for (let section of row.sections) {
          for (let result of section.labels) {
            for (let main_row of this.report.rows) {
              for (let main_section of main_row.sections) {
                if (main_section.id == section.id) {
                  for (let name of this.namesperresponse) {
                    if (result.results[0].legend == name.idassessmentresponse) {
                      result.results[0].legend =
                        '<span tag="' +
                        name.idassessmentresponse +
                        '"></span>' +
                        name.name +
                        " " +
                        name.lastname;
                    }
                  }
                  result.results[0].classcolor = this.getColourPerLegend(
                    result.results[0]
                  );
                  if (
                    !find(
                      main_section.labels[0].results,
                      (temp_result) =>
                        temp_result.legend == result.results[0].legend
                    )
                  ) {
                    if (this.selecteddimension == "demographic") {
                      if (report.individual && this.demgroupselected) {
                        if (
                          !find(main_section.labels, ["name", " Respondents"])
                        ) {
                          main_section.labels.unshift({
                            name: " Respondents",
                            results: [],
                            show: true,
                            even: false,
                          });
                        }
                        find(main_section.labels, [
                          "name",
                          " Respondents",
                        ]).results.push(result.results[0]);
                      } else {
                        find(main_section.labels, [
                          "name",
                          result.name,
                        ]).results.push(result.results[0]);
                      }
                    } else {
                      main_section.labels[0].results.push(result.results[0]);
                    }
                  }
                }
                main_section.labels = sortBy(main_section.labels, [
                  "show",
                  "name",
                ]);
                for (let i = main_section.labels.length - 1; i >= 0; i--) {
                  main_section.labels[i].even = !(i % 2 == 0);
                }
              }
            }
          }
        }
      }
      // this.loading = false;
    }
  }

  generateFilters() {
    this.scales = [];
    if (get(this.filterselected, "idfilter", "")) {
      // this.report.rows = this.filterselected.scales;
      for (const scale of this.filterselected.scales) {
        let currentScale = find(this.report.rows, [
          "component",
          scale.component,
        ]);
        if (!isEmpty(currentScale)) {
          currentScale.selected = scale.selected;
          for (const section of currentScale.sections) {
            let currentSection = find(scale.sections, ["text", section.text]);
            section.selected = get(currentSection, "selected", true);
          }
        }
      }
    } else {
      this.report.rows.forEach((row) => {
        row.selected = get(row, "selected", true);
        row.sections.forEach((section) => {
          section.selected = get(section, "selected", true);
        });
      });
    }
  }

  getSidebarElements(filters, isinitial) {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    filters.push({ idpeople: currentUser.idpeople });
    this.adminService
      .getSidebarElements(filters)
      .subscribe((sidebarelements) => {
        let sidebarbody = JSON.parse(get(sidebarelements, "body", {}));

        //////////////////////////////////////////////
        // filter out SLCQ assessments
        sidebarbody.sidebarFilters = sidebarbody.sidebarFilters.filter(
          (sidebarFilters) => {
            const options = sidebarFilters.options.filter((options) => {
              const projects = options.projects.filter((projects) => {
                const assessments = projects.assessments;
                if (assessments.length > 0) {
                  projects.assessments = assessments;
                  return true;
                } else {
                  return false;
                }
              });
              if (projects.length > 0) {
                options.projects = projects;
                return true;
              } else {
                return false;
              }
            });
            if (options.length > 0) {
              sidebarFilters.options = options;
              return true;
            } else {
              return false;
            }
          }
        );

        if (sidebarbody.sidebarFilters.length > 0) {
          for (let clientitem of sidebarbody.sidebarFilters[0].options) {
            for (let projectitem of clientitem.projects) {
              for (let assessmentitem of projectitem.assessments) {
                // if(assessmentitem.idassessmenttype != 7){
                this.adminService
                  .getNamesPerResponse(assessmentitem.idassessment)
                  .subscribe((namesperresponse) => {
                    assessmentitem.names = namesperresponse;
                    for (let name of namesperresponse) {
                      this.namesperresponse.push(name);
                    }
                  });
                // }
              }
            }
          }
        }
        this.loading = false;
        this.assignSidebarElements(sidebarbody, isinitial);
        this.getIndividualReport();
      });
  }
  //downloadFileFromJSON
  downloadPdf() {
    this.downloadButtonDisabled = true;
    // let filters = this.currentScales;
    // let dimensions = this.currentdemographics;

    // this.adminService
    //   .getGenericReportHtml(filters, dimensions)
    //   .subscribe((report: any) => {
    //     this.downloadButtonDisabled = false;
    //     downloadFileFromJSON(report.data, "report", "pdf");
    //   });

    window.onafterprint = () => {
      //this.detectScreenSize();
      this.downloadButtonDisabled = false;
      // this.document.body.style.zoom = "100%";
    };

    //this.bubbleChartWidth = 780;

    setTimeout(() => {
      window.print();
    }, 2000);
    //after print, call detectScreenSize to turn the charts back into the normal size
    //window.print();
  }

  getGenericReport(filters, dimensions): void {
    // this.demographicOptions = [];
    let currentsecondarydimensions = dimensions;
    this.loading = true;

    console.log("done waiting for report");
    this.adminService
      .getGenericReport(filters, dimensions)
      .subscribe((report) => {
        if (isEmpty(report.rows) && !isUndefined(report)) {
          this.loading = false;

          this.document.getElementById("alert-info").classList.add("show");
          setTimeout(() => {
            this.document.getElementById("alert-info").classList.remove("show");
          }, 5000);
        } else {
          this.assignReport(report);

          if (this.currentSecondaryScales.length > 0) {
            for (let item_idassessmentresponse of this
              .currentSecondaryScales[0]["idassessmentresponse"]) {
              var secondaryScales = [];
              var selectedoptions_idassessmentresponse = [];
              selectedoptions_idassessmentresponse.push(
                item_idassessmentresponse
              );
              secondaryScales.push({
                idassessmentresponse: selectedoptions_idassessmentresponse,
              });
              if (this.selecteddimension != "demographic") {
                currentsecondarydimensions = [];
              }
              this.attachGenericReport(
                secondaryScales,
                currentsecondarydimensions
              );
            }
          } else {
            report.individual = false;
          }
        }
      });
  }

  // call API for specific ID response
  attachGenericReport(filters, dimensions): void {
    this.adminService
      .getGenericReport(filters, dimensions)
      .subscribe((report) => {
        this.loading = false;
        report.individual = true;
        this.attachReport(report);
      });
  }

  getInitialLoad(): void {
    this.loading = true;
    this.route.queryParams.subscribe((params) => {
      if (
        typeof params.idassessment !== "undefined" &&
        typeof params.demographicgroup !== "undefined"
      ) {
        this.idassessmentselectedone = +params.idassessment;
        this.demgroupselected = params.demographicgroup;
        // this.selectDimension('demographic', false);
      }
      if (
        typeof params.idassessmentone !== "undefined" &&
        typeof params.idassessmenttwo !== "undefined"
      ) {
        this.idassessmentselectedone = +params.idassessmentone;
        this.idassessmentselectedtwo = +params.idassessmenttwo;
      }
    });
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.getInitialLoad();
    this.getSidebarElements([], true);
    this.getClients();
    this.getFilters();
  }

  ngAfterViewChecked() {
    const id = this.route.snapshot.paramMap.get("id");
    this.idQueryParam = id;
    const response = this.document.getElementById(id);

    if (!isEmpty(response) && this.autodecollapse) {
      const parent = response.closest(".collapse");
      const grandpa = parent.closest(".collapse.projects-container");
      const grandgrandpa = grandpa.closest(".collapse.section");
      parent.classList.add("show");
      grandpa.classList.add("show");
      grandgrandpa.classList.add("show");
      this.autodecollapse = false;
    }
  }

  getIndividualReport(): void {
    if (!this.individualIsLoaded) {
      const id = this.route.snapshot.paramMap.get("id");
      this.idQueryParam = id;
      if (id != null) {
        this.currentScales.push({ idassessmentresponse: [this.idQueryParam] });
        this.attachGenericReport([{ idassessmentresponse: [id] }], []);
        this.individualIsLoaded = true;
      }
    }
  }

  selectElement($event, filterelement): void {
    this.loading = true;
    this.selectElementName(
      $event.target.name,
      filterelement,
      $event.target.checked
    );
  }

  toggleDemographicOption(demographicOption): void {
    let rows = get(this.report, "rows", []);
    for (let row of rows) {
      for (let section of row.sections) {
        let modifiedIndex;
        find(section.labels, (label) => {
          if (label.name === demographicOption.name) {
            modifiedIndex = indexOf(section.labels, label);
            label.show = !label.show;
            let option = find(this.demographicOptions, [
              "name",
              demographicOption.name,
            ]);
            option.show = label.show;
            option.even = label.even;
          }
        });
        section.labels = sortBy(section.labels, ["show", "name"]);
        for (let i = section.labels.length - 1; i >= 0; i--) {
          section.labels[i].even = !(i % 2 === 0);
        }
      }
    }
  }

  clearAllSelections(): void {
    this.selecteddimension = "assessment";
    this.optionSelected = "";
    this.currentScales = [];
    this.currentSecondaryScales = [];
    this.currentdemographics = [];
    this.individualIsLoaded = false;
    this.demographicOptions = [];
    this.autodecollapse = true;
    this.report = null;
    this.idcolour = 0;
    this.coloursassigned = [];
    this.oldcolours = [];
    this.currentlegends = [];
    this.getSidebarElements([], false);

    var options = this.document.getElementsByName("elementCheckbox-1");
    for (let option of <any>options) {
      if (option.getAttribute("secondTag") == "clearable") {
        option.checked = false;
      }
    }
    this.selectedFilter = 0;
    this.filterselected = {
      name: "",
      idfilter: "",
      demographics: {
        id: "",
        options: [],
      },
      scales: [],
    };
    this.newFilterClients = [];
    this.newFilterName = "";
    this.selectedFilterName = "";
  }

  selectElementName(name, filterelement, checked): void {
    var options = this.document.getElementsByName(name);
    var filters = [];
    var secondaryScales = [];
    var selectedoptions_idassessment = [];
    var selectedoptions_idassessmentresponse = [];
    var dimensions = this.currentdemographics;

    if (!checked) {
      if (
        filterelement.idassessmentresponse &&
        filterelement.name &&
        filterelement.lastname
      ) {
        var nameElement =
          '<span tag="' +
          filterelement.idassessmentresponse +
          '"></span>' +
          filterelement.name +
          " " +
          filterelement.lastname;
        remove(this.currentlegends, ["name", nameElement]);
        delete this.coloursassigned[nameElement];
      }
      delete this.coloursassigned[filterelement.assessmentname];
      remove(this.currentlegends, ["name", filterelement.assessmentname]);

      if (
        this.currentlegends.length == 0 ||
        this.idcolour == this.colours.length - 1
      ) {
        this.idcolour = 0;
      }
    }

    for (let option of <any>options) {
      if (option.checked) {
        if (option.getAttribute("tag") == "idassessment") {
          selectedoptions_idassessment.push(option.id);
        }
        if (option.getAttribute("tag") == "idassessmentresponse") {
          selectedoptions_idassessmentresponse.push(option.id);
        }
      }
    }
    if (selectedoptions_idassessment.length > 0) {
      filters.push({ idassessment: selectedoptions_idassessment });
    }
    if (selectedoptions_idassessmentresponse.length > 0) {
      secondaryScales.push({
        idassessmentresponse: selectedoptions_idassessmentresponse,
      });
    }

    this.currentSecondaryScales = secondaryScales;
    this.currentScales = filters;
    let currentsecondarydimensions = dimensions;
    if (
      (!(this.selecteddimension === "demographic") &&
        this.currentdemographics.length == 0) ||
      this.selecteddimension === "assessment"
    ) {
      dimensions = [];
    }

    if (filters.length > 0) {
      this.getGenericReport(filters, dimensions);
    } else {
      if (secondaryScales.length > 0) {
        this.report = null;
        for (let item_idassessmentresponse of this.currentSecondaryScales[0][
          "idassessmentresponse"
        ]) {
          var secondaryScales = [];
          var selectedoptions_idassessmentresponse = [];
          selectedoptions_idassessmentresponse.push(item_idassessmentresponse);
          secondaryScales.push({
            idassessmentresponse: selectedoptions_idassessmentresponse,
          });
          if (this.selecteddimension !== "demographic") {
            currentsecondarydimensions = [];
          }
          this.attachGenericReport(secondaryScales, currentsecondarydimensions);
        }
      } else {
        this.report = null;
        this.currentdemographics = [];
        this.demographicOptions = [];
      }
    }

    this.getSidebarElements(filters, false);
  }

  selectDemographic(dimensionId): void {
    var options = this.document.getElementsByName("demographic");
    var selectedoptions = [];
    for (let option of <any>options) {
      if (option.checked) {
        selectedoptions.push(option.id);
      }
    }
    var demographics = [];

    if (selectedoptions.length > 0) {
      demographics.push({ demographicgroup: selectedoptions[0] });
      this.currentdemographics = demographics;
      this.getGenericReport(this.currentScales, demographics);
    } else {
      this.report = null;
    }
    this.demgroupselected = dimensionId;
    this.optionSelected = "demographic";
    this.demographicOptions = [];
  }

  selectDimension(dimension, fromClear): void {
    var demographicbox = this.document.getElementById("demographicsbox");
    if (dimension === "demographic") {
      this.selecteddimension = "demographic";
      
      this.getGenericReport(this.currentScales, this.currentdemographics);
      if (demographicbox) {
        demographicbox.style.display = "block";
      }
    } else {
      if (demographicbox) {
        demographicbox.style.display = "none";
      }
    }

    if (dimension === "assessment") {
      this.selecteddimension = "assessment";
      this.demgroupselected = "";
      this.currentdemographics = [];
      this.getGenericReport(this.currentScales, []);
    }
    this.optionSelected = "dimension";
    for (let key in this.oldcolours) {
      var newKey = dimension + "-" + key.split("-").pop();
      let value = this.oldcolours[key];
      delete this.oldcolours[key];
      this.oldcolours[newKey] = value;
    }
  }

  componentChanged(component) {
    component.selected = !component.selected;

    component.sections.forEach((section) => {
      section.selected = component.selected;
    });
  }

  download() {
    let legends = [];
    this.report["legends"].forEach((l) => {
      legends.push(l.name);
    });
    let filename = legends.join("-");
    const html2pdf = require("html2pdf.js");
    const opt = {
      filename: filename + ".pdf",
      margin: 10,
      jsPDF: { orientation: "landscape" },
    };

    this.watermarkMode = true;
    setTimeout(() => {
      html2pdf()
        .from(this.reportContent.nativeElement)
        .set(opt)
        .save()
        .then(() => {
          this.watermarkMode = false;
        });
    }, 100);
  }

  getFilters(filtername = "") {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.adminService
      .getFiltersByUser(currentUser.idpeople)
      .subscribe((filtersList) => {
        this.myFilters = map(filtersList, (el) => {
          var o = Object.assign({}, el);
          o.selected = false;
          return o;
        });
        this.loading = false;
        if (filtername) {
          this.loadFilter(filtername);
        }
      });
  }

  ableConfirmDeleteFooter(idfilter) {
    this.filterondelete = idfilter;
    this.document
      .getElementById("filter-delete-confirmation")
      .classList.add("show");
  }

  deleteFilter() {
    this.loading = true;
    this.adminService
      .deleteFilterById(this.filterondelete)
      .subscribe((response) => {
        if (response.responseid == "1") {
          this.document
            .getElementById("alert-info-success")
            .classList.add("show");
          this.getFilters();
          if (this.filterondelete == get(this.filterselected, "idfilter", "")) {
            this.clearFilter();
          }
        } else {
        }
        this.document
          .getElementById("filter-delete-confirmation")
          .classList.remove("show");
      });
  }

  clearFilter() {
    this.selectedFilter = 0;
    this.filterselected = {
      name: "",
      idfilter: "",
      demographics: {
        id: "",
        options: [],
      },
      scales: [],
    };
    this.newFilterClients = [];
    this.newFilterName = "";
    this.selectedFilterName = "";
    // Default back to assessment
    this.report.rows.forEach((row) => {
      row.selected = true;
      row.sections.forEach((section) => {
        section.selected = true;
      });
    });

    // Todo: Populate the filters (dimension and scales) with default values.
  }

  filterEdit() {
    // Todo: build a filter object and send it to the backend.
    const filter = {
      idfilter: this.filterselected.idfilter,
      clients: this.newFilterClients,
      configuration: {
        demographics: {
          id: this.demgroupselected,
          options: this.demographicOptions,
        },
        scales: this.report.rows,
      },
    };

    this.adminService.filterEdit(filter).subscribe((response) => {
      // Todo: handle the response: display message on screen
      // console.log(response);
      if (response.responseid == "1") {
        this.getFilters(this.filterselected.name);
        this.message = response.responsetxt;
        this.document
          .getElementById("alert-info-success")
          .classList.add("show");
        setTimeout(() => {
          this.document
            .getElementById("alert-info-success")
            .classList.remove("show");
        }, 3000);
      } else {
        this.getFilters();
        this.message = "something went wrong.";
        this.document.getElementById("alert-info-danger").classList.add("show");
        setTimeout(() => {
          this.document
            .getElementById("alert-info-danger")
            .classList.remove("show");
        }, 3000);
      }
    });
  }

  saveFilter() {
    // Todo: build a filter object and send it to the backend.
    const filter = {
      filtername: this.newFilterName,
      clients: this.newFilterClients,
      configuration: {
        demographics: {
          id: this.demgroupselected,
          options: this.demographicOptions,
        },
        scales: this.report.rows,
      },
    };

    this.adminService.createFilter(filter).subscribe((response) => {
      // Todo: handle the response: display message on screen
      if (response.responseid == "1") {
        this.getFilters(filter.filtername);
        this.message = response.responsetxt;
        this.document
          .getElementById("alert-info-success")
          .classList.add("show");
        setTimeout(() => {
          this.document
            .getElementById("alert-info-success")
            .classList.remove("show");
        }, 3000);
      } else {
        this.getFilters();
        this.message = "something went wrong.";
        this.document.getElementById("alert-info-danger").classList.add("show");
        setTimeout(() => {
          this.document
            .getElementById("alert-info-danger")
            .classList.remove("show");
        }, 3000);
      }
    });
  }

  loadFilter(filterName) {
    this.filterselected = this.myFilters.find(
      (filter) => filter.name === filterName
    );
    if (
      this.filterselected &&
      this.filterselected.demographics.options.length !== 0
    ) {
      // Show dimensions options (assessment or demographic)
      // this.selectDimension('demographic');

      // this.demgroupselected = this.filterselected.demographics.id;
      // this.demographicOptions = this.filterselected.demographics.options;
      for (const scale of this.filterselected.scales) {
        let currentScale = find(this.report.rows, [
          "component",
          scale.component,
        ]);
        if (!isEmpty(currentScale)) {
          currentScale.selected = scale.selected;
          for (const section of currentScale.sections) {
            let currentSection = find(scale.sections, ["text", section.text]);
            section.selected = get(currentSection, "selected", true);
          }
        }
      }

      const demographics = [];
      if (this.demgroupselected) {
        demographics.push({ demographicgroup: this.demgroupselected });
      }
      this.currentdemographics = demographics;
      // this.getGenericReport(this.currentScales, demographics);
      let currentfilter = find(this.myFilters, ["name", filterName]);
      currentfilter.selected = true;
      this.newFilterClients = currentfilter.clients;
      this.selectedFilterName = filterName;
    } else {
      this.clearFilter();
    }
  }
}
