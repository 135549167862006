import { Component, OnInit, Inject } from '@angular/core';
import {PermissionService} from '../permission.service';
import { AdminService } from '../admin.service';
import { get } from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { BrandingService } from '../branding.service';

@Component({
  selector: 'app-adminheader',
  templateUrl: './adminheader.component.html',
  styleUrls: ['./adminheader.component.css']
})
export class AdminheaderComponent implements OnInit {
  isadmin: boolean;
  currentUser;
  isimpersonating: boolean;
  users_to_impersonate;

  branding = {};
  
  constructor(
    private permissionService: PermissionService,
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private brandingService: BrandingService) {

    this.branding = brandingService.setBranding(document, titleService);
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.isadmin = get(this.currentUser, 'isadmin', false);


    this.getUsers(this.currentUser.idpeople);
    this.isimpersonating = JSON.parse(localStorage.getItem('isimpersonating'));
  }

  logout() {
    localStorage.removeItem('currentUser');
  }

  getUsers(idpeople): void {
    this.adminService.getUsers(idpeople).subscribe((usersList) => {
      var allUsers = usersList['users'];

      // sort the list of users that can be impesated
      this.users_to_impersonate = this.sortUsers(allUsers);

      // remove the current user from the list of users that can be impersonated
      // look for idpeople
      for (var i = 0; i < this.users_to_impersonate.length; i++) {
        if (this.users_to_impersonate[i].idpeople === this.currentUser.idpeople) {
          // console.log("user found");
          // If you don't check the return of indexOf() for -1, this will remove the last item from array when msg wasn't found!
          this.users_to_impersonate.splice(i, 1);
          break;
        }
      }

      // console.log("this.users_to_impersonate: " + JSON.stringify(this.users_to_impersonate));
    });
  }

  impersonateUser(idpeople_arg) {
    // console.log("you clicked on " + idpeople_arg);

    // store the real current user in a temp localStorage var
    var current_user = localStorage.getItem('currentUser');
    var current_clients = localStorage.getItem('currentClients');
    localStorage.setItem('pre_impersonation_currentUser', current_user);
    localStorage.setItem('pre_impersonation_currentClients', current_clients);

    // console.log("pre_impersonation_currentUser: " + JSON.stringify(localStorage.getItem('pre_impersonation_currentUser')));
    // console.log("pre_impersonation_currentClients: " + JSON.stringify(localStorage.getItem('pre_impersonation_currentClients')));

    // find the user that was clicked on
    var user;
    for (var user_in_loop of this.users_to_impersonate) {
      // console.log("user for loop: " + JSON.stringify(user_in_loop));
      if (user_in_loop.idpeople == idpeople_arg) {
        // console.log("FOUND USER # " + user_in_loop.idpeople);
        user = user_in_loop;
        break;
      }
    }
    // console.log("got user " + user.idpeople);

    // temporarily set user as admin
    // user.isadmin = true;
    // docs
    // need the user's client List
    var clients = user.clients;
    // console.log("user client list " + JSON.stringify(clients));

    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('currentClients', JSON.stringify(clients));

    // this changes the icon
    this.isimpersonating = true;

    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('currentClients', JSON.stringify(clients));


    if (localStorage.getItem('isimpersonating') === null) {
      this.isimpersonating = true;
      localStorage.setItem('isimpersonating', JSON.stringify(true));
    } else {
      this.isimpersonating = JSON.parse(localStorage.getItem('isimpersonating'));
      if (this.isimpersonating === true) {
        this.isimpersonating = false;
        localStorage.setItem('isimpersonating', JSON.stringify(this.isimpersonating));
      } else {
        this.isimpersonating = true;
        localStorage.setItem('isimpersonating', JSON.stringify(this.isimpersonating));
      }
    }
    // console.log("isimpersonating = " + this.isimpersonating);

    if (this.router.url === "/") {
      location.reload();
    } else {
      this.router.navigate(["/"]);
    }

  }

  stopImpersonation() {
    this.isimpersonating = false;
    localStorage.setItem('isimpersonating', JSON.stringify(this.isimpersonating));

    // put the current User & client back to what they were before impoersonation
    var pre_impersonation_currentUser = localStorage.getItem('pre_impersonation_currentUser');
    var pre_impersonation_currentClients = localStorage.getItem('pre_impersonation_currentClients');
    localStorage.setItem('currentUser', pre_impersonation_currentUser);
    localStorage.setItem('currentClients', pre_impersonation_currentClients);

    if (this.router.url === "/") {
      location.reload();
    } else {
      this.router.navigate(["/"]);
    }
  }

  sortUsers(users) {
    return users.sort((n1, n2) => {
      if (n1.lastname.toUpperCase() > n2.lastname.toUpperCase()) {
        return 1;
      }

      if (n1.lastname.toUpperCase() < n2.lastname.toUpperCase()) {
        return -1;
      }

      if (n1.lastname.toUpperCase() === n2.lastname.toUpperCase()) {
        if (n1.name > n2.name) {
          return 1;
        }

        if (n1.name.toUpperCase() < n2.name.toUpperCase()) {
          return -1;
        }
      }
      return 0;
    });
  }
}
