



import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AssessmentIntro, AssessmentQuestion, Assessment, AssessmentLog } from './assessment';
import { MessageService } from './message.service';
import { environment } from '../environments/environment';
import { Report } from './report';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class AssessmentLogService {

  private getAssessmentLogUrl = environment.apiUrl + environment.portMain + "getAllAssessmentLogs";
  private submitAssessmentLogUrl = environment.apiUrl + environment.portMain + "submitAssessmentLog";

  constructor(private http: HttpClient, private messageService: MessageService) { }

  getAllAssessmentLogs(idassessment: string): Observable<AssessmentLog> {
    const url = `${this.getAssessmentLogUrl}/?id=${idassessment}`;
  
    return this.http.get<AssessmentLog>(url)
      .pipe();
  }
  
  submitAssessmentLog(assessmentlog: AssessmentLog): Observable<AssessmentLog> {
    return this.http.post<AssessmentLog>(this.submitAssessmentLogUrl, assessmentlog, httpOptions)
      .pipe();
  }

}






 
