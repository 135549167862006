import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
//import { AssessmentLogService } from '../assessmentlog.service';
import { PermissionService } from '../permission.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ViewChild, ElementRef } from '@angular/core';
import { AssessmentIntro, AssessmentQuestion, Assessment, AssessmentResponse, Assessmentlist, DemographicFilterList, DemographicFilter, AssessmentLog } from '../assessment';
import { saveAs } from 'file-saver';
import { Report } from '../report';
import { sortBy, map, find, isEmpty, get, remove } from 'lodash';
import { SlcqQuadrant, SlcqData } from '../bubble-chart/bubble-chart';

@Component({
  selector: 'app-adminassessment',
  templateUrl: './adminassessment.component.html',
  styleUrls: ['./adminassessment.component.css']
})

export class AdminassessmentComponent implements OnInit {
  @ViewChild('closeModal', {static: false}) closeModal !: ElementRef;
  currentUser;
  assessment: Assessment;
  responses: AssessmentResponse[];
  client: Assessmentlist;
  isDownloadable;
  report: Report;
  mySavedAssessment : Assessment;
  demographicfilterlist : DemographicFilter[];
  copyFeedback: string = "";
  limitdump: number;
  offsetdump: number;
  assessmentdumpall = [];
  formattedStartDate: string = "";
  formattedEndDate: string = "";
  messageclass;
  message;
  step = 1;
  availableDemographics = [];
  isAdmin = false;
  backHidden = false;
  actualrespondent;
  mySpecificRespondent = false;
  lang = 'en';
  quadrantsLoading = true;
  slcqQuadrant: SlcqQuadrant[] = [];
  slcqData: SlcqData[] = [];
  bubbleChartWidth: number = 700;
  logs: any [] = [];

  constructor( private router: Router,
               private route: ActivatedRoute,
               private adminService: AdminService,
               private permissionService: PermissionService,
            //   private assessmentLogService: AssessmentLogService,
               private location: Location) { }

  nextStep(step): void {
    this.step = step;
  }

  assignAssessment(assessment): void {
    this.assessment = assessment;
    this.assessment.url = document.location.origin + this.assessment.url;
    //this.initialLoadComponent();
  }

  /*
  async getAssessmentLogs(){
    const id = this.route.snapshot.paramMap.get('id');
    console.log(id);
    const logs: any = await this.assessmentLogService.getAllAssessmentLogs(id).toPromise();
    this.logs = logs.data;
    console.log("data fetched",logs);
  }*/
  async getAssessment() {
    const id = this.route.snapshot.paramMap.get('id');
    const assessment = await this.adminService.getAdminAssessment(id).toPromise();
    // console.log("in getAssessment() assessment:");
    // console.log(assessment);

    this.assignAssessment(assessment);
    if (this.currentUser.isadmin) {
      this.isDownloadable = get(assessment, 'downloadreport', 0);
    } else {
      this.isDownloadable = false;
    }

    this.formattedStartDate = this.getDate(assessment['startdate']);
    this.formattedEndDate = this.getDate(assessment['enddate']);
    this.getAvailableDemographics(assessment);

    // console.log('assessment:');
    // console.log(JSON.stringify(assessment));
  }

  async getAssessmentReport() {
    const id = this.route.snapshot.paramMap.get('id');    
    const report = await this.adminService.getAssessmentReport(id).toPromise();
    this.report = report;
    // console.log('this.report:');
    // console.log(JSON.stringify(this.report));
  }

  deleteAssessmentById(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.adminService.deleteAssessmentById(id).subscribe((response) => {
      if(response.responseid == "1") {
        localStorage.setItem('message', 'The assessment has been deleted');
        localStorage.setItem('messageclass', 'alert-success');
        this.router.navigate(['/clients/' + this.assessment.client.id + '/projects/assessments']);
      }
      else {
        this.message = response.responsetxt;
        this.messageclass = 'alert-danger';
      }
    });
  }

  async getResponses() {
    // for SLCQ assessments don't show individual response info
    // if(this.assessment.idassessmenttype == 7){
      // console.log("found an SLCQ assessment - removing individual response data");
    //   this.responses = [];
    //   return;
    // }

    const id = this.route.snapshot.paramMap.get('id');
    const assessmentResponses = await this.adminService.getResponses(id, '1').toPromise();

    this.responses = assessmentResponses;

    this.responses = map(this.responses, (el) => {
      const o = Object.assign({}, el);
      o.withreport = false;
      return o;
    });

    // console.log('this.responses:');
    // console.log(JSON.stringify(this.responses));
  }

  getAvailableDemographics(assessment) {
    let idclient = get(assessment, 'client.id', 0);
    this.adminService.getAvailableDemographics(idclient).subscribe((availableDemographics) => {
                        this.availableDemographics = sortBy(availableDemographics, ['iddemographicquestion']);
                    });
  }

  checkStandardDemographics(step): void {
    map(this.availableDemographics, (question) => {
      find(this.demographicfilterlist, (assessmentDemographic) => {
        if (assessmentDemographic.iddemographicquestion === question.iddemographicquestion) {
          if (isEmpty(this.demographicfilterlist)) { this.demographicfilterlist = []; }
          question.checked = true;
          assessmentDemographic.old = true;
        }
      })
    });
    if (step) {
      this.step = 2;
      this.backHidden = true;
    } else {
      this.step = 1;
      this.backHidden = false;
    }
  }

  checkCustomDemographics(event, demographic): void {
    if(event.target.checked) {
      let selectedDemographic = find(this.demographicfilterlist, (addedDemographic) =>
        addedDemographic.iddemographicquestion === demographic.iddemographicquestion);
      if (selectedDemographic) {
        selectedDemographic.new = true;
        selectedDemographic.deleted = false;
        selectedDemographic.checked = true;
      } else { this.demographicfilterlist.push({ new: true, ...demographic, deleted: false, checked: true }); }
    } else {
      let selectedDemographic = find(this.demographicfilterlist, (addedDemographic) =>
        addedDemographic.iddemographicquestion === demographic.iddemographicquestion);
      selectedDemographic.new = false;
      selectedDemographic.deleted = true;
      selectedDemographic.checked = false;
    }

    let selectedDemographicFromModal = find(this.availableDemographics, (addedDemographic) =>
      addedDemographic.iddemographicquestion === demographic.iddemographicquestion);
    if (selectedDemographicFromModal) { selectedDemographicFromModal.checked = event.target.checked }
  }

  async getProjectsAssessments() {
    const currentClients = JSON.parse(localStorage.getItem('currentClients'));
    const strClients = [];
    for (const client1 of currentClients) {
      strClients.push(client1.idclient);
    }
    const id = this.route.snapshot.paramMap.get('id');
    const client2 = await this.adminService.getProjectsAssessments(strClients.join(','), id).toPromise();
    this.client = client2;
    // console.log('this.client:');
    // console.log(JSON.stringify(this.client));
  }

  async getAssessmentDemographics() {
    const id = this.route.snapshot.paramMap.get('id');
    const demographicfilterlist = await this.adminService.getAssessmentDemographics(id).toPromise();

    const demographics = sortBy(demographicfilterlist.demographics, ['iddemographicquestion']);
    this.demographicfilterlist = demographics.filter(question => question.lang === this.lang);

  }

  quickOverlayReport(id): void {
    if (id > 0) {
      const idone = this.route.snapshot.paramMap.get('id');
      //this.router.navigate(['/admin/report/overlay/' + idone + '/' + id + '']);
      this.router.navigate(['/report'], { queryParams: { idassessmentone: idone, idassessmenttwo: id}});
    }
  }

  quickDemographicReport(demographicgroup): void {
    if(demographicgroup !== 0) {
      const id = this.route.snapshot.paramMap.get('id');
      //this.router.navigate(['/admin/report/demographic/' + id + '/' + demographicgroup + '']);
      this.router.navigate(['/report'], { queryParams: { idassessment: id, demographicgroup: demographicgroup}});
    }
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.step = 1;
    this.isAdmin = this.permissionService.isAdmin();
    
    this.runAsyncMethods();
  }
  
  async runAsyncMethods() {
    // wait for each mothod to finish before moving to the next so we have all the data we need
    // we dont need to show the logs await this.getAssessmentLogs();
    await this.getAssessment();
    await this.getResponses();
    await this.getProjectsAssessments();
    await this.getAssessmentReport();
    this.copyFeedback = '';
    await this.getAssessmentDemographics();
    await this.buildSlcq();
  }

  exportRawData(): void {
    // console.log("exportRawData()");
    document.getElementById("exportrawdata").innerText = "Downloading...";
    document.getElementById("exportrawdata").setAttribute("disabled","disabled");
    this.limitdump = 7000;
    this.offsetdump = 0;
    this.exportRawDataPiece();
  }

  exportRawDataPiece(): void {
    // console.log("exportRawDataPiece()");
    const id = this.route.snapshot.paramMap.get('id');
    this.adminService.getAssessmentDump(id,this.limitdump,this.offsetdump).subscribe((assessmentdump) => {
      
      ////////////////////////////////////////////////////
      // also add these:
      // osaquestiondefinitions
      // slcqquestiondefinitions
      // demographicdefinitions

      this.assessmentdumpall = this.assessmentdumpall.concat(assessmentdump.rows);
      if(assessmentdump.rows.length >= this.limitdump) {
        this.offsetdump = this.offsetdump + this.limitdump;
        this.exportRawDataPiece();
      }
      else {
        this.exportAssessmentDump(this.assessmentdumpall);
        this.assessmentdumpall = []; // clear the var for the next download or it will double the rows
      }
    });
  }

  exportAssessmentDump(assessmentdump): void {
    // console.log("exportAssessmentDump()");
    // console.log(JSON.stringify(assessmentdump));
    var json = assessmentdump;
    var fields = Object.keys(json[0]);
    var replacer = function(key, value) { return value === null ? '' : value };
    var csv = json.map(function(row){
      return fields.map(function(fieldName){
        return JSON.stringify(row[fieldName], replacer);
      }).join(',');
    });
    csv.unshift(fields.join(','));
    this.downloadFile(csv.join('\r\n'));
    document.getElementById("exportrawdata").innerText = "Export";
    document.getElementById("exportrawdata").removeAttribute("disabled");
  }

  downloadFile(data: any){
    // console.log("downloadFile()");
    var blob = new Blob([data], { type: 'text/csv' });
    saveAs(blob, "data.csv");
  }

  downloadFileDefinitions(data: any){
    // console.log("downloadFile()");
    var blob = new Blob([data], { type: 'text/csv' });
    saveAs(blob, "data_definitions.csv");
  }

  getDate(date) {
    const dateArray = date.split("/");
    const formattedDate = dateArray[2] + "-" + dateArray[0] + "-" + dateArray[1];
    return formattedDate;
  }

  validateDate() {
    if (this.formattedEndDate < this.formattedStartDate) {
      this.formattedEndDate = this.formattedStartDate;
    }
  }

  copyUrl($event, url: string){
    $event.stopPropagation();
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    // var Url = document.getElementById('assessment-url') as HTMLInputElement; // this was returning null
    this.copyFeedback="URL copied to clipboard!";
    // Url.select(); // this was throwing an error because Url was null
    
  }

  submitChanges(): void {
    this.assessment.startdate = this.formattedStartDate;
    this.assessment.enddate = this.formattedEndDate;
    this.assessment.demographicQuestions = map(this.demographicfilterlist, (demographic) => demographic);
    this.adminService.editAssessment(this.assessment).subscribe((response) => {
      if(response.responseid == "1") {
        this.message = 'The assessment has been updated';
        this.messageclass = 'alert-success';
      }
      else {
        this.message = 'An error has occurred.';
        this.messageclass = 'alert-danger';
      }
      this.closeModal.nativeElement.click();
      this.ngOnInit();
    });
  }

  deleteAssessment() {
    // console.log("Delete Assessment...only if no answers yet");
  }

  deleteRespondentId(id, name) {
    this.actualrespondent = {
      id,
      name
    };
  }

  deleteRespondent() {
    this.adminService.deleteRespondentById(this.actualrespondent.id).subscribe((response) => {
      if(response.responseid == "1") {
        this.message = 'The respondent has been deleted';
        this.messageclass = 'alert-success';
        this.getResponses();
      }
      else {
        this.message = 'An error has occurred.';
        this.messageclass = 'alert-danger';
      }
    });
  }

  getAssessmentToReport(response) {
    const specificresponse = find(this.responses, ['id', response.idassessmentresponse]);
    specificresponse.withreport = true;
    this.mySavedAssessment = response;
    // specificresponse.withreport = false;
  }

  async buildSlcq() {

    // // check if this is an slcq assessment
    // if (this.assessment.idassessmenttype != 7) {
    //   return;
    // }

    const id = this.route.snapshot.paramMap.get('id');
    // get the layout for the quadrants
    const quadrantLayout = await this.adminService.getSlcqLayoutAndReport(id).toPromise();
    console.log('quadrantLayout:');
    console.log(JSON.stringify(quadrantLayout));

    this.createQuadrants(quadrantLayout);

    this.quadrantsLoading = false;

  }

  createQuadrants(quadrantLayout) {

    // console.log('quadrantLayout: ' + JSON.stringify(quadrantLayout));

    let counter = 0;
    for (const quadrant of quadrantLayout) {
      // console.log('quadrant: ' + JSON.stringify(quadrant));

      this.slcqQuadrant[counter] = new SlcqQuadrant;

      this.slcqQuadrant[counter].quadrantName = quadrant.quadrantname;
      this.slcqQuadrant[counter].sectionName = quadrant.sectionname;

      this.slcqQuadrant[counter].quadTitle1.line1 = quadrant.q1line1;
      this.slcqQuadrant[counter].quadTitle1.line2 = quadrant.q1line2;
      this.slcqQuadrant[counter].quadTitle2.line1 = quadrant.q2line1;
      this.slcqQuadrant[counter].quadTitle2.line2 = quadrant.q2line2;
      this.slcqQuadrant[counter].quadTitle3.line1 = quadrant.q3line1;
      this.slcqQuadrant[counter].quadTitle3.line2 = quadrant.q3line2;
      this.slcqQuadrant[counter].quadTitle4.line1 = quadrant.q4line1;
      this.slcqQuadrant[counter].quadTitle4.line2 = quadrant.q4line2;

      this.slcqQuadrant[counter].xLabel = quadrant.xlabel;
      this.slcqQuadrant[counter].xLabelLeft = quadrant.xscaleleft;
      this.slcqQuadrant[counter].xLabelRight = quadrant.xscaleright;

      this.slcqQuadrant[counter].yLabel = quadrant.ylabel;
      this.slcqQuadrant[counter].yLabelTop = quadrant.yscaletop;
      this.slcqQuadrant[counter].yLabelBottom = quadrant.yscalebottom;

      this.slcqQuadrant[counter].columnNames = ['Id', this.slcqQuadrant[counter].xLabel, this.slcqQuadrant[counter].yLabel, '', 'N'];

      this.slcqQuadrant[counter].meanX = quadrant.xaxismean;

      this.slcqQuadrant[counter].meanY = quadrant.yaxismean;

      this.slcqQuadrant[counter].quad1Percent = quadrant.q1percent;
      this.slcqQuadrant[counter].quad2Percent = quadrant.q2percent;
      this.slcqQuadrant[counter].quad3Percent = quadrant.q3percent;
      this.slcqQuadrant[counter].quad4Percent = quadrant.q4percent;

      this.slcqQuadrant[counter].totalResponses = quadrant.totalresponses;

      this.slcqQuadrant[counter].showTooltips = true;

      counter++;
    }

    // cleanup the sectionName so it only shows at the beginning of each component section
    let lastSectionName = '';
    for (let i = 0; i < this.slcqQuadrant.length; i++) {
      if (this.slcqQuadrant[i].sectionName === lastSectionName) {
        // remove the sectionName so it doesn't show up on the page as a new section
        this.slcqQuadrant[i].sectionName = null;
      } else {
        lastSectionName = this.slcqQuadrant[i].sectionName;
      }
    }

  }

  /*
  simulateLog(){
    let log: AssessmentLog  = {
        "os": "Windows NT 10.0",
        "platform": "desktop",
        "browser": "Chrome 91.0.4472.164",
        "idassessment": 30,
        "currentPage": 1,
        "questions": [
            {
                "id": 1,
                "enumerator": "1",
                "question": "",
                "subtext": "My company tends to act at a deliberate pace.",
                "reverse": false,
                "idcategory": 1,
                "category": "SECTION 1: These questions ask about your organization's strategic focus.",
                "answers": [
                    {
                        "answer": "Disagree",
                        "id": 1,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Disagree",
                        "id": 2,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Agree",
                        "id": 3,
                        "lang": "en",
                        "class": "focus active"
                    },
                    {
                        "answer": "Agree",
                        "id": 4,
                        "lang": "en",
                        "class": ""
                    }
                ],
                "lang": "en",
                "answer_id": 3,
                "validationmessage": {
                    "en": "",
                    "es": ""
                }
            },
            {
                "id": 3,
                "enumerator": "2",
                "question": "",
                "subtext": "In my company, we value doing things better.",
                "reverse": true,
                "idcategory": 1,
                "category": "",
                "answers": [
                    {
                        "answer": "Disagree",
                        "id": 1,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Disagree",
                        "id": 2,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Agree",
                        "id": 3,
                        "lang": "en",
                        "class": "focus active"
                    },
                    {
                        "answer": "Agree",
                        "id": 4,
                        "lang": "en",
                        "class": ""
                    }
                ],
                "lang": "en",
                "outercategory": "",
                "answer_id": 3,
                "validationmessage": {
                    "en": "",
                    "es": ""
                }
            },
            {
                "id": 5,
                "enumerator": "3",
                "question": "",
                "subtext": "My company tends to be analytical.",
                "reverse": true,
                "idcategory": 1,
                "category": "",
                "answers": [
                    {
                        "answer": "Disagree",
                        "id": 1,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Disagree",
                        "id": 2,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Agree",
                        "id": 3,
                        "lang": "en",
                        "class": "focus active"
                    },
                    {
                        "answer": "Agree",
                        "id": 4,
                        "lang": "en",
                        "class": ""
                    }
                ],
                "lang": "en",
                "outercategory": "",
                "answer_id": 3,
                "validationmessage": {
                    "en": "",
                    "es": ""
                }
            },
            {
                "id": 7,
                "enumerator": "4",
                "question": "",
                "subtext": "We tend to anticipate our customers' needs.",
                "reverse": false,
                "idcategory": 1,
                "category": "",
                "answers": [
                    {
                        "answer": "Disagree",
                        "id": 1,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Disagree",
                        "id": 2,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Agree",
                        "id": 3,
                        "lang": "en",
                        "class": "focus active"
                    },
                    {
                        "answer": "Agree",
                        "id": 4,
                        "lang": "en",
                        "class": ""
                    }
                ],
                "lang": "en",
                "outercategory": "",
                "answer_id": 3,
                "validationmessage": {
                    "en": "",
                    "es": ""
                }
            },
            {
                "id": 4,
                "enumerator": "5",
                "question": "",
                "subtext": "In my company, we value doing things differently.",
                "reverse": false,
                "idcategory": 1,
                "category": "",
                "answers": [
                    {
                        "answer": "Disagree",
                        "id": 1,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Disagree",
                        "id": 2,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Agree",
                        "id": 3,
                        "lang": "en",
                        "class": "focus active"
                    },
                    {
                        "answer": "Agree",
                        "id": 4,
                        "lang": "en",
                        "class": ""
                    }
                ],
                "lang": "en",
                "outercategory": "",
                "answer_id": 3,
                "validationmessage": {
                    "en": "",
                    "es": ""
                }
            },
            {
                "id": 2,
                "enumerator": "6",
                "question": "",
                "subtext": "My company tends to act at a rapid pace.",
                "reverse": true,
                "idcategory": 1,
                "category": "",
                "answers": [
                    {
                        "answer": "Disagree",
                        "id": 1,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Disagree",
                        "id": 2,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Agree",
                        "id": 3,
                        "lang": "en",
                        "class": "focus active"
                    },
                    {
                        "answer": "Agree",
                        "id": 4,
                        "lang": "en",
                        "class": ""
                    }
                ],
                "lang": "en",
                "outercategory": "",
                "answer_id": 3,
                "validationmessage": {
                    "en": "",
                    "es": ""
                }
            },
            {
                "id": 9,
                "enumerator": "7",
                "question": "",
                "subtext": "In my company, we value growth of market share.",
                "reverse": false,
                "idcategory": 1,
                "category": "",
                "answers": [
                    {
                        "answer": "Disagree",
                        "id": 1,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Disagree",
                        "id": 2,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Agree",
                        "id": 3,
                        "lang": "en",
                        "class": "focus active"
                    },
                    {
                        "answer": "Agree",
                        "id": 4,
                        "lang": "en",
                        "class": ""
                    }
                ],
                "lang": "en",
                "outercategory": "",
                "answer_id": 3,
                "validationmessage": {
                    "en": "",
                    "es": ""
                }
            },
            {
                "id": 6,
                "enumerator": "8",
                "question": "",
                "subtext": "My company tends to be experimental.",
                "reverse": false,
                "idcategory": 1,
                "category": "",
                "answers": [
                    {
                        "answer": "Disagree",
                        "id": 1,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Disagree",
                        "id": 2,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Agree",
                        "id": 3,
                        "lang": "en",
                        "class": "focus active"
                    },
                    {
                        "answer": "Agree",
                        "id": 4,
                        "lang": "en",
                        "class": ""
                    }
                ],
                "lang": "en",
                "outercategory": "",
                "answer_id": 3,
                "validationmessage": {
                    "en": "",
                    "es": ""
                }
            },
            {
                "id": 8,
                "enumerator": "9",
                "question": "",
                "subtext": "We tend to react to our customers' needs.",
                "reverse": true,
                "idcategory": 1,
                "category": "",
                "answers": [
                    {
                        "answer": "Disagree",
                        "id": 1,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Disagree",
                        "id": 2,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Agree",
                        "id": 3,
                        "lang": "en",
                        "class": "focus active"
                    },
                    {
                        "answer": "Agree",
                        "id": 4,
                        "lang": "en",
                        "class": ""
                    }
                ],
                "lang": "en",
                "outercategory": "",
                "answer_id": 3,
                "validationmessage": {
                    "en": "",
                    "es": ""
                }
            },
            {
                "id": 10,
                "enumerator": "10",
                "question": "",
                "subtext": "In my company, we value growth of profits.",
                "reverse": true,
                "idcategory": 1,
                "category": "",
                "answers": [
                    {
                        "answer": "Disagree",
                        "id": 1,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Disagree",
                        "id": 2,
                        "lang": "en",
                        "class": ""
                    },
                    {
                        "answer": "Somewhat Agree",
                        "id": 3,
                        "lang": "en",
                        "class": "focus active"
                    },
                    {
                        "answer": "Agree",
                        "id": 4,
                        "lang": "en",
                        "class": ""
                    }
                ],
                "lang": "en",
                "outercategory": "",
                "answer_id": 3,
                "validationmessage": {
                    "en": "",
                    "es": ""
                }
            }
        ],
        "logtype": "page change",
        "logdate": "7/22/2021, 3:29:10 PM"
    }
    console.log("LOGGING DATA",log);
    this.assessmentLogService.submitAssessmentLog(log).subscribe((resp) => {
      console.log(resp);
    });
  }
  
*/
}
