import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AssessmentService } from '../assessment.service';
import { AssessmentLogService } from '../assessmentlog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { orderBy, some, head, filter, get, groupBy, range, forEach, includes } from 'lodash';
import { AssessmentIntro, AssessmentExit, AssessmentQuestion, Assessment, AssessmentResponse, AssessmentLog } from '../assessment';
import { ThrowStmt } from '@angular/compiler';
import { Title } from '@angular/platform-browser';
import { BrandingService } from '../branding.service';
import { JsonpClientBackend } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { HostListener } from "@angular/core";

import * as Bowser from "bowser";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.css']
})
export class AssessmentComponent implements OnInit {
  rowsperpage = 10; //10 by default but this value changes when its a mobile device
  page = 0;
  totalpages = 0;
  istlastpage = false;
  areDemographics = false;
  assessmentquestions = [];
  assessmentpages = [];
  allassessmentquestions = [];
  myAssessment : Assessment;
  originalAssessment : Assessment;
  mySavedAssessment : Assessment;
  myResponse  = {};
  intro = {};
  exit = {};
  client = {};
  fixedLabels = {
    en: {
      next: 'continue',
      back: 'previous',
      done: 'submit',
      demographicsTitle: 'Demographics',
      help: 'The page you requested cannot be accessed. Please contact Strategia Analytics at ',
      report: 'Download Report'
    },
    es: {
      next: 'continuar',
      back: 'regresar',
      done: 'enviar',
      demographicsTitle: 'Demográficos',
      help: 'Esta página no puede ser accedida en este momento. Por favor, contacta a Strategia Analytics en la siguiente dirección ',
      report: 'Descargar Reporte'
    }
  }
  pageiscomplete = false;
  firstPageDemographics = false;
  lastPageDemographics = false;
  numExtraPages = 0;
  lang = 'en';
  defaultLang = 'en';
  langList = [
    { lang: 'en', name: 'English' },
    { lang: 'es', name: 'Spanish' }
  ]
  stateKeys = [ "rowsperpage","page", "totalpages", "istlastpage", "areDemographics", "assessmentquestions", "assessmentpages", "allassessmentquestions", 
                "myResponse", "intro", "exit", "client", "fixedLabels", "pageiscomplete", "firstPageDemographics", "lastPageDemographics", "numExtraPages",
                "lang", "branding", "session_uuid", "logmetadata","myAssessment"];
  branding = {};
  isOffline = false;
  session_uuid = "";
  logmetadata: any = {};
  window = null;
  isMobile = false;
  showQuestionCategory = false;
  pageOffset = 0;
  firstLoad = true;
  currentQuestionCategory = null; 

  constructor(@Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private assessmentService: AssessmentService,
    private assessmentLogService: AssessmentLogService,
    private location: Location,
    private titleService: Title,
    private brandingService: BrandingService) {  
    this.branding = brandingService.setBranding(document, titleService);
    this.window = this.document.defaultView;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    // console.log("entered onResize");
    // console.log("this.myAssessment.demographicQuestions start of onResize:");
    // console.log(this.myAssessment.demographicQuestions);

    let isCurrentlyMobile = window.innerWidth > 768 ? false : true;
    
    if((this.isMobile == true ) && (false == isCurrentlyMobile)){
      // console.log("from mobile to desktop");
      this.isMobile = false;
      this.rowsperpage = 10;

      this.recalculateQuestions();
    }
    else if((this.isMobile == false ) && (true == isCurrentlyMobile)){
      // console.log("from desktop to mobile");
      this.isMobile = true;
      this.rowsperpage = 1;
      this.recalculateQuestions();
    }
    else{
      // console.log("stays the same");
      this.isMobile = isCurrentlyMobile;
    }
    this.firstLoad = false;
  }
  recalculateQuestions(){
    console.log("entered recalculateQuestions");
    // console.log("this.myAssessment.demographicQuestions start of recalculateQuestions():");
    // console.log(this.myAssessment.demographicQuestions);

    this.assessmentpages = [];
    let incompleteFlag = true;
    let currentPage = 0;

    if(this.isMobile) {
      this.totalpages = Math.round(this.allassessmentquestions.length / this.rowsperpage) + this.numExtraPages;
      console.log("totalpages", this.totalpages);
    } else {
      const groupedBySection = groupBy(this.allassessmentquestions, (question) => question.sectionName);

      let pages = 0;
      for (const section of Object.entries(groupedBySection)) {
        // console.log([...value].length, value.length);
        const key = section[0];
        const value = section[1] as [];
        pages += Math.ceil(value.length / 10);
      }
      console.log('SECTION PAGES', pages)
  
      this.totalpages = pages + this.numExtraPages;
  
      console.log("totalpages", this.totalpages);
    }

    if(this.isMobile){
      for (var _i = 0; _i <= this.totalpages; _i++) {
        if(this.allassessmentquestions[_i] && this.isAnswered(this.allassessmentquestions[_i])){
          this.assessmentpages.push({status : 'complete', islast : false, position: _i});
          if(incompleteFlag ){
            currentPage = _i;
          }
        }
        else{
          this.assessmentpages.push({status : 'incomplete', islast : false, position: _i});
            incompleteFlag = false;
        } 
      }
      this.page = 0;
      this.movePage(0);
      if(!this.firstLoad){
        this.assessmentpages[this.assessmentpages.length-1].islast = true;
        currentPage+= 2;
        for (var _i = 0; _i < currentPage; _i++) {
          this.movePage(1);
        }
      }
      this.assessmentpages[this.assessmentpages.length-1].islast = true;
      //this.goToPage(this.assessmentpages[currentPage +1]);
    }
    else{
      // this.totalpages+=1;
      for(let _i = 0; _i <= this.totalpages; _i++){
          this.assessmentpages.push({status : 'incomplete', islast : false, position: _i});
      }

      this.page = 0;
      this.movePage(0);
      if(!this.firstLoad){
        for(let _i = 0; _i < this.allassessmentquestions.length; _i++){
          if(this.isAnswered(this.allassessmentquestions[_i])){
            if(_i > 0){
              currentPage = _i;
            }
            //console.log("for some reason it didnt go inside");
          }
          else{
            break;
          }
        }
        currentPage = Math.floor(currentPage/10);
        //console.log("currentPage", currentPage);
        //this.page = 0;
        this.assessmentpages[this.assessmentpages.length-1].islast = true;
        for (var _i = 0; _i <= currentPage; _i++) {
          this.movePage(1);
        }
      }
      this.assessmentpages[this.assessmentpages.length-1].islast = true;

      // console.log("this.myAssessment.demographicQuestions end of recalculateQuestions():");
      // console.log(this.myAssessment.demographicQuestions);
    }
    
    let assessmentType = get(this.myAssessment, 'name[0].name', '')
    if (assessmentType == 'Organizational Strategic Alignment' || assessmentType == 'My Preferred Organization') {
      let initLangModal: HTMLElement = this.document.getElementById('triggerInitModal') as HTMLElement;
      initLangModal.click();
    } else {
      let alert = this.document.getElementById("alert-info");
      if (alert) { alert.classList.add('hide'); }
    }
  }
  
  isAnswered(question){
    // console.log("entered isAnswered");
    let answers = question.answers;
    for(let i = 0; i < answers.length; i++){
      if(answers[i].class == "focus active"){
        return true;
      }
    }
    return false;
    
  }
  questionContinue(question_id: number): void {
    // console.log("entered questionContinue");
    this.showQuestionCategory = true;
  }
  assignAssessment(assessment): void {
    // console.log("entered assignAssessment");
    let groupAssessmentSectionTitle = '';
    let idoutercategory = 0;
    let idcategory = 0;
    /*
    console.log("DISPLAY DATA");
    console.log(assessment);
    Assign the id to the log metadata for logging purposes
    */
    let { browser, os, platform } = Bowser.parse(window.navigator.userAgent);
    this.logmetadata.browser =  `${browser.name} ${browser.version}`;
    this.logmetadata.os = `${os.name} ${os.version}`;
    this.logmetadata.platform = `${platform.type}`;
    this.logmetadata.idassessment =  assessment.idassessment;

    this.originalAssessment = Object.assign({}, assessment);
    let assessmentQuestions = JSON.parse(JSON.stringify(this.originalAssessment.assessmentQuestions));
    assessmentQuestions = assessmentQuestions.filter(question => question.lang == this.lang);
    this.allassessmentquestions = assessmentQuestions.filter(question => {
      question.answers = question.answers.filter((answer) => answer.lang == this.lang);
      return question;
    });

    for(let question of this.allassessmentquestions) {
      if(question.category != ''){
        let section = question.category.split(': ');
        question.category = section[1];
        question.sectionName = section[0];
      }
      
      if (question.groupAssessmentSectionTitle == groupAssessmentSectionTitle) {
        question.groupAssessmentSectionTitle = '';
      } else {
        groupAssessmentSectionTitle = question.groupAssessmentSectionTitle;
      }
      
      if (question.idoutercategory == idoutercategory) {
        question.outercategory = '';
      }
      idoutercategory = question.idoutercategory;

      if (question.idcategory == idcategory) {
        question.category = '';
      }
      idcategory = question.idcategory;
    }

    let count = 2;
    let sectionCount = 1;
    let currentQuestionId = null;
    for (let i = 0; i < this.allassessmentquestions.length; i++){
      if(i == 0){
        this.allassessmentquestions[0].sectionNumber = sectionCount;
        sectionCount++;  
        this.allassessmentquestions[0].questionNumber = 1;
        currentQuestionId = i;
      }
      else if (this.allassessmentquestions[i].category != '') {
        if(this.allassessmentquestions[currentQuestionId]){
          this.allassessmentquestions[currentQuestionId].questionCount = count-1;
          this.allassessmentquestions[i].sectionNumber = sectionCount;
          sectionCount++;  
          this.allassessmentquestions[i].questionNumber = 1;
        }
        count = 2;
        currentQuestionId = i;
      }
      else{
        this.allassessmentquestions[i].questionNumber = count;
        count++;
      }
    }
    let question = this.allassessmentquestions[0];
    this.currentQuestionCategory = {};
    this.currentQuestionCategory.fullText = question.category;
    this.currentQuestionCategory.sectionNumber = question.sectionNumber;
    this.currentQuestionCategory.questionCount = question.questionCount;
    this.allassessmentquestions[currentQuestionId].questionCount = count-1;


    let questionCount;
    for (let i = 0; i < this.allassessmentquestions.length; i++){
      if(this.allassessmentquestions[i].questionCount){
        questionCount = this.allassessmentquestions[i].questionCount;
      }
      else{
        this.allassessmentquestions[i].questionCount = questionCount;
      }
    }

    this.intro = head(assessment.assessmentIntro.filter(intro => intro.lang == this.lang));
    this.exit = head(assessment.assessmentExit.filter(intro => intro.lang == this.lang));
    this.client = assessment.client;
    this.myAssessment = Object.assign({}, assessment);
    this.myAssessment.howtoread = get(head(assessment.howtoread.filter(intro => intro.lang == this.lang)), 'howtoread', '');
    this.myAssessment.demographicQuestions = 
      orderBy(this.originalAssessment.demographicQuestions.filter(
        (demo) => { 
          if (demo.lang == this.lang) { 
            return demo;
          }
      }), ['weight'], ['asc']); // sort by the weight only
    this.initialLoadComponent();
    console.log("this.myAssessment.assessmentQuestions",this.myAssessment.assessmentQuestions);
    console.log("this.allassessmentquestions",this.allassessmentquestions);
    // console.log("this.myAssessment.demographicQuestions in assignAssessment():");
    // console.log(this.myAssessment.demographicQuestions);
  }

  changeLang(lang): void {
    // console.log("entered changeLang");
    let idcategory = 0;
    this.lang = lang;
    this.intro = head(filter(this.originalAssessment.assessmentIntro, intro => intro.lang == this.lang));
    this.exit = head(filter(this.originalAssessment.assessmentExit, intro => intro.lang == this.lang));
    this.myAssessment.howtoread = get(head(this.originalAssessment.howtoread.filter(intro => intro.lang == this.lang)), 'howtoread', '');
    
    let assessmentQuestions = JSON.parse(JSON.stringify(this.originalAssessment.assessmentQuestions));
    assessmentQuestions = assessmentQuestions.filter(question => question.lang == this.lang);
    this.allassessmentquestions = assessmentQuestions.filter((question) => {
      question.answers = question.answers.filter((answer) => answer.lang == this.lang);
      return question;
    });
    for (let question of this.allassessmentquestions) {
      if (question.idcategory == idcategory) {
        question.category = '';
      }
      idcategory = question.idcategory;
    }

    // this.myAssessment.demographicQuestions = 
    //   orderBy(this.originalAssessment.demographicQuestions.filter(
    //     (demo) => { 
    //       if (demo.lang == this.lang) { 
    //         return demo;
    //       }
    //   }), ['type', 'id', 'weight'], ['asc', 'asc', 'asc']);
    this.movePage(0);
  }

  getAssessment(): void {
    // console.log("entered getAssessment");
    const hash = this.route.snapshot.paramMap.get('hash');
    this.assessmentService.getAssessment(hash).subscribe((assessment) => {
      // console.log("assessment");
      // console.log(assessment);
      this.assignAssessment(assessment);
      this.checkAssessmentState(assessment);
      this.onResize();
    });

    
  }

  submitResponse(): void {
    // console.log("entered submitResponse");
    //console.log("SUBMITTING ASSESSMENT REPONSE");
    this.myAssessment.assessmentQuestions = this.allassessmentquestions;
    this.myAssessment.uuid = this.session_uuid;
    // console.log("this.myAssessment:");
    // console.log(this.myAssessment);
    // console.log(JSON.stringify(this.myAssessment));
    this.assessmentService.submitAssessment(this.myAssessment).subscribe((assessment) => {
      // console.log("submitResponse returned assessment:");
      // console.log(assessment);
      this.mySavedAssessment = assessment;
    });
    //console.log("FINISHED SUBMITTING ASSESSMENT");
    this.goEnd();
    this.removeState();
  }

  goEnd(): void {
    // console.log("entered goEnd");
    this.page = -1;
  }

  refreshPage(): void {
    // console.log("entered refreshPage");
    window.location.reload();
  }

  ngOnInit() {
    // console.log("entered ngOnInit");
    this.session_uuid = uuidv4();
    this.getAssessment();
    //this.addOfflineCheckers();
  }
  addOfflineCheckers(){
    // console.log("entered addOfflineCheckers");
    this.window.addEventListener('online', () => console.log('Became online'));
    this.window.addEventListener('offline', () => console.log('Became offline'));
  }

  checkAssessmentState(assessment){
    // console.log("entered checkAssessmentState");
    // console.log("this.myAssessment.demographicQuestions start of checkAssessmentState:");
    // console.log(this.myAssessment.demographicQuestions);

    let storedState = JSON.parse(localStorage.getItem('assessmentState'));
    if(!storedState){
      this.saveState();
      // console.log("this.myAssessment.demographicQuestions leaving checkAssessmentState at inner return:");
      // console.log(this.myAssessment.demographicQuestions);
      return;
    }
    if(assessment.idassessment == storedState.myAssessment.idassessment){
      this.getStoredState(storedState);
    }
    
    // console.log("this.myAssessment.demographicQuestions end of checkAssessmentState:");
    // console.log(this.myAssessment.demographicQuestions);
  }

  getStoredState(storedState){ //maps the stored state keys to the current state
    // console.log("entered getStoredState");
    // console.log("this.myAssessment.demographicQuestions start of getStoredState:");
    // console.log(this.myAssessment.demographicQuestions);
    // console.log("storedState start of getStoredState:");
    // console.log(storedState);
    for(let i=0; i<this.stateKeys.length; i++){
      this[this.stateKeys[i]] = storedState[this.stateKeys[i]];
    }
    
    // console.log("storedState end of getStoredState:");
    // console.log(storedState);
    // console.log("this.myAssessment.demographicQuestions end of getStoredState:");
    // console.log(this.myAssessment.demographicQuestions);
  }

  saveState(){
    // console.log("entered saveState");
    // console.log("this.myAssessment.demographicQuestions:");
    // console.log(this.myAssessment.demographicQuestions);
    // console.log("this.stateKeys:");
    // console.log(this.stateKeys);

    if(this.page == 0){
      return;
    }
    let currentState = {};
    for(let i=0; i<this.stateKeys.length; i++){
      // console.log(this.stateKeys[i]);
      // console.log(this[this.stateKeys[i]]);
      currentState[this.stateKeys[i]] = this[this.stateKeys[i]];
    }
    localStorage.setItem('assessmentState', JSON.stringify(currentState));

    
    // console.log("exiting saveState");
    // console.log("this.myAssessment.demographicQuestions:");
    // console.log(this.myAssessment.demographicQuestions);
    // console.log("this.stateKeys:");
    // console.log(this.stateKeys);
  }

  removeState(){
    // console.log("entered removeState");
    localStorage.removeItem('assessmentState');
  }

  initialLoadComponent(): void{
    // console.log("entered initialLoadComponent");
    this.assessmentpages = [];
    this.numExtraPages = 0;
    
    for (let question of this.myAssessment.demographicQuestions) {
      if (question.weight < 0 && !this.firstPageDemographics) {
        this.areDemographics = false;
        this.firstPageDemographics = true;
        this.numExtraPages = this.numExtraPages + 1;
      }
      if (question.weight >= 0 && !this.lastPageDemographics) {
        this.areDemographics = true;
        this.lastPageDemographics = true;
        this.numExtraPages = this.numExtraPages + 1;
      }
    }

    if (some(this.myAssessment.demographicQuestions, (question) =>
      (question.weight >= 0))) {
      this.areDemographics = true;
    } else {
      this.areDemographics = false;
    }
    this.isMobile = window.innerWidth < 768 ? false : true;
    console.log(this.isMobile);

    this.rowsperpage = this.isMobile ? 1 : 10; 

    let incompleteFlag = true;
    let currentPage = 0;

    if (this.isMobile) {
      this.totalpages = Math.ceil(this.allassessmentquestions.length / this.rowsperpage) + this.numExtraPages;
    } else {
      const groupedBySection = groupBy(this.allassessmentquestions, (question) => question.sectionName);

      let pages = 0;
      for (const section of Object.entries(groupedBySection)) {
        // console.log([...value].length, value.length);
        const value = section[1] as [];

        pages += Math.ceil(value.length / 10);
      }
      console.log('SECTION PAGES', pages);
  
      this.totalpages = pages + this.numExtraPages;
    }

    for (var _i = 0; _i <= this.totalpages; _i++) {
      if(this.allassessmentquestions[_i] && this.isAnswered(this.allassessmentquestions[_i])) {
        this.assessmentpages.push({status : 'complete', islast : false, position: _i});
        if(incompleteFlag ){
          currentPage = _i;
        }
      } else {
        this.assessmentpages.push({status : 'incomplete', islast : false, position: _i});
        if(_i > this.pageOffset){
          incompleteFlag = false;
        }
      }
    }

    if(!this.isMobile){
      currentPage =  Math.round(currentPage/10);
    }

    this.page = currentPage;
    this.assessmentpages[this.assessmentpages.length-1].islast = true;
    this.movePage(currentPage);
    let assessmentType = get(this.myAssessment, 'name[0].name', '')
    if (assessmentType == 'Organizational Strategic Alignment' || assessmentType == 'My Preferred Organization') {
      let initLangModal: HTMLElement = this.document.getElementById('triggerInitModal') as HTMLElement;
      initLangModal.click();
    } else {
      let alert = this.document.getElementById("alert-info");
      if (alert) { alert.classList.add('hide'); }
    }
    
    // console.log("this.myAssessment.demographicQuestions in initialLoadComponent():");
    // console.log(this.myAssessment.demographicQuestions);
  }
  /*
  initialLoadComponent(): void {
    this.numExtraPages = 0;
    for (let question of this.myAssessment.demographicQuestions) {
      if (question.weight < 0 && !this.firstPageDemographics) {
        this.areDemographics = false;
        this.firstPageDemographics = true;
        this.numExtraPages = this.numExtraPages + 1;
      }
      if (question.weight >= 0 && !this.lastPageDemographics) {
        this.areDemographics = true;
        this.lastPageDemographics = true;
        this.numExtraPages = this.numExtraPages + 1;
      }
    }

    if (some(this.myAssessment.demographicQuestions, (question) =>
      (question.weight >= 0))) {
      this.areDemographics = true;
    } else {
      this.areDemographics = false;
    }
    
    this.totalpages = Math.ceil(this.allassessmentquestions.length / this.rowsperpage) + this.numExtraPages;
    for (var _i = 0; _i <= this.totalpages; _i++) {
      this.assessmentpages.push({status : 'incomplete', islast : false, position: _i});
    }
    this.assessmentpages[this.assessmentpages.length-1].islast = true;
    this.page = 0;
    this.movePage(0);
    let assessmentType = get(this.myAssessment, 'name[0].name', '')
    if (assessmentType == 'Organizational Strategic Alignment' || assessmentType == 'My Preferred Organization') {
      let initLangModal: HTMLElement = this.document.getElementById('triggerInitModal') as HTMLElement;
      initLangModal.click();
    } else {
      let alert = this.document.getElementById("alert-info");
      if (alert) { alert.classList.add('hide'); }
    }
  }*/

  async answerQuestion(question_id: number, answer_id: number, question: any) {
    //console.log("all quest",this.allassessmentquestions[7].continue);
    //console.log("question Answered");
    //await new Promise(r => setTimeout(r, 1000));
    for (let question of this.allassessmentquestions) {
      if(question.id == question_id) {
        question.answer_id = answer_id;
        question.validationmessage = {
          en: '',
          es: ''
        };
        
        for (let answer of question.answers) {
          answer.class = '';
          if(answer.id == answer_id) {
            answer.class = 'focus active';
          }
        }

        this.showQuestionCategory = false;
      }
    }

    this.isPageComplete();
    /*
      Do this bc on mobile we only show one question per page, 
      when the user answers a question we move the page automatically
    */

    if(this.isMobile) {
      // console.log(question);

      // if (parseInt(question.enumerator) !== this.totalpages) {
        this.movePageIfComplete(1);
      // }
    }
  }

  answerDemographicQuestion(question_id: number, value: number,  valuetext: string): void {
    // console.log("entered answerDemographicQuestion");
    // console.log("answerDemographicQuestion() question_id=" + question_id + " value=" + value + " valuetext=" + valuetext);
    
    // console.log("this.myAssessment.demographicQuestions in answerDemographicQuestion():");
    // console.log(this.myAssessment.demographicQuestions);

    for (let question of this.myAssessment.demographicQuestions) {
      if(question.id == question_id) {
        // console.log("question in answerDemographicQuestion():");
        // console.log(question);
        let answer_text;
        if (value) {
          // get the chosen value inthe valuset
          let selected_value = question.valueset.find((item) => item.id == value);
          // do we need to show a textbox?
          question.needtextbox = selected_value.needtextbox;
          // console.log("needtextbox: " + question.needtextbox);
          
          if(question.needtextbox){
            answer_text = valuetext;
          } else {
            answer_text = selected_value.value;
          }
        } else {
          answer_text = valuetext;
        }

        question.answer_id = value;
        question.answer_txt = answer_text
        question.validationmessage = {
          en: '',
          es: ''
        };
      }
    }
    this.isDemographicPageComplete();
    
    console.log("this.myAssessment.demographicQuestions at end answerDemographicQuestion():");
    console.log(this.myAssessment.demographicQuestions);
  }

  isPageComplete(): void {
    // console.log("entered isPageComplete");
    let allcomplete = true;
    for (let question of this.assessmentquestions) {
      if (typeof question.answer_id === 'undefined') {
        allcomplete = false;
      }
    }

    this.pageiscomplete = allcomplete;
  }

  isDemographicPageComplete(): void {
    // console.log("entered isDemographicPageComplete");
    let allcomplete = true;
    // // demographic questions are now optional
    // for (let question of this.myAssessment.demographicQuestions) {
    //   if (typeof question.answer_id === 'undefined'  && question.weight < 0) {
    //     allcomplete = false;
    //   }
    // }
    this.pageiscomplete = allcomplete;
  }

  movePageIfComplete(move: number): void {
    // console.log("entered movePageIfComplete");
    if(this.pageiscomplete) {
      this.movePage(move);
    }
    else {
      let firstquestion = 0;
      for (let question of this.assessmentquestions) {
        if (typeof question.answer_id === 'undefined') {
          question.validationmessage = {
            en: "This question requires an answer.",
            es: "La respuesta a pregunta es requerida."
          }
          if(firstquestion == 0) {
            firstquestion = question.id;
          }
        }
      }
      window.scroll(0, this.document.getElementById('question_' + firstquestion).offsetTop - 100);
    }
  }

  movePageIfCompleteDemo(move: number): void {
    // console.log("entered movePageIfCompleteDemo()");
    /* demographic questions are optional now */
    
    // if(this.pageiscomplete) {
      this.movePage(move);
    // }
    // else {
    //   let firstquestion = 0;
    //   for (let question of this.myAssessment.demographicQuestions) {
    //     if (typeof question.answer_id === 'undefined') {
    //       question.validationmessage = {
    //         en: "This question requires an answer.",
    //         es: "La respuesta a pregunta es requerida."
    //       };
    //       if(firstquestion == 0) {
    //         firstquestion = question.id;
    //       }
    //     }
    //   }
    //   //window.scroll(0, this.document.getElementById('question_' + firstquestion).offsetTop - 100);
    // }
  }

  logData(): void{
    // console.log("entered logData()");
    //get the device info for the logs
    if(this.page > 0){

      let uuid = this.session_uuid;
      let nlogtype = "page change";
      let options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
      let date  = new Date();
      let { os, platform, browser, currentPage, idassessment } = this.logmetadata;
  /*
      let cleanQuestions = JSON.parse(JSON.stringify(this.assessmentquestions));
      let questions = cleanQuestions.map( function(question){
          let answers = question.answers.filter( answer => answer.class.includes('active'));
          delete question.answers;
          question.answer = answers[0].answer;
          return question;
        });
*/
      let log:AssessmentLog  = {
        os,
        platform,
        browser,
        idassessment,
        currentPage: this.page,
        questions: this.assessmentquestions,
        logtype: nlogtype,
        logdate: date.toLocaleDateString("en-US", options),
        uuid
      };
      //makes service call to upload the data
      //console.log("log:",log, this.logmetadata);

      this.assessmentLogService.submitAssessmentLog(log).subscribe((resp) => {
        this.isOffline = false;
      }, (error)=>{
        this.isOffline = true;
      });
      
    }
  }
  
  movePage(move: number): void {
    // console.log("entered movePage()");    
    // console.log("this.myAssessment.demographicQuestions start of movepage():");
    // console.log(this.myAssessment.demographicQuestions);

    this.logData();
    this.saveState();
    window.scroll(0, 0);
    if (move == 0) {
      let alert = this.document.getElementById("alert-info");
      if (alert) { alert.classList.remove('hide'); }
    } else {
      let alert = this.document.getElementById("alert-info");
      if (alert) { alert.classList.add('hide'); }
    }
    //console.log("rows again", this.rowsperpage);
    this.assessmentquestions = [];
    this.page = this.page + move;
    let realpage = this.page - this.numExtraPages;
    if (!this.areDemographics) { realpage -= 1; }

    const groupedBySection = groupBy(this.allassessmentquestions, (question) => question.sectionName);

    let pages = 0;
    let latestPage = 0;
    let pagesMatrix = {};
    for (const section of Object.entries(groupedBySection)) {
      // console.log([...value].length, value.length);
      const key = section[0];
      const value = section[1] as [];

      
      pages += Math.ceil(value.length / 10);
      latestPage = pages - Math.ceil(value.length / 10);
      const nPages = Math.ceil(value.length / 10);

      pagesMatrix = Object.assign(pagesMatrix, {[key]: nPages > 1 ? range(latestPage + 1, pages + 1) : [latestPage + 1]})
    }

    if(this.isMobile) {
      if(this.page >= 1 && realpage < this.totalpages) {
        let offset = this.rowsperpage * realpage;//this.rowsperpage * realpage;
        for (var _i = offset; _i < offset + this.rowsperpage; _i++) {
          if(_i < this.allassessmentquestions.length) {
            let question = this.allassessmentquestions[_i];
            this.assessmentquestions.push(question);
          }
        }
      }
    } else {
      if(this.page >= 1 && realpage < pages) {
        let sectionKey = Object.keys(pagesMatrix).find(key => includes(pagesMatrix[key], realpage+1));
        let sectionIndex = pagesMatrix[sectionKey].indexOf(realpage+1);
        let offset = this.rowsperpage * (sectionIndex+1);
      
        this.assessmentquestions = groupedBySection[sectionKey].length <= 10 ? groupedBySection[sectionKey] : groupedBySection[sectionKey].slice((offset - this.rowsperpage), offset);
        console.log('currentQuestions', realpage+1, offset, this.assessmentquestions);
  
        // let offset = this.rowsperpage * realpage;//this.rowsperpage * realpage;
        // for (var _i = offset; _i < offset + this.rowsperpage; _i++) {
        //   if(_i < this.allassessmentquestions.length) {
        //     let question = this.allassessmentquestions[_i];
        //     this.assessmentquestions.push(question);
        //   }
        // }
      }
    }

    for (var _i = 0; _i < this.assessmentpages.length; _i++) {
      if(this.page > _i) {
        this.assessmentpages[_i].status = 'complete';
      }
      else if(this.page == _i) {
        this.assessmentpages[_i].status = 'in-progress';
      }
      else {
        this.assessmentpages[_i].status = 'incomplete';
      }
    }


    this.istlastpage = this.page < this.assessmentpages.length - 1 ? this.assessmentpages[this.page].islast : this.assessmentpages[realpage].islast;
    //console.log(this.assessmentquestions[0].answers[0].class);
    if(this.page == 1 && this.firstPageDemographics) {
      this.isDemographicPageComplete();
    }
    else if(this.istlastpage && this.lastPageDemographics) {
      for (let question of this.myAssessment.demographicQuestions) {
        question.validationmessage = {
          en: '',
          es: ''
        };
      }
      this.pageiscomplete = true;
    }
    else {
      this.isPageComplete();
    }
    // console.log("current questions",this.assessmentquestions);
    // console.log("assessmentpages: ",this.assessmentpages);
    // console.log("rowsperpage: ",this.rowsperpage);
    // console.log("numExtraPages: ",this.numExtraPages);
    // console.log("totalpages: ",this.totalpages);
    
    // console.log("this.myAssessment.demographicQuestions end of movepage():");
    // console.log(this.myAssessment.demographicQuestions);
    
  }

  goToPage(page): void {
    // console.log("entered goToPage()");
    if(page.status == 'complete') {
      this.movePage(page.position - this.page);
    }
  }
}
