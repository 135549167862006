import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule }    from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { AssessmentComponent } from './assessment/assessment.component';
// import { AssessmentSlcqComponent } from './assessment-slcq/assessment-slcq.component';
import { ReportingToolComponent } from './reporting-tool/reporting-tool.component';
import { AppRoutingModule } from './app-routing.module';

import { LoginComponent } from './login/login.component';
import { MessageService } from './message.service';
import { PermissionService } from './permission.service';
import { AssessmentService } from './assessment.service';
import { AssessmentLogService } from './assessmentlog.service';
import { AdminService } from './admin.service';
import { AdminassessmentComponent } from './adminassessment/adminassessment.component';
import { AdmindemographicsComponent }   from './demographicedit/admindemographics.component'
import { AdminindividualreportComponent } from './adminindividualreport/adminindividualreport.component';
import { AdminheaderComponent } from './adminheader/adminheader.component';
import { ClientlistComponent } from './clientlist/clientlist.component';
import { AssessmentlistComponent } from './assessmentlist/assessmentlist.component';
import { AuthGuard } from './guards/auth.guard';
import { OverlayreportComponent } from './overlayreport/overlayreport.component';
import { DemographicreportComponent } from './demographicreport/demographicreport.component';
import { NavigationComponent } from './navigation/navigation.component';
import { MyAccountComponent } from './myaccount/myacount.component';
import { UsersComponent } from './users/users.component';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { PublicindividualreportComponent } from './publicindividualreport/publicindividualreport.component';
import { ResetPasswordComponent } from './resetpassword/resetpassword.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DemographicsComponent } from './demographics/demographics.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ManualFilterPipePipe } from './manual-filter-pipe.pipe';
import { NgSelectModule } from '@ng-select/ng-select';import { BubbleChartComponent } from './bubble-chart/bubble-chart.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { BubbleChartReportComponent } from './bubble-chart-report/bubble-chart-report.component';
import { ReportingToolSlcqComponent } from './reporting-tool-slcq/reporting-tool-slcq.component';
import { ReportingToolSlcqComponentComparable } from './reporting-tool-slcq-comparable/reporting-tool-slcq-comparable.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    NgSelectModule,
    NgxDatatableModule,
    NgbModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatCardModule,
    GoogleChartsModule
  ],
  declarations: [
    AppComponent,
    AssessmentComponent,
    ReportingToolComponent,
    LoginComponent,
    AdminassessmentComponent,
    AdminindividualreportComponent,
    AdminheaderComponent,
    ClientlistComponent,
    AssessmentlistComponent,
    OverlayreportComponent,
    DemographicreportComponent,
    NavigationComponent,
    MyAccountComponent,
    UsersComponent,
    ForgotPasswordComponent,
    PublicindividualreportComponent,
    ResetPasswordComponent,
    AdmindemographicsComponent,
    DemographicsComponent,
    ManualFilterPipePipe,
    BubbleChartComponent,
    BubbleChartReportComponent,
    ReportingToolSlcqComponent,
    ReportingToolSlcqComponentComparable,
    // AssessmentSlcqComponent
  ],
  providers: [AuthGuard, AdminService, AssessmentService, MessageService, PermissionService, AssessmentLogService],
  bootstrap: [AppComponent]
})
export class AppModule { }
