export default {
  "en": {
    "Contacto directo con clientes internos": "Direct contact with internal customers",
    "Contacto indirecto con clientes internos": "Indirect contact with internal customers",
    "Contacto directo con clientes externos": "Direct contact with external customers",
    "Contacto indirecto con clientes externos": "Indirect contact with external customers",
    "Licenciatura": "Post High School Certificate",
    "Doctorado": "Graduate Doctorate",
    "Maestría": "Graduate Masters Degree",
    "Bachillerato": "Bachelors Degree",
    "Diplomado": "Associates Degree",
    "Preparatoria": "High School",
    "22 o más": "22 o más",
    "16 - 21": "16 - 21",
    "11 - 15": "11 - 15",
    "6 - 10": "6 - 10",
    "4 - 5": "4 - 5",
    "1 - 3": "1 - 3",
    "Menos de 1 año": "Less than 1 year",
    "71+": "71+",
    "61 - 70": "61 - 70",
    "51 - 60": "51 - 60",
    "41 - 50": "41 - 50",
    "36 - 40": "36 - 40",
    "31 - 35": "31 - 35",
    "26 - 30": "26 - 30",
    "22 - 25": "22 - 25",
    "18 - 21": "18 - 21",
    "Masculino": "Male",
    "Femenino": "Female"
  }
}