import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { GenericReport } from '../report';

@Component({
  selector: 'app-demographicreport',
  templateUrl: './demographicreport.component.html',
  styleUrls: ['../reporting-tool/reporting-tool.component.css']
})
export class DemographicreportComponent implements OnInit {

  report: GenericReport;

  constructor( private router: Router,
               private route: ActivatedRoute,
               private adminService: AdminService,
               private location: Location) { }

  assignReport(report): void {

    for (let row of report.rows) {
      for (let section of row.sections) {
        var i = 1;
        for (let result of section.labels) {
          result.pipclass = 'pip' + i;
          result.fillclass = 'fill' + i;
          i = i + 1;
        }
      }
    }
    console.log(report);
    this.report = report;
    console.log(this.report);
    //this.initialLoadComponent();
  }

  getDemographicReport(): void {
    const idassessment = this.route.snapshot.paramMap.get('idassessment');
    const demographicgroup = this.route.snapshot.paramMap.get('demographicgroup');

    this.adminService.getGenericReport([{"idassessment" : [idassessment]}], [{"demographicgroup" : demographicgroup}]).subscribe((report) => {
                        this.assignReport(report);
                    });
  }

  ngOnInit() {
    this.getDemographicReport();
  }

}
