import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../admin.service';
import { PermissionService } from '../permission.service';
import { DemographicAll } from '../assessment'; //eslint-disable-line
import { get, remove, last, find, isEmpty, sortBy } from 'lodash';

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.css']
})
export class DemographicsComponent implements OnInit {
  messageclass;
  message;
  rows: DemographicAll[];
  customRows: DemographicAll[];
  temp = [];
  currentclient;
  selectedClientName: '';
  clientlist = [];
  selectedClientId;
  loading = true;
  customLoading = false;
  isAdmin = false;
  customDemographic = {
    idclient: '',
    question: '',
    label: '',
    answersGroup: [{
      body: '',
      order: 0,
      new: false,
      hidden: false,
      erasable: true,
      valuesetlang: {}
    }],
    questionlang: { en: '', es: '' }
  }
  demographicOnEdit = {
    valueset: [],
    questionlang: { en: '', es: '' }
  }
  defaultLang = 'en'
  lang = 'en';
  langList = [
    { lang: 'en', name: 'English' },
    { lang: 'es', name: 'Spanish' }
  ]
  messages = {
    en: {
      placeholder: 'English'
    },
    es: {
      placeholder: 'Spanish'
    }
  }

  constructor(private router: Router,
              private adminService: AdminService,
              private permissionService: PermissionService) {
  }

  ngOnInit() {
    this.isAdmin = this.permissionService.isAdmin();
    this.getGeneralDemographics();
    this.currentclient = {
      name:"",
      primarycontact:"",
      email:"",
      idpeople: JSON.parse(localStorage.getItem('currentUser')).idpeople
    };

    if (isEmpty(this.clientlist)) { this.getClients(this.currentclient.idpeople); }
  }

  getGeneralDemographics(): void {
    this.adminService.getGeneralDemographics().subscribe(
      (data) => {
        let demographics = sortBy(get(data, 'demographics', []), ['iddemographicquestion']);
        this.rows = demographics.filter(question => question.lang == this.defaultLang);
        this.loading = false;
      }
    )
  }

  clear() {
  }
  getClients(idpeople): void {
    this.adminService.getClients(idpeople).subscribe((clientlist) => {
                        this.clientlist = clientlist;
                        // Alphabetically sort the client list with .Sort + arroy function
                        this.clientlist.sort(( a, b ) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                    });
  }

  selectClient(clientId): void {
    this.selectedClientId = clientId;
    this.selectedClientName = get(find(this.clientlist, (client) =>
      client.id === Number(clientId)), 'name', '');
    this.customDemographic.idclient = clientId;
    if (clientId == 'null') { this.customRows = []; }
    else { this.getCustomGeneralDemographics(); }
  }

  getCustomGeneralDemographics(): void {
    this.customLoading = true;
    this.adminService.getCustomGeneralDemographics(this.selectedClientId).subscribe(
      (data) => {
        this.customRows = get(data, 'demographics', []);
        this.customLoading = false;
      }
    )
  }

  addNewAnswerChoice(): void {
    let lastOrder = get(last(this.customDemographic.answersGroup), 'order', 0)
    this.customDemographic.answersGroup.push({
      body: '',
      order: lastOrder + 1,
      new: true,
      hidden: false,
      erasable: true,
      valuesetlang: {

      }
    })
  }

  addUpdateNewAnswerChoice(): void {
    let lastWeight = get(last(this.demographicOnEdit.valueset), 'weight', 0)
    this.demographicOnEdit.valueset.push({
      value: '',
      weight: lastWeight + 1,
      new: true,
      hidden: false,
      erasable: true,
      deleted: false,
      valuesetlang: {

      }
    })
  }

  deleteAnswerChoice(order): void {
    remove(this.customDemographic.answersGroup, (answer) => answer.order === order);
  }

  deleteUpdateAnswerChoice(weight): void {
    find(this.demographicOnEdit.valueset, (answer) => {
      if (answer.weight === weight) { 
        answer.deleted = !answer.deleted;
      }
    });
    const valueset = find(this.demographicOnEdit.valueset, (answer) =>
      answer.weight === weight);
    if (valueset.new) {
      remove(this.demographicOnEdit.valueset, (value) => value.weight === weight); }
  }

  submitNewCustomDemographic(family): void {
    this.lang = this.defaultLang;
    if (family === 'standard') {
      this.adminService.submitNewStandardDemographic(this.customDemographic).subscribe((response) => {
        if (response.responseid == "1") {
          this.message = 'New standard demographic question has been created.';
          this.messageclass = 'alert-success';
          this.customDemographic = {
            idclient: this.selectedClientId,
            question: '',
            label: '',
            answersGroup: [{
              body: '',
              order: 0,
              new: false,
              hidden: false,
              erasable: true,
              valuesetlang: {
        
              }
            }],
            questionlang:
              { en: '', es: '' }
          }
        }
        else {
          this.message = 'An error has occurred.';
          this.messageclass = 'alert-danger';
        }
        this.ngOnInit();
      });
    } else {
      this.adminService.submitNewCustomDemographic(this.customDemographic).subscribe((response) => {
        if (response.responseid == "1") {
          this.message = 'New demographic question has been created.';
          this.messageclass = 'alert-success';
          this.customDemographic = {
            idclient: this.selectedClientId,
            question: '',
            label: '',
            answersGroup: [{
              body: '',
              order: 0,
              new: false,
              hidden: false,
              erasable: true,
              valuesetlang: {
        
              }
            }],
            questionlang: { en: '', es: '' }
          }
          this.getCustomGeneralDemographics();
        }
        else {
          this.message = 'An error has occurred.';
          this.messageclass = 'alert-danger';
        }
        this.ngOnInit();
      });
    }
  }

  editCustomDemographic(demographic): void { this.demographicOnEdit = demographic; }

  editDemographic(demographic): void { this.demographicOnEdit = demographic; }

  submitUpdateCustomDemographic(): void {
    this.adminService.submitUpdateCustomDemographic(this.demographicOnEdit).subscribe((response) => {

      if(response.responseid == "1") {
        this.message = 'Demographic question has been updated.';
        this.messageclass = 'alert-success';
        this.ngOnInit();
      }
      else {
        this.message = 'An error has occurred.';
        this.messageclass = 'alert-danger';
      }
    });
    this.lang = this.defaultLang;
  }

  toggleLockQuestion(demographic) {
    this.loading = true;
    demographic.locked = !demographic.locked;
    this.adminService.submitToggleLockDemographic(demographic).subscribe((response) => {
      this.loading = false;
      if(response.responseid == "1") {
        find(this.rows, (dGroup) => {
          if (get(response, 'iddemographicquestion', 0) === dGroup.iddemographicquestion)
            dGroup.locked = get(response, 'locked', true);
        });
        this.message = 'Demographic question has been updated.';
        this.messageclass = 'alert-success';
      }
      else {
        this.message = 'An error has occurred.';
        this.messageclass = 'alert-danger';
      }
    });
  }

  toggleLockAnswer(answer) {
    answer.hidden = !answer.hidden;
  }
  
  changeLang(lang): void {
    this.lang = lang;
  }
}
