import { Component, OnInit } from '@angular/core';
import {AdminService} from '../admin.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetPasswordComponent implements OnInit {

  loading: boolean = true;
  idpeople: number = 0;
  newPassword: string = "";
  confirmPassword: string = "";
  valid: boolean = false;
  match: boolean = false;
  message: string = "";
  code: string = "";

  constructor(private router: Router , private adminService: AdminService, private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.code = params['hash'];

      this.adminService.validateHashPassword(this.code).subscribe((response) => {
        this.loading = false;
        console.log("Hash validation Response:", response);
        if(response.responseid == "1") {
          console.log("Identity confirmed");
          this.idpeople = response['idpeople'];
        }
        else {
          console.log("Identity validation failed", response);
        }
      });
    });

  }

  componentWillUpdate() {

  }

  checkPassword() {

    let hasUpperCase = false;
    let hasNumber = false;
    let hasSpecialChar = false;
    let isLongEnough = this.newPassword.length >= 8;

    for (let i = 0; i < this.newPassword.length; i++) {
      if ((this.newPassword.charAt(i) === this.newPassword.charAt(i).toUpperCase()) && isNaN((parseInt(this.newPassword.charAt(i))))) {
        hasUpperCase = true;
      }

      if (!isNaN((parseInt(this.newPassword.charAt(i))))) {
        hasNumber = true;
      }

      const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (specialChars.test(this.newPassword)) {
        hasSpecialChar = true;
      }
    }

    this.valid = isLongEnough && hasUpperCase && hasNumber && hasSpecialChar;
  }

  checkMatch() {
    this.match = this.newPassword === this.confirmPassword;
  }

  submit() {
    this.adminService.updateForgotPassword(this.idpeople, this.newPassword, this.code).subscribe((response) => {
      console.log("New Password Response:", response);
      if(response.responseid == "1") {
        console.log("Your password was successfully changed");
        this.newPassword = "";
        this.confirmPassword = "";
        this.message = "Your password was successfully changed";
        window.location.href = "/";
      }
      else {
        console.log("Password change failed", response);
        this.message = "An error happened. The password hasn't been changed";
      }
    });
  }

}
