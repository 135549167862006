import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.css']
})
export class MyAccountComponent implements OnInit {

  currentUser: any;
  showPasswordFields: boolean = false;
  oldPassword: string = "";
  newPassword: string = "";
  confirmPassword: string = "";
  valid: boolean = false;
  match: boolean = false;
  message: string = "";

  constructor(private router: Router , private adminService: AdminService,) { }

  ngOnInit() {
    if (localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
  }

  showPasswordSection() {
    this.showPasswordFields = true;
  }

  checkPassword() {

    let hasUpperCase = false;
    let hasNumber = false;
    let hasSpecialChar = false;
    let isLongEnough = this.newPassword.length >= 8;

    for (let i = 0; i < this.newPassword.length; i++) {
      if ((this.newPassword.charAt(i) === this.newPassword.charAt(i).toUpperCase()) && isNaN((parseInt(this.newPassword.charAt(i))))) {
        hasUpperCase = true;
      }

      if (!isNaN((parseInt(this.newPassword.charAt(i))))) {
        hasNumber = true;
      }

      const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (specialChars.test(this.newPassword)) {
        hasSpecialChar = true;
      }
    }

    this.valid = isLongEnough && hasUpperCase && hasNumber && hasSpecialChar;
  }

  checkMatch() {
    this.match = this.newPassword === this.confirmPassword;
  }

  submit() {
    const idpeople = JSON.parse(localStorage.getItem("currentUser"))["idpeople"];
    this.adminService.updatePassword(idpeople, this.oldPassword, this.newPassword).subscribe((response) => {
      console.log("Set Password Response:", response);
      if(response.responseid == "0") {
        console.log("Your password was successfully changed");
        this.oldPassword = "";
        this.newPassword = "";
        this.confirmPassword = "";
        this.showPasswordFields = false;
        this.message = "You password was successfully changed";
      }
      else {
        console.log("Password change failed", response);
        this.message = "An error happened. The password hasn't been changed";
      }
    });
  }
}
