import {Component, AfterViewChecked, Inject, ViewChild, ElementRef, Input, ViewEncapsulation} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AssessmentService } from '../assessment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Report } from '../report';
import { orderBy, some, head, filter, get } from 'lodash';
import { Assessment } from '../assessment';
import { Title } from '@angular/platform-browser';
import { BrandingService } from '../branding.service';
declare var require: any;


@Component({
  selector: 'app-publicindividualreport',
  templateUrl: './publicindividualreport.component.html',
  styleUrls: ['./publicindividualreport.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PublicindividualreportComponent implements AfterViewChecked {

  @ViewChild('reportContent', {static: false}) reportContent !: ElementRef;

  @Input() assessment: Assessment;
  @Input() lang: string;

  report: Report;
  responseDemographics: any;
  fixedLabels = {
    en: {
      report: 'Download Report'
    },
    es: {
      report: 'Descargar Reporte'
    }
  }
  visibility = "hidden";
  buttonDisabled = true;
  branding = {};

  constructor( @Inject(DOCUMENT) private document: Document,
               private router: Router,
               private route: ActivatedRoute,
               private assessmentService: AssessmentService,
               private location: Location,
               private titleService: Title,
               private brandingService: BrandingService) {
    this.branding = brandingService.setBranding(document, titleService);
  }


  getIndividualReport(): void {
    
    // don't bother getting the report until the assessment exists or the 
    // ref to this.assessment.idassessmentresponse will fail
    if(this.assessment == null || !this.assessment.idassessmentresponse){
      return;
    }

    this.assessmentService.getPublicReport(this.assessment.idassessmentresponse).subscribe((report) => {
      let current_lang = this.lang ? this.lang : 'en';
      report.howtoread = get(head(report.howtoread.filter(intro => intro.lang == current_lang)), 'howtoread', '');
      this.report = report;
    });
  }

  ngAfterViewChecked() {
    if (this.reportContent) {
      console.log("this.reportContent is defined in ngAfterViewChecked");
    } else {
      console.log("this.reportContent is UNdefined in ngAfterViewChecked");
    }

    if (this.report == null) {
      this.getIndividualReport();
    }
  }

  // bug fix - ngDoCheck was added because ngAfterViewChecked wasn't always 
  // loading reportContent and as a result reportContent was undefined and 
  // download() was crashing.
  ngDoCheck() {
    if(this.reportContent){
      console.log("this.reportContent is defined in ngDoCheck");
      this.buttonDisabled = false;
    } else{
      console.log("this.reportContent is UNdefined in ngDoCheck");
      this.buttonDisabled = true;
    }

    if (this.report == null) {
      this.getIndividualReport();
    }   
  } 

  download() {
    // make sure reportContent is actually defined or this will crash
    console.log("BUTTON CLICKED");
    if (this.reportContent) {
      let filename = 'IndividualReport';
      const html2pdf = require("html2pdf.js");
      const opt = {
        filename: filename + '.pdf',
        margin: 20,
        jsPDF: { orientation: 'landscape' },
        html2canvas: { scale: 1 }
      };
      const htmlElement = this.reportContent.nativeElement as HTMLElement;
      /*
          var scale = "scale(1)";
          htmlElement.style.webkitTransform =  scale;     // Chrome, Opera, Safari
          htmlElement.style.transform = scale;     // General
          htmlElement.setAttribute("style", "transform: scale(1);");
      */
      html2pdf().from(htmlElement).set(opt).save();
    }
  }

}

