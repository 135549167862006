import { Component, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { AdminService } from "../admin.service";
import { PermissionService } from "../permission.service";
import { AssessmentService } from "../assessment.service";
import { Router, ActivatedRoute } from "@angular/router";
import {
  Assessmentlist,
  Project,
  AssessmentType,
  AssessmentSubmit,
} from "../assessment";
import {
  sortBy,
  find,
  map,
  isEmpty,
  remove,
  includes,
  some,
  every,
} from "lodash";

@Component({
  selector: "app-assessmentlist",
  templateUrl: "./assessmentlist.component.html",
  styleUrls: ["./assessmentlist.component.css"],
})
export class AssessmentlistComponent implements OnInit {
  client: Assessmentlist;
  projects: Project[];
  assessmenttypes: AssessmentType[];
  project = new Project(-1, "", "", 1);
  assessment = new AssessmentSubmit(-1, "", -1, -1, "", "", 1, "", "", []);
  messageclass;
  message;
  suffixAvailable: boolean = false;
  suffixMsg = "";
  suffixMsgClass = "";
  editedProject = {};
  step = 1;
  availableDemographics = [];
  isAdmin = false;
  lang = "en";
  assessmentTypeSelected = "";
  nextButtonDisabled = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private assessmentService: AssessmentService,
    private permissionService: PermissionService
  ) {}

  checkValidation(assessmentForm): void {
    if (assessmentForm.form.valid) {
      this.nextButtonDisabled = false;
    }
  }
  nextStep(step, assessmentForm?, suffixAvailable?): void {
    //!assessmentForm.form.valid || !suffixAvailable
    if (assessmentForm)
      if (!assessmentForm.form.valid || !suffixAvailable) {
        this.nextButtonDisabled = true;
        return;
      }

    this.step = step;
    this.nextButtonDisabled = false;
  }

  getProjectsAssessments(): void {
    let id = this.route.snapshot.paramMap.get("id");
    //ToDo : Multiple clients
    let exclude_id = "0";
    this.adminService
      .getProjectsAssessments(id, exclude_id, id)
      .subscribe((client) => {
        this.client = client;
        console.log(this.client);
      });
  }

  getProjects(): void {
    let id = this.route.snapshot.paramMap.get("id");
    //ToDo : Multiple clients
    this.adminService.getProjects(id).subscribe((projects) => {
      this.projects = projects;
      // Alphabetically sort the client list with .Sort + arroy function
      this.projects.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    });
  }

  getAssessmentTypes(): void {
    this.adminService.getAssessmentTypes().subscribe((assessmenttypes) => {
      this.assessmenttypes = assessmenttypes;
      // Alphabetically sort the client list with .Sort + arroy function
      // It looks like the asessment types are already sorted, but i'll leave this here just in case...
      // Nevermind, i just double checked, they were not sorted
      this.assessmenttypes.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    });
  }

  getAvailableDemographics(): void {
    let idclient = this.route.snapshot.paramMap.get("id");
    this.adminService
      .getAvailableDemographics(idclient)
      .subscribe((availableDemographics) => {
        let demographics = sortBy(availableDemographics, [
          "iddemographicquestion",
        ]);
        this.availableDemographics = demographics.filter(
          (question) => question.lang == this.lang
        );
      });
  }

  checkStandardDemographics(id): void {
    this.assessment.demographics = [];
    let assessmenttype = find(this.assessmenttypes, ["id", Number(id)]);
    this.assessmentTypeSelected = assessmenttype.name;
    //this.availableDemographics = merge(this.availableDemographics, assessmenttype.demographicquestions);
    map(this.availableDemographics, (question) => {
      question.checked = false;
      find(assessmenttype.demographicquestions, (standardQuestion) => {
        if (
          standardQuestion.iddemographicquestion ===
          question.iddemographicquestion
        ) {
          if (isEmpty(this.assessment.demographics)) {
            this.assessment.demographics = [];
          }
          question.checked = true;
          if (!includes(this.assessment.demographics, question)) {
            this.assessment.demographics.push(question);
          }
        }
      });
    });
  }

  checkCustomDemographics(event, demographic): void {
    if (event.target.checked) {
      this.assessment.demographics.push(demographic);
      if (
        (!every(demographic.valueset, "valuesetlang.es") ||
          some(demographic.valueset, ["valuesetlang.es", ""])) &&
        (this.assessmentTypeSelected == "Organizational Strategic Alignment" ||
          this.assessmentTypeSelected == "My Preferred Organization")
      ) {
        this.document.getElementById("alert-info").classList.add("show");
        setTimeout(() => {
          this.document.getElementById("alert-info").classList.remove("show");
        }, 12000);
      }
    } else {
      remove(
        this.assessment.demographics,
        (addedDemographic) =>
          addedDemographic.iddemographicquestion ===
          demographic.iddemographicquestion
      );
    }
  }

  getInitialLoad(): void {
    this.message =
      localStorage.getItem("message") != ""
        ? localStorage.getItem("message")
        : this.message;
    this.messageclass =
      localStorage.getItem("messageclass") != ""
        ? localStorage.getItem("messageclass")
        : this.messageclass;
    localStorage.setItem("message", "");
    localStorage.setItem("messageclass", "");
  }
  ngOnInit() {
    this.step = 1;
    this.project = new Project(-1, "", "", 1);
    this.isAdmin = this.permissionService.isAdmin();
    this.assessment = new AssessmentSubmit(
      -1,
      "",
      -1,
      -1,
      "",
      "",
      1,
      "",
      "",
      []
    );
    this.getProjectsAssessments();
    this.getProjects();
    this.getAssessmentTypes();
    this.getAvailableDemographics();
    this.getInitialLoad();
  }

  private navigateToAssessment(id) {
    this.router.navigate(["/admin/assessment/" + id]);
  }

  submitProject(): void {
    let idclient = this.route.snapshot.paramMap.get("id");
    this.project.idclient = idclient;
    this.adminService.submitProject(this.project).subscribe((response) => {
      if (response.responseid == "1") {
        this.message = "New project has been created.";
        this.messageclass = "alert-success";
      } else {
        this.message = "An error has occurred.";
        this.messageclass = "alert-danger";
      }
      this.ngOnInit();
    });
  }

  setProjectEdit(idproject, name): void {
    this.editedProject = {
      name: name,
      idproject: idproject,
    };
  }

  editProject(): void {
    let idclient = this.route.snapshot.paramMap.get("id");
    let editProject = {
      idclient: idclient,
      idproject: this.editedProject["idproject"],
      name: this.editedProject["name"],
    };
    this.adminService.editProject(editProject).subscribe((response) => {
      if (response.responseid == "1") {
        this.message = "The project name has been changed";
        this.messageclass = "alert-success";
      } else {
        this.message = "An error has occurred.";
        this.messageclass = "alert-danger";
      }
      this.ngOnInit();
    });
  }

  checkSuffixAvailability(): void {
    this.adminService
      .validateAssessment(this.assessment)
      .subscribe((response) => {
        if (response.responseid == "1") {
          this.suffixMsg = " Suffix available ";
          this.suffixMsgClass = "alert-success";
          this.suffixAvailable = true;
        } else {
          this.suffixMsg = response["responsemsg"];
          this.suffixMsgClass = "alert-danger";
          this.suffixAvailable = false;
        }
      });
  }

  submitAssessment(): void {
    let error = false;
    if (
      this.assessment.name === "" ||
      this.assessment.idassessmenttype === -1 ||
      this.assessment.idproject === -1 ||
      this.assessment.startdate === "" ||
      this.assessment.enddate === "" ||
      this.assessment.estimatedresponses === ""
    )
      error = true;
    if (!error) {
      this.adminService
        .submitAssessment(this.assessment)
        .subscribe((response) => {
          if (response.responseid == "1") {
            this.message = "New assessment has been created.";
            this.messageclass = "alert-success";
            this.assessmentService
              .cronAssessmentStatus()
              .subscribe((response) => {
                this.ngOnInit();
              });
          } else {
            this.message = "An error has occurred.";
            this.messageclass = "alert-danger";
          }
          this.suffixMsg = "";
          this.step = 1;
        });
    } else {
      this.message = "All fields are mandatory";
      this.messageclass = "alert-danger";
    }
  }

  validateDate() {
    if (this.assessment.enddate < this.assessment.startdate) {
      this.assessment.enddate = this.assessment.startdate;
    }
  }

  closeProject() {
    this.project = new Project(-1, "", "", 1);
  }

  closeAssessment() {
    this.suffixMsg = "";
    this.assessment = new AssessmentSubmit(
      -1,
      "",
      -1,
      -1,
      "",
      "",
      1,
      "",
      "",
      []
    );
  }

  deleteProject(id) {
    this.adminService.deleteProjectById(id).subscribe((response) => {
      if (response.responseid == "1") {
        this.message = response.responsetxt;
        this.messageclass = "alert-success";
        this.getProjectsAssessments();
      } else {
        this.message = response.responsetxt;
        this.messageclass = "alert-danger";
      }
    });
  }
}
