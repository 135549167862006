export class SlcqQuadrant {

  dataChangeTrigger: number = 0;

  html_section_num: number;

  quadrantName: string;  
  quadrantPhrase1: string;
  quadrantPhrase2: string;
  sectionName: string;

  quadTitle1 = new QuadTitle();
  quadTitle2 = new QuadTitle();
  quadTitle3 = new QuadTitle();
  quadTitle4 = new QuadTitle();

  xLabel: string;
  xLabelLeft: string;
  xLabelRight: string;

  yLabel: string;
  yLabelTop: string;
  yLabelBottom: string;

  columnNames: any;

  slcqData: SlcqData[] = [];

  showTooltips = true;

}

export class QuadTitle {
  line1: string;
  line2: string;
}

export class SlcqData {

  idassessment: number;
  assessmentname: string;

  meanX: number;
  meanY: number;

  totalResponses: number;

  quad1Percent: number;
  quad2Percent: number;
  quad3Percent: number;
  quad4Percent: number;

  slcqDemographic: SlcqDemographic[] = [];
}

export class SlcqDemographic {
  demographicQuestionLabel: string;
  demographicQuestionId: number;
  slcqDemographicData: SlcqDemographicData[] = [];
}

export class SlcqDemographicData {
  demographicAnswerLabel: string;
  meanXCoord: number;
  meanYCoord: number;
}

////////////////////////////////////////////////
// layout is hard coded here for the html code
export class SlcqLayoutSequence {
  sequence = [{ "layoutElement": 0, "bubbleChartElement": 0 },
  { "layoutElement": 1, "bubbleChartElement": 1 },
  { "layoutElement": 2, "bubbleChartElement": 2 },
  { "layoutElement": 3, "writeupSectionNum": 1 },
  { "layoutElement": 4, "bubbleChartElement": 3 },
  { "layoutElement": 5, "bubbleChartElement": 4 },
  { "layoutElement": 6, "bubbleChartElement": 5 },
  { "layoutElement": 7, "writeupSectionNum": 2 },
  { "layoutElement": 8, "bubbleChartElement": 6 },
  { "layoutElement": 9, "bubbleChartElement": 7 },
  { "layoutElement": 10, "bubbleChartElement": 8 },
  { "layoutElement": 11, "writeupSectionNum": 3 }];
}

// dark colors from https://www.rapidtables.com/web/color/RGB_Color.html
export class SlcqBubbleColors1 { // pastel colors from https://www.color-hex.com/color-palette/33276
  colors = [{ "mean": "5F9EA0", "data": "PaleTurquoise" }, // original pale turquoise
  { "mean": "#DAA520", "data": "#ffffcc" },  // yellow
  { "mean": "#B22222", "data": "#ffcccc" },  // salmon
  { "mean": "#9400D3", "data": "#ccccff" },  // purple
  { "mean": "#228B22", "data": "#ccffcc" }]; // green
}

export class SlcqBubbleColors2 { // pastel colors from https://www.color-hex.com/color-palette/34647
  colors = [{ "mean": "5F9EA0", "data": "PaleTurquoise" }, // original pale turquoise
  { "mean": "#DAA520", "data": "#e1ecb9" },  // yellow
  { "mean": "#B22222", "data": "#e3a1a1" },  // salmon
  { "mean": "#9400D3", "data": "#ee93e1" },  // purple/pink
  { "mean": "#0000FF", "data": "#aea9e1" },  // blue
  { "mean": "#228B22", "data": "#9fe5c7" }]; // green
}

export class SlcqAssessmentEntireDb {
  assessment = [{"quadnumber":1,"totalresponses":20082,"q1percent":9.46,"q2percent":16.74,"q3percent":50.87,"q4percent":22.93,"xaxismean":2.32,"yaxismean":2.05},
                {"quadnumber":2,"totalresponses":20082,"q1percent":14.06,"q2percent":43.06,"q3percent":14.90,"q4percent":27.98,"xaxismean":2.9,"yaxismean":2.6},
                {"quadnumber":3,"totalresponses":20082,"q1percent":21.32,"q2percent":36.95,"q3percent":25.82,"q4percent":15.90,"xaxismean":2.55,"yaxismean":2.6},
                {"quadnumber":4,"totalresponses":20082,"q1percent":16.90,"q2percent":39.04,"q3percent":29.04,"q4percent":15.02,"xaxismean":2.55,"yaxismean":2.55},
                {"quadnumber":5,"totalresponses":20082,"q1percent":3.84,"q2percent":20.72,"q3percent":21.83,"q4percent":53.62,"xaxismean":2.95,"yaxismean":2},
                {"quadnumber":6,"totalresponses":20082,"q1percent":29.94,"q2percent":9.37,"q3percent":50,"q4percent":10.70,"xaxismean":1.95,"yaxismean":2.3},
                {"quadnumber":7,"totalresponses":20082,"q1percent":6.24,"q2percent":56.31,"q3percent":20.15,"q4percent":17.29,"xaxismean":3,"yaxismean":2.75},
                {"quadnumber":8,"totalresponses":20082,"q1percent":22.32,"q2percent":44.23,"q3percent":18.05,"q4percent":15.40,"xaxismean":2.6,"yaxismean":2.8},
                {"quadnumber":9,"totalresponses":20082,"q1percent":26.63,"q2percent":33.39,"q3percent":31.57,"q4percent":8.42,"xaxismean":2.3,"yaxismean":2.65}];
}
